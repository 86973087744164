import { RouteObject } from "react-router-dom";
import ErrorHandler from "../../Components/ErrorHandler/ErrorHandler";

export const ERROR_HANDLER = "/errorHandler/:code/:msg";

const ErrorHandlerScreen: RouteObject[] = [
  { path: ERROR_HANDLER, element: <ErrorHandler /> },
];

export default ErrorHandlerScreen;
