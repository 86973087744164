import { Model } from "@tailflow/laravel-orion/lib/model";

export class User extends Model<{
  first_name: string;
  last_name: string;
  phone_no: string;
  parent_id: any;
  email: string;
  created_at: string;
  updated_at: string;
  organization?: any;
  company_name?: any;
}> {
  public $resource(): string {
    return "users";
  }
}
