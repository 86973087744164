import { RouteObject } from "react-router-dom";
import AddUpdate from "../../Pages/Setting/BankDetails/AddUpdate";
import List from "../../Pages/Setting/BankDetails/List";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_BANK_DETAIL,
  DELETE_BANK_DETAIL,
  RESTORE_BANK_DETAIL,
  UPDATE_BANK_DETAIL,
  VIEW_BANK_DETAIL,
} from "../../Util/PermissionList";

export const BANK_DETAILS_TITLE = "Bank Details";
export const BANK_DETAILS_LIST = "/bank-details";
export const BANK_DETAILS_ADD = "/bank-details/add";
export const BANK_DETAILS_EDIT = "/bank-details/:id/edit";
export const BANK_DETAILS_ARCHIVE = "/bank-details/archive";

const BankDetailRoutes: RouteObject[] = [
  {
    path: BANK_DETAILS_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_BANK_DETAIL}
        checkPermisson={{
          addPermission: ADD_BANK_DETAIL,
          updatePermission: UPDATE_BANK_DETAIL,
          deletePermission: DELETE_BANK_DETAIL,
          restorePermission: RESTORE_BANK_DETAIL,
          viewPermission: VIEW_BANK_DETAIL,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: BANK_DETAILS_ADD,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate title={BANK_DETAILS_TITLE} listRoute={BANK_DETAILS_LIST} />
        }
        checkPermisson={{
          addPermission: ADD_BANK_DETAIL,
          updatePermission: UPDATE_BANK_DETAIL,
          deletePermission: DELETE_BANK_DETAIL,
          restorePermission: RESTORE_BANK_DETAIL,
          viewPermission: VIEW_BANK_DETAIL,
        }}
        permission={ADD_BANK_DETAIL}
      />
    ),
  },
  {
    path: BANK_DETAILS_EDIT,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate title={BANK_DETAILS_TITLE} listRoute={BANK_DETAILS_LIST} />
        }
        checkPermisson={{
          addPermission: ADD_BANK_DETAIL,
          updatePermission: UPDATE_BANK_DETAIL,
          deletePermission: DELETE_BANK_DETAIL,
          restorePermission: RESTORE_BANK_DETAIL,
          viewPermission: VIEW_BANK_DETAIL,
        }}
        permission={UPDATE_BANK_DETAIL}
      />
    ),
  },
  {
    path: BANK_DETAILS_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_BANK_DETAIL,
          updatePermission: UPDATE_BANK_DETAIL,
          deletePermission: DELETE_BANK_DETAIL,
          restorePermission: RESTORE_BANK_DETAIL,
          viewPermission: VIEW_BANK_DETAIL,
        }}
        permission={DELETE_BANK_DETAIL}
      />
    ),
  },
];

export default BankDetailRoutes;
