import { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Country } from "../../../Model/Setting/Country";
import { COUNTRIES_TITLE } from "../../../Routes/Setting/Country";
import { fetchData } from "../../../Util/OrionList";
import CustomDataTable from "../../../Components/CustomDataTable";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { setToast } from "../../../Util/Toast";
import pluralize from "pluralize";
interface CountryItem {
  id: number;
  name: string;
  code: string;
  created_at: string;
  updated_at: string;
}

function List() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<CountryItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");

  const columns: TableColumn<CountryItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
      minWidth: "45%",
    },
    {
      name: "Name",
      cell: (row) => row.name,
      minWidth: "49%",
    },
    {
      name: "Code",
      cell: (row) => row.code,
      minWidth: "6%",
    },
  ];

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  // @ts-ignore
  const fetchCountry = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new Country(),
        sortField,
        sortOrder,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchCountry();
  }, [limit, page]); // eslint-disable-line

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "name",
      value: "name",
      label: "Name",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "code",
      value: "code",
      label: "Code",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
  ];

  function handleRowCreateButtonClick(): void {
    setToast("error", "Function not implemented.");
  }

  return (
    <>
      <div className="mb-4">
        <h2>{pluralize(COUNTRIES_TITLE)} List</h2>
      </div>
      <Row>
        <CustomFilter
          options={filterOptions}
          inputValues={inputValues}
          setInputValues={setInputValues}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          getData={fetchCountry}
          setPage={setPage}
        />
      </Row>
      <div className="mb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"Country"} // Pass optional fields to CustomDataTable
        />
      </div>
    </>
  );
}

export default List;
