import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { Table } from "react-bootstrap";
import { XCircle } from "react-bootstrap-icons";
import CustomSkeleton from "./CustomSkeleton";

type ItemType = {
  id: number;
  content: React.ReactNode;
};

type DNDProps = {
  items: ItemType[];
  onDragEnd: (result: DropResult) => void;
  children: React.ReactNode;
  removeItem?: (id: number) => void;
  removeButtonStatus?: boolean;
  loadingStatus?: boolean;
};

function DND({
  items,
  onDragEnd,
  children,
  removeItem,
  removeButtonStatus,
  loadingStatus,
}: DNDProps) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <Table>
              <thead className="custom-table-header">
                <tr className="text-center">{children ? children : ""}</tr>
              </thead>
              <tbody className="custom-table-body">
                {/* {items.length === 0 ? ( */}
                {loadingStatus ? (
                  <tr>
                    <td
                      className="p-0"
                      colSpan={
                        children ? React.Children.count(children) + 2 : 2
                      }
                    >
                      <CustomSkeleton
                        height={50}
                        customOuterStyle={{ padding: "0", height: "50px" }}
                        containerClassName={"d-grid"}
                        customInnerStyle={{ borderRadius: "0" }}
                      />
                    </td>
                  </tr>
                ) : (
                  items.map((item: ItemType, index: number) => (
                    <Draggable
                      key={item?.id?.toString()}
                      draggableId={item.id?.toString()}
                      index={index}
                      isDragDisabled={items?.length <= 1}
                    >
                      {(provided) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <td>
                            {items?.length > 1 ? (
                              <FontAwesomeIcon icon={faGripVertical} />
                            ) : (
                              ""
                            )}
                          </td>
                          {item.content}
                          <td>
                            {items?.length > 1 &&
                            (removeButtonStatus ?? true) ? (
                              <span
                                onClick={() =>
                                  removeItem && removeItem(item?.id)
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "red",
                                  margin: "1px",
                                }}
                              >
                                <XCircle />
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))
                )}
              </tbody>
            </Table>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DND;
