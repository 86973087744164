import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Dropdown, Form } from "react-bootstrap";
import CustomSkeleton from "./CustomSkeleton";

interface CustomDataTableProps {
  columns: any[];
  data: any[];
  progressPending: boolean;
  paginationTotalRows: number;
  onChangeRowsPerPage: (limit: any, page: any) => void;
  onChangePage: (page: number | undefined) => void;
  handleRowCreateButtonClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  paginationDisable?: any;
  currentPages?: any;
  currentRowsPerPage?: any;
  customSkeletonCount?: any;
  module?: string; // Only module name is needed
}

const CustomDataTable: React.FC<CustomDataTableProps> = ({
  columns,
  data,
  progressPending,
  paginationTotalRows,
  onChangeRowsPerPage,
  onChangePage,
  paginationDisable,
  currentPages,
  currentRowsPerPage,
  customSkeletonCount,
  module,
}) => {
  // Retrieve the visibility settings from localStorage or default to an empty object
  const [invisibleColumns, setInvisibleColumns] = useState<any>(() => {
    if (!module) return {};
    const savedInvisibleColumns = localStorage.getItem("invisibleColumns");
    return savedInvisibleColumns ? JSON.parse(savedInvisibleColumns) : {};
  });

  useEffect(() => {
    // Save invisibleColumns state to localStorage whenever it changes
    if (module) {
      localStorage.setItem(
        "invisibleColumns",
        JSON.stringify(invisibleColumns),
      );
    }
  }, [invisibleColumns, module]);

  const handleCheckboxChange = (fieldName: string) => {
    if (!module) return;
    setInvisibleColumns((prev: any) => {
      const updatedModuleColumns = prev[module]?.includes(fieldName)
        ? prev[module].filter((col: any) => col !== fieldName)
        : [...(prev[module] || []), fieldName];

      return { ...prev, [module]: updatedModuleColumns };
    });
  };

  const updatedColumns = module
    ? columns.filter(
        (column) => !invisibleColumns[module]?.includes(column.name),
      )
    : columns;

  const anyColumnVisible = module
    ? columns.some((column) => !invisibleColumns[module]?.includes(column.name))
    : true;

  return (
    <div className="row fluid">
      {module && (
        <div className="sub-header mt-3 d-flex justify-content-end">
          <Dropdown>
            <Dropdown.Toggle
              className="rounded ms-3"
              style={{ fontSize: "10px" }}
              size={"sm"}
              variant={anyColumnVisible ? "success" : "secondary"}
              id="dropdown-basic"
            >
              Optional Columns
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="px-2 py-2"
              style={{ width: "max-content" }}
            >
              {columns?.map((field: any) => (
                <Form.Check
                  key={field.name}
                  type="checkbox"
                  id={field.name}
                  checked={!invisibleColumns[module]?.includes(field.name)}
                  label={
                    <span style={{ cursor: "pointer" }}>{field.name}</span>
                  }
                  onChange={() => handleCheckboxChange(field.name)}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <DataTable
        className="pt-2"
        columns={updatedColumns}
        data={data}
        progressPending={progressPending}
        progressComponent={
          <CustomSkeleton
            customInnerStyle={{ marginBottom: "24px" }}
            count={customSkeletonCount || 7}
            height={35}
          />
        }
        persistTableHead
        pagination={paginationDisable ? false : true}
        paginationServer
        paginationDefaultPage={currentPages}
        paginationPerPage={currentRowsPerPage}
        paginationTotalRows={paginationTotalRows}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        customStyles={{
          rows: {
            style: {
              fontSize: "0.75rem",
              color: "black",
            },
          },
          headCells: {
            style: {
              fontSize: "0.75rem",
              fontWeight: "bold",
              color: "black",
            },
          },
          pagination: {
            style: {
              fontSize: "0.75rem",
              color: "black",
            },
          },
        }}
      />
    </div>
  );
};

export default CustomDataTable;
