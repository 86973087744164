import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table, Alert } from "react-bootstrap";
import { useApi } from "../Controller/ApiController";
import { Download } from "react-bootstrap-icons";
import { CapitalizeAndRemoveUnderscore } from "../Util/CapitalizeAndRemoveUnderscore";

interface ImportDataProps {
  show: boolean;
  handleClose: () => void;
  model: any;
  modelName: any;
}

const ImportData: React.FC<ImportDataProps> = ({
  show,
  handleClose,
  model,
  modelName,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [importedData, setImportedData] = useState([]);
  const [duplicateData, setDuplicateData] = useState([]);
  const [importSuccessMessage, setImportSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDuplicateData, setIsDuplicateData] = useState(false);
  const { apiGet } = useApi();
  const fileInputRef: any = useRef(null); // Create a ref for the file input element

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile: any = e.target.files?.[0];
    if (selectedFile) {
      setIsDisabled(false);
      const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();

      if (
        fileExtension === "xlsx" ||
        fileExtension === "xls" ||
        fileExtension === "csv"
      ) {
        setIsDisabled(false);
        setErrorMessage("");
        setSelectedFile(selectedFile);
      } else {
        setImportSuccessMessage(false);
        setIsDisabled(true);
        setErrorMessage(
          "Invalid file type. Please upload a valid Excel file with .xlsx, .xls, or .csv extension.",
        );
        setSelectedFile(null);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setErrorMessage("");
      const formData: any = new FormData();
      formData.append("file", selectedFile);
      const response = await model
        .$query()
        .getHttpClient()
        .post("/import", formData);
      if (
        response?.data?.data &&
        response?.data?.data["ignored_duplicate_entries"]?.length > 0
      ) {
        setDuplicateData([]);
        setIsDuplicateData(false);
        const duplicateDataRecord: any =
          response.data.data["ignored_duplicate_entries"];

        Object.keys(duplicateDataRecord[0]).length === 0
          ? setIsDuplicateData(false)
          : setIsDuplicateData(true);
        setDuplicateData(duplicateDataRecord);
        setSelectedFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
      if (response?.data?.data && response?.data?.data[modelName]?.length > 0) {
        const newImportedData: any = [
          ...importedData,
          ...response.data.data[modelName],
        ];
        setImportedData(newImportedData);
        setImportSuccessMessage(true);
        setSelectedFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        setImportedData([]);
        setErrorMessage(response?.data?.message);
        setImportSuccessMessage(false);
      }
    } catch (error: any) {
      setImportedData([]);
      if (error.message) {
        setErrorMessage(error.message);
      } else if (error.response?.data?.message) {
        setErrorMessage(error.response?.data?.message);
      } else {
        setErrorMessage("Something went wrong");
      }
      setImportSuccessMessage(false);
    }
  };

  const handleCloseModal = () => {
    setImportedData([]);
    setDuplicateData([]);
    setErrorMessage("");
    setImportSuccessMessage(false);
    handleClose();
  };

  const handleDownloadSample = async () => {
    let responseData: any = await apiGet("csv/download/" + modelName);
    const anchor = document.createElement("a");
    anchor.href = responseData.url;
    anchor.download = "sample";
    anchor.rel = "noreferrer";
    anchor.target = "_blank";
    anchor.click();
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {importSuccessMessage && (
            <Alert variant="success">Import successful!</Alert>
          )}
          {errorMessage?.length > 0 && (
            <Alert variant="danger">{errorMessage}</Alert>
          )}
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Label>Choose file to import:*</Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange}
              required
              ref={fileInputRef}
            />
            <Button
              variant="primary"
              type="submit"
              className="my-3"
              disabled={isDisabled}
            >
              Import
            </Button>
            <span>
              <Button
                variant="success"
                type="button"
                size="sm"
                className="ms-3"
                style={{ marginLeft: "1px" }}
                onClick={handleDownloadSample}
              >
                Download Sample CSV{" "}
                <span>
                  {" "}
                  <Download style={{ marginBottom: "3px" }} />
                </span>
              </Button>
            </span>
          </Form>
          {importedData?.length > 0 && (
            <div>
              <Modal.Title className="mb-2">Import Summary</Modal.Title>
              <div style={{ maxHeight: "600px", overflowY: "auto" }}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {[
                        "id",
                        ...Object.keys(importedData[0]).filter(
                          (header) => header !== "id",
                        ),
                      ].map((header, index) => (
                        <th key={index} className="text-center">
                          {CapitalizeAndRemoveUnderscore(header)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {importedData.map((dataItem: any, index) => (
                      <tr key={index}>
                        {[
                          "id",
                          ...Object.keys(importedData[0]).filter(
                            (header) => header !== "id",
                          ),
                        ].map((header, index) => (
                          <td key={index} className="text-center">
                            {dataItem[header] !== undefined &&
                            dataItem[header] !== null
                              ? dataItem[header]
                              : "-"}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}

          {duplicateData?.length > 0 && isDuplicateData && (
            <div style={{ marginTop: "25px" }}>
              <Modal.Title className="mb-2">Duplicate Data</Modal.Title>
              <div style={{ maxHeight: "600px", overflowY: "auto" }}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {Object.keys(duplicateData[0])
                        .filter((header) => header !== "id")
                        .map((header, index) => (
                          <th key={index} className="text-center">
                            {CapitalizeAndRemoveUnderscore(header)}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {duplicateData.map((dataItem: any, index) => (
                      <tr key={index}>
                        {Object.keys(duplicateData[0])
                          .filter((header) => header !== "id")
                          .map((header, index) => (
                            <td key={index} className="text-center">
                              {dataItem[header] !== undefined &&
                              dataItem[header] !== null
                                ? dataItem[header]
                                : "-"}
                            </td>
                          ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImportData;
