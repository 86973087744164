import { Model } from "@tailflow/laravel-orion/lib/model";

export class Currency extends Model<{
  code: string;
  symbol: string;
  country_id: string;
  active: boolean;
  created_at: string;
  updated_at: string;
}> {
  static find(arg0: (currency: any) => boolean) {
    throw new Error("Method not implemented.");
  }
  public $resource(): string {
    return "currencies";
  }
}
