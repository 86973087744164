import React, { useEffect, useState } from "react";
import { Table, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { useApi } from "../../../Controller/ApiController";
import { Download } from "react-bootstrap-icons";
import { setToast } from "../../../Util/Toast";

const BalanceSheet = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { apiGet } = useApi();
  const { id } = useParams();
  const [companyName, setCompanyName] = useState("");
  const [pdfURL, setpdfURL] = useState();
  const [fromDate, setFromDate] = useState(() =>
    format(startOfMonth(new Date()), "yyyy-MM-dd"),
  );
  const [toDate, setToDate] = useState(() =>
    format(endOfMonth(new Date()), "yyyy-MM-dd"),
  );
  const [paymentType, setPaymentType] = useState({
    label: "All",
    value: "",
  });
  const [data, setData] = useState<any>({ paymentsArray: [], total: null });

  const [limit, setLimit] = useState(150);
  const [totalRows, setTotalRows] = useState<any>();

  const fetchPayments = async () => {
    if (data.paymentsArray.length === 0) {
      setLoading(true);
    }
    try {
      let apiUrl = `balance-sheet?from_date=${fromDate}&to_date=${toDate}&payment_type=${paymentType.value}&buyer_id=${id}&limit=${limit}`;

      const item = await apiGet(apiUrl);

      setpdfURL(item.pdf_url);
      setTotalRows(item.data.total);
      setCompanyName(item.data.company_name);
      const paymentsArray: any = Object.values(item.data.data);
      const total: any = paymentsArray.pop();

      setData({ paymentsArray, total });
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setToast("error", error.response.data.errors);
      } else if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, paymentType, limit]);

  const handleShowMore = () => {
    setLimit((prevLimit) => prevLimit + 150);
  };
  const handleDownloadPDF = () => {
    window.open(pdfURL, "_blank");
  };

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center">
        <h2>Balance Sheet of {companyName}</h2>
        <div>
          <button
            type="button"
            className="btn btn-primary btn-sm mb-2 mx-2"
            onClick={handleDownloadPDF}
          >
            Download PDF
            <Download className="mx-2" style={{ fill: "white" }} />
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm mb-2 mx-2"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      </div>
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex">
              <div style={{ marginRight: "20px" }}>
                <label>From: </label>
                <input
                  className="form-control"
                  type="date"
                  value={fromDate}
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div style={{ marginRight: "20px" }}>
                <label>To: </label>
                <input
                  className="form-control"
                  type="date"
                  value={toDate}
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <div style={{ marginRight: "20px" }}>
                <label>Payment Type: </label>
                <Select
                  options={[
                    { label: "All", value: "" },
                    { label: "Credit", value: "credit" },
                    { label: "Debit", value: "debit" },
                  ]}
                  placeholder="Select options..."
                  onChange={(selectedOption: any) =>
                    setPaymentType(selectedOption)
                  }
                  value={paymentType}
                  className="p-0"
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      minWidth: "120px",
                    }),
                  }}
                />
              </div>
            </div>
            <div>
              <span className="fs-3">
                <span className="me-1">Total Amount: </span>
                <span>{data.total?.toFixed(2)}</span>
              </span>
            </div>
          </div>

          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : data.paymentsArray.length === 0 ? (
            <div className="text-center">
              <p>No Data Available</p>
            </div>
          ) : (
            <div>
              <Table className="table table-bordered">
                <thead className="table-heder">
                  <tr>
                    <th>#</th>
                    <th>Payment Document Number</th>
                    <th>Invoice Number</th>
                    <th>Payment Type</th>
                    <th>Payment Mode</th>
                    <th>Payment Reference Number</th>
                    <th>Status</th>
                    <th>Payment Date</th>
                    <th>Opening Balance</th>
                    <th>Closing Balance</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.paymentsArray?.map((detail: any, index: any) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{detail?.document_number}</td>
                      <td>{detail?.tax_invoice?.document_number}</td>
                      <td
                        style={{ textTransform: "capitalize" }}
                        className={"text-capitalize"}
                      >
                        {detail.payment_type}
                      </td>
                      <td className="text-capitalize">{detail.payment_mode}</td>
                      <td>{detail.payment_reference_number}</td>
                      <td className="text-capitalize">{detail.status}</td>
                      <td>{detail.payment_date}</td>
                      <td className="text-end">
                        {Number(detail?.opening_balance)?.toFixed(2)}
                      </td>
                      <td className="text-end">
                        {Number(detail?.closing_balance)?.toFixed(2)}
                      </td>
                      <td
                        className={`${
                          detail.amount >= 0
                            ? "bg-success p-2 text-dark bg-opacity-50"
                            : "bg-danger text-dark bg-opacity-50"
                        } text-end`}
                      >
                        {detail.amount > 0
                          ? `+${Number(detail.amount).toFixed(2)}`
                          : Number(detail.amount).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={10} className="fw-bold text-end">
                      Total:
                    </td>
                    <td className="fw-bold text-end">
                      {data?.total?.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {data.paymentsArray.length < totalRows && (
                <span
                  className="d-flex justify-content-end text-primary cursor-pointer"
                  role="button"
                  onClick={handleShowMore}
                >
                  show more...
                </span>
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default BalanceSheet;
