import AppRoutes from "./Routes/AppRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./Style/App.css";
import { ToastContainer } from "react-toastify";

export default function App() {
  return (
    <div>
      <AppRoutes />
      <ToastContainer />
    </div>
  );
}
