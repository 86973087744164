import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/CounterPartyTag/List";
import AddUpdate from "../../Pages/Setting/CounterPartyTag/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_COUNTER_PARTY_TAG,
  DELETE_COUNTER_PARTY_TAG,
  RESTORE_COUNTER_PARTY_TAG,
  UPDATE_COUNTER_PARTY_TAG,
  VIEW_COUNTER_PARTY_TAG,
} from "../../Util/PermissionList";

export const COUNTER_PARTY_TAG_TITLE = "Counter Party Tag";
export const COUNTER_PARTY_TAG_LIST = "/counter-party-tags";
export const COUNTER_PARTY_TAG_ADD = "/counter-party-tags/add";
export const COUNTER_PARTY_TAG_EDIT = "/counter-party-tags/:id/edit";
export const COUNTER_PARTY_TAG_ARCHIVE = "/counter-party-tags/archive";

const CounterPartyTagRoutes: RouteObject[] = [
  {
    path: COUNTER_PARTY_TAG_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_COUNTER_PARTY_TAG}
        checkPermisson={{
          addPermission: ADD_COUNTER_PARTY_TAG,
          updatePermission: UPDATE_COUNTER_PARTY_TAG,
          deletePermission: DELETE_COUNTER_PARTY_TAG,
          restorePermission: RESTORE_COUNTER_PARTY_TAG,
          viewPermission: VIEW_COUNTER_PARTY_TAG,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: COUNTER_PARTY_TAG_ADD,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate
            title={COUNTER_PARTY_TAG_TITLE}
            listRoute={COUNTER_PARTY_TAG_LIST}
          />
        }
        checkPermisson={{
          addPermission: ADD_COUNTER_PARTY_TAG,
          updatePermission: UPDATE_COUNTER_PARTY_TAG,
          deletePermission: DELETE_COUNTER_PARTY_TAG,
          restorePermission: RESTORE_COUNTER_PARTY_TAG,
          viewPermission: VIEW_COUNTER_PARTY_TAG,
        }}
        permission={ADD_COUNTER_PARTY_TAG}
      />
    ),
  },
  {
    path: COUNTER_PARTY_TAG_EDIT,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate
            title={COUNTER_PARTY_TAG_TITLE}
            listRoute={COUNTER_PARTY_TAG_LIST}
          />
        }
        checkPermisson={{
          addPermission: ADD_COUNTER_PARTY_TAG,
          updatePermission: UPDATE_COUNTER_PARTY_TAG,
          deletePermission: DELETE_COUNTER_PARTY_TAG,
          restorePermission: RESTORE_COUNTER_PARTY_TAG,
          viewPermission: VIEW_COUNTER_PARTY_TAG,
        }}
        permission={UPDATE_COUNTER_PARTY_TAG}
      />
    ),
  },
  {
    path: COUNTER_PARTY_TAG_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_COUNTER_PARTY_TAG,
          updatePermission: UPDATE_COUNTER_PARTY_TAG,
          deletePermission: DELETE_COUNTER_PARTY_TAG,
          restorePermission: RESTORE_COUNTER_PARTY_TAG,
          viewPermission: VIEW_COUNTER_PARTY_TAG,
        }}
        permission={DELETE_COUNTER_PARTY_TAG}
      />
    ),
  },
];

export default CounterPartyTagRoutes;
