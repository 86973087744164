import React, { ChangeEvent, useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Card } from "react-bootstrap";
import { getUser, setUser } from "../../Util/Auth";
import { getCookie } from "typescript-cookie";
import { setToast } from "../../Util/Toast";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import { showErrorsInToasts } from "../../Util/ErrorMessageToast";
const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const apiUrl = `${process.env.REACT_APP_API_URL}api/update-user-profiles`;
  const authToken = getCookie("authToken");
  const token = authToken ? authToken.replace(/["']/g, "") : "";

  const [user, setUserState] = useState(getUser());
  const [editedProfile, setEditedProfile] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone_no: user.phone_no,
    profilePictureUrl: user.profile_pic,
    signatureUrl: user.signature,
  });
  const [isProfileEditStatus, setProfileEditStatus] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<any>(user.profile_pic);
  const [signatureImage, setSignatureImage] = useState<any>(user.signature);

  useEffect(() => {
    const updatedUser = getUser();
    setUserState(updatedUser);
    if (updatedUser.profile_pic) {
      setProfileImage(updatedUser.profile_pic + "?" + new Date().getTime());
    }
    updatedUser.signature &&
      setSignatureImage(updatedUser.signature + "?" + new Date().getTime());
  }, []);

  const handleProfileUpdate = async () => {
    const formData = new FormData();
    formData.append("first_name", editedProfile.first_name);
    formData.append("last_name", editedProfile.last_name);
    formData.append("email", editedProfile.email);
    formData.append("profile_pic", editedProfile.profilePictureUrl);
    formData.append("signature", editedProfile.signatureUrl);
    formData.append("phone_no", editedProfile.phone_no);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setProfileEditStatus(false);
        setUser(data.data, true);
        setUserState(data.data);
        setProfileImage(data.data.profile_pic + "?" + new Date().getTime());
        setSignatureImage(data.data.signature + "?" + new Date().getTime());
        setToast("success", `Profile Successfully Updated`);
        navigate("/");
      } else {
        const errorData = await response.json();
        if (errorData.errors) {
          showErrorsInToasts(errorData.errors);
        } else {
          setToast("error", "Failed to update profile");
        }
      }
    } catch (error: any) {
      setToast("error", "Failed to update profile");
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setEditedProfile((prev) => ({
      ...prev,
      profilePictureUrl: file ? file : "",
    }));
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setProfileImage(fileURL); // Update state to show the image in real-time
    }
  };

  const handleSignatureChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setEditedProfile((prev) => ({
      ...prev,
      signatureUrl: file ? file : "",
    }));
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setSignatureImage(fileURL); // Update state to show the image in real-time
    }
  };

  return (
    <Container>
      <Card className="mt-3 mb-3">
        <Card.Body>
          <Card.Title
            className="text-center mb-4"
            style={{ fontWeight: "600" }}
          >
            Profile
          </Card.Title>
          <Row>
            <Col md={8}>
              <Form>
                <Form.Group controlId="formFirstName" className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={editedProfile.first_name}
                    onChange={(e) =>
                      setEditedProfile((prev) => ({
                        ...prev,
                        first_name: e.target.value,
                      }))
                    }
                    readOnly={!isProfileEditStatus}
                  />
                </Form.Group>

                <Form.Group controlId="formLastName" className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={editedProfile.last_name}
                    onChange={(e) =>
                      setEditedProfile((prev) => ({
                        ...prev,
                        last_name: e.target.value,
                      }))
                    }
                    readOnly={!isProfileEditStatus}
                  />
                </Form.Group>

                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={editedProfile.email}
                    onChange={(e) =>
                      setEditedProfile((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    readOnly={!isProfileEditStatus}
                  />
                </Form.Group>

                <Form.Group controlId="formPhone" className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  {isProfileEditStatus ? (
                    <PhoneInput
                      international
                      className="form-control"
                      value={editedProfile.phone_no}
                      onChange={(e) =>
                        setEditedProfile((prev) => ({
                          ...prev,
                          phone_no: e,
                        }))
                      }
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      value={editedProfile.phone_no}
                      readOnly
                    />
                  )}
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Button variant="secondary" onClick={() => navigate("/")}>
                    Back
                  </Button>
                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={() => {
                      if (isProfileEditStatus) {
                        handleProfileUpdate();
                      }
                      setProfileEditStatus(!isProfileEditStatus);
                    }}
                  >
                    {isProfileEditStatus ? "Save" : "Edit Profile"}
                  </Button>
                </div>
              </Form>
            </Col>
            <Col md={4} className="text-center">
              <div
                className="mb-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Form.Label style={{ fontWeight: "600" }}>
                  Profile Picture
                </Form.Label>
                <img
                  src={profileImage || "/images/svg/user-profile.svg"}
                  onError={(e: any) => {
                    e.target.onerror = null; // Prevent infinite loop in case the fallback image also fails
                    e.target.src = "images/svg/user-profile.svg"; // Set the alternative image
                  }}
                  alt={"Not Found"}
                  className="img-fluid rounded-circle mb-2"
                  style={{
                    width: "150px",
                    height: "150px",
                    ...(profileImage && {
                      boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                    }),
                  }}
                />
                {isProfileEditStatus && (
                  <input
                    className="form-control"
                    type="file"
                    id="fileInput"
                    onChange={handleFileChange}
                    accept="image/*"
                    style={{ width: "auto" }}
                  />
                )}
              </div>
              <div
                className="mb-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Form.Label style={{ fontWeight: "600" }}>
                  Profile Signature
                </Form.Label>
                <img
                  src={signatureImage || "/images/svg/no-image.svg"}
                  alt="signature"
                  className="img-fluid mb-2"
                  onError={(e: any) => {
                    e.target.onerror = null; // Prevent infinite loop in case the fallback image also fails
                    e.target.src = "images/svg/no-image.svg"; // Set the alternative image
                  }}
                  style={{
                    width: "270px",
                    height: "120px",
                    ...(signatureImage && {
                      boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                    }),
                  }}
                />
                {isProfileEditStatus && (
                  <input
                    className="form-control mt-2"
                    type="file"
                    id="signatureInput"
                    onChange={handleSignatureChange}
                    accept="image/*"
                    style={{ width: "auto" }}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProfilePage;
