import React from "react";
import { useParams } from "react-router-dom";

interface ErrorHandlerProps {
  error?: {
    code?: string;
    message: string;
  };
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({ error }) => {
  const { code, msg } = useParams();
  if (!error) {
    error = {
      code: code,
      message: msg!,
    };
  }
  return (
    <div
      style={{
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#f8d7da",
        color: "#721c24",
      }}
    >
      <strong>Error:</strong> {error!.message}
      {error!.code && (
        <div style={{ marginTop: "10px" }}>
          <strong>Error Code:</strong> {error!.code}
        </div>
      )}
    </div>
  );
};

export default ErrorHandler;
