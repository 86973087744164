import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FieldDescription from "../../../Components/FieldDescription";

interface AddUpdateModelProps {
  show: boolean;
  handleClose?: () => void;
  widget?: React.ReactNode;
  title: string;
  formFields?: any;
  size?: any;
}

const AddUpdateModel: React.FC<AddUpdateModelProps> = ({
  show,
  handleClose,
  widget,
  title,
  formFields,
  size,
}) => {
  const [isShows, setIsShows] = useState<boolean>(false);
  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={size ? size : "lg"}
        dialogClassName="custom-modal"
        className="mt-3"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
            {formFields && (
              <span style={{ marginLeft: "15px" }}>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={handleHelpButtonClick}
                >
                  Need Help?
                </Button>
              </span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {widget}
          {formFields && (
            <div id="form-bottom">
              <FieldDescription
                title={title}
                formFields={formFields}
                isShows={isShows}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddUpdateModel;
