import { setCookie, getCookie, removeCookie } from "typescript-cookie";
const TOKEN_KEY = "authToken";
const USER_KEY = "user";
const PERMISSION_KEY = "permission";
const EXPIRES_TIME = 1; // 1 day

export const setToken = (token: string) => {
  const existingToken = getCookie(TOKEN_KEY);
  if (!existingToken) {
    setCookie(TOKEN_KEY, JSON.stringify(token), {
      expires: EXPIRES_TIME,
      path: "/",
    });
  }
};

export const getToken = () => {
  return getCookie(TOKEN_KEY);
};

export const setUser = (user: any, forceUpdate?: any) => {
  const existingUser = getCookie(USER_KEY);
  if (!existingUser) {
    setCookie(USER_KEY, JSON.stringify(user), {
      expires: EXPIRES_TIME,
      path: "/",
    });
  } else if (forceUpdate) {
    setCookie(USER_KEY, JSON.stringify(user), {
      expires: EXPIRES_TIME,
      path: "/",
    });
  }
};

export const getPermissions = () => {
  return getCookie(PERMISSION_KEY);
};

export const setPermissions = (data: any) => {
  setCookie(PERMISSION_KEY, JSON.stringify(data), {
    expires: EXPIRES_TIME,
    path: "/",
  });
};

export const getUser = () => {
  const user = getCookie(USER_KEY);
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export const removeToken = () => {
  removeCookie(TOKEN_KEY, { path: "/" });
  removeCookie(USER_KEY, { path: "/" });
  removeCookie(PERMISSION_KEY, { path: "/" });
};
