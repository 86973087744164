import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useApi } from "../Controller/ApiController";
import { ActionMeta, components } from "react-select";
import { setToast } from "../Util/Toast";
import { PlusCircleFill } from "react-bootstrap-icons";
import { AsyncPaginate, wrapMenuList } from "react-select-async-paginate";
import { CapitalizeAndRemoveUnderscore } from "../Util/CapitalizeAndRemoveUnderscore";

interface CustomAsyncSelectProps {
  id?: any;
  inputfield: any;
  inputfieldSecond?: any;
  fieldName: any;
  isMultiSelect: boolean;
  formData: any;
  model: any;
  onChange: (newValue: any, actionMeta: ActionMeta<any>) => void;
  defaultOptions?: any;
  label: any;
  isMenuPortalTarget?: any;
  customTheme?: any;
  customValue?: any;
  placeholder?: any;
  customComponents?: any;
  isNeedFullData?: any;
  isdisabled?: boolean;
  addNewBtnStatus?: boolean;
  customFilter?: any;
  usePublicApi?: any;
  publicApiToken?: any;
  componentKey?: any;
  includeWith?: any;
  widget?: any;
  onChangeModalStatus?: (status: boolean) => void;
  // onChange?: (data: any) => void;
  modalStatus?: boolean;
  isNotSearchable?: boolean;
}

const CustomAsyncSelect: React.FC<CustomAsyncSelectProps> = ({
  id,
  inputfield,
  inputfieldSecond,
  fieldName,
  isMultiSelect,
  formData,
  model,
  onChange,
  defaultOptions,
  label,
  isMenuPortalTarget,
  customTheme,
  customValue,
  placeholder,
  isNeedFullData,
  isdisabled,
  customFilter,
  customComponents,
  addNewBtnStatus = false,
  usePublicApi,
  publicApiToken,
  componentKey,
  includeWith,
  widget,
  onChangeModalStatus,
  modalStatus,
  isNotSearchable,
}: CustomAsyncSelectProps) => {
  const { apiPost, apiPostPublicCountry } = useApi();
  async function loadOptions(
    search: any,
    loadedOptions: any,
    { page }: any,
  ): Promise<any> {
    if (defaultOptions !== undefined) {
      search = search.toLocaleLowerCase();
      if (search) {
        const filteredResults = defaultOptions.filter(
          (obj: { label: string }) => obj.label?.toLowerCase().includes(search),
        );
        return {
          options: filteredResults,
          hasMore: false,
        };
      } else {
        const modifiedOptions = defaultOptions.map((option: any) => {
          if (option.dangerouslySetInnerHTML) {
            return {
              label: (
                <span dangerouslySetInnerHTML={{ __html: option.label }} />
              ),
              modifieldLabel: CapitalizeAndRemoveUnderscore(option.value),
              value: option.value,
            };
          } else {
            return option;
          }
        });

        return {
          options: modifiedOptions,
          hasMore: false, // Adjust based on actual API response
        };
      }
    } else {
      try {
        let URL = new model().$resource();
        let dataList: any = null;
        if (usePublicApi) {
          dataList =
            search != null
              ? await apiPostPublicCountry(
                  `public/projects/country/${publicApiToken}?page=${page}&search=${search}`,
                )
              : await model.$query().get();

          const convertData = (originalData: any) => {
            const convertedData = {
              results: originalData.map((item: any) => ({
                label: item[inputfield],
                value: item["id"],
              })),
              hasMore: page * 10 < dataList.data.total, // Check if there are more pages to load
            };
            return convertedData;
          };
          const convertedData: any = convertData(dataList.data.data);
          return {
            options: convertedData.results,
            hasMore:
              dataList.data.total > loadedOptions.length
                ? convertedData.hasMore
                : false, // Adjust based on actual API response
            additional: {
              page: page + 1,
            },
          };
        } else {
          if (page) {
            dataList =
              search != null
                ? await apiPost(
                    `${URL}/search?${
                      includeWith ? "include=" + includeWith + "&" : ""
                    }page=${page}`,
                    {
                      search: { value: search },
                      filters: customFilter,
                    },
                  )
                : await model.$query().get();
            const convertData = (originalData: any) => {
              const convertedData = {
                results: originalData.map((item: any) => ({
                  label: inputfieldSecond
                    ? `${item[inputfield]} (${item[inputfieldSecond]})`
                    : item[inputfield],
                  value: item["id"],
                  ...(isNeedFullData && { data: item }),
                })),
                hasMore: page * 10 < dataList.meta.total, // Check if there are more pages to load
              };

              return convertedData;
            };
            const convertedData: any = convertData(dataList.data);
            return {
              options: convertedData.results,
              hasMore:
                dataList.meta.total > loadedOptions.length
                  ? convertedData.hasMore
                  : false, // Adjust based on actual API response
              additional: {
                page: page + 1,
              },
            };
          }
        }
      } catch (error: any) {
        setToast("error", error?.response?.data.message);
        return {
          options: [],
          hasMore: false,
        };
      }
    }
  }

  const onAddNewClick: (
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (onChangeModalStatus) {
      onChangeModalStatus(true);
    }
  };
  useEffect(() => {
    if (componentKey) {
      loadOptions("", "", 1);
    }
    // eslint-disable-next-line
  }, [componentKey]);

  const closeModal = () => {
    if (onChangeModalStatus) {
      onChangeModalStatus(false);
    }
  };
  const SelectMenuButton = (props: any) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        {addNewBtnStatus ? (
          <div style={{ position: "sticky", bottom: "-10px" }}>
            <button
              className="mt-1"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: "15px",
                width: "100%",
                backgroundColor: "#ffffff",
                textAlign: "left",
                fontSize: "14px",
                color: "#007bff",
              }}
              onClick={(e: any) => onAddNewClick(e)}
            >
              <PlusCircleFill className="mb-1 fs-5" /> Add New
            </button>
          </div>
        ) : (
          ""
        )}
      </components.MenuList>
    );
  };
  const MenuList = wrapMenuList(SelectMenuButton);
  return (
    <div>
      <AsyncPaginate
        id={id}
        loadOptions={loadOptions}
        isDisabled={isdisabled}
        key={typeof componentKey === "number" ? componentKey : undefined}
        loadOptionsOnMenuOpen={true}
        additional={{
          page: 1,
        }}
        theme={customTheme && customTheme}
        defaultOptions
        name={fieldName}
        className="basic-single"
        classNamePrefix="select"
        isSearchable={isNotSearchable ? false : true}
        isMulti={isMultiSelect}
        value={customValue ? customValue : formData[fieldName]}
        menuPortalTarget={isMenuPortalTarget && document.body}
        onChange={(selectedOption, actionMeta) => {
          if (selectedOption && selectedOption.value === "add_new") {
            onAddNewClick();
          } else {
            if (!isMultiSelect) {
              const modifiedLabel =
                selectedOption.modifieldLabel || selectedOption.label;
              const modifiedOption = {
                ...selectedOption,
                label: modifiedLabel,
              };
              onChange(modifiedOption, actionMeta);
            } else {
              onChange(selectedOption, actionMeta);
            }
          }
        }}
        placeholder={!defaultOptions ? placeholder : "Select..."}
        components={
          customComponents ? { MenuList: customComponents } : { MenuList }
        }
      />
      <Modal
        show={modalStatus}
        onHide={closeModal}
        dialogClassName="custom-modal"
        className="mt-3"
        size="lg"
      >
        <Modal.Body>{widget}</Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomAsyncSelect;
