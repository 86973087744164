import "bootstrap/dist/css/bootstrap.min.css";
import { Designation } from "../../../Model/Setting/Designation";
import {
  DESIGNATIONS_LIST,
  DESIGNATIONS_TITLE,
} from "../../../Routes/Setting/Designation";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { Button } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import { useState } from "react";

const AddUpdate = () => {
  const formFields = [
    {
      id: "inputName",
      label: "Name*",
      type: "text",
      col: 6,
      name: "name",
      placeholder: "Enter Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Name is required",
        },
      ],
      description:
        "Enter a descriptive name for the designation. (Example:'Marketing Specialist, ' 'Team Lead, ')",
    },
    {
      id: "inputDescription",
      label: "Description*",
      type: "text",
      col: 6,
      name: "description",
      placeholder: "Enter Description",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Description is required",
        },
      ],
      description:
        "Provide a brief description outlining the responsibilities and scope of the designation.<br />(Example: 'Responsible for developing and maintaining software solutions.')",
    },
  ];
  const [isShows, setIsShows] = useState<boolean>(false);
  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={formFields}
          model={Designation}
          title={DESIGNATIONS_TITLE}
          listRoute={DESIGNATIONS_LIST}
        />
      </div>
      <div id="form-bottom">
        <FieldDescription
          title={DESIGNATIONS_TITLE}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
