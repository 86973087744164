import { Model } from "@tailflow/laravel-orion/lib/model";

export class Category extends Model<{
  name: string;
  parent_id: any;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "categories";
  }
}
