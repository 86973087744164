import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { Tax } from "../../../Model/Setting/Tax";
import { TaxType } from "../../../Model/Setting/TaxType";
import { Country } from "../../../Model/Setting/Country";
import { setToast } from "../../../Util/Toast";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import Notes from "../../../Components/Notes";
import AddUpdateTaxType from "../../../Pages/Setting/TaxType/AddUpdate";
import ActivityLogs from "../../../Components/ActivityLogs";
import { TAX_TYPE_LIST, TAX_TYPE_TITLE } from "../../../Routes/Setting/TaxType";
interface AddUpdateProps {
  title?: any;
  listRoute?: any;
  isNotUpdate?: any;
  onChangeModalStatus?: (status: boolean) => void;
}

const AddUpdate: React.FC<AddUpdateProps> = ({
  title,
  listRoute,
  isNotUpdate,
  onChangeModalStatus,
}) => {
  const navigate = useNavigate();
  let { id }: any = useParams();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [isShows, setIsShows] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("notes");
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status
  const [taxTypeModalStatus, setTaxTypeModalStatus] = useState(false); // State variable to track form submission status

  if (isNotUpdate) {
    id = null;
  }
  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    let inputData = Object.assign({}, formData);
    const taxTypeIdNumber = parseInt(inputData.tax_type_id.value);
    const countryIdNumber = parseInt(inputData.country_id.value);
    inputData.tax_type_id = taxTypeIdNumber;
    inputData.country_id = countryIdNumber;

    try {
      if (inputData.id) {
        let item = await Tax.$query().find(inputData.id);
        item.$attributes = {
          ...item.$attributes,
          ...inputData,
        };

        item = await item.$save();
        setToast("success", `${title} Successfully Updated`);
      } else {
        await Tax.$query().store(inputData);
        setToast("success", `${title} Successfully Added`);
      }
      if (onChangeModalStatus) {
        onChangeModalStatus(false);
      } else {
        navigate(`${listRoute.toLowerCase().replace(" ", "-")}`);
      }
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const formFields = [
    {
      id: "inputtax_name",
      label: "Tax Name*",
      type: "text",
      col: 6,
      name: "tax_name",
      placeholder: "Enter Your Tax Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Tax Name is Required",
        },
      ],
      description:
        "Enter a clear and descriptive name for the tax. (Example: 'Value Added Tax(VAT)', 'Sales Tax', 'Goods and Services Tax(GST)' etc).",
    },
    {
      id: "inputRate",
      label: "Rate*",
      type: "text",
      col: 6,
      name: "rate",
      placeholder: "Select an Rate",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Rate is Required",
        },
      ],
      description:
        "Specify the applicable tax rate as a percentage. (Example: '15%', '8.5%', '20%' etc.)",
    },
    {
      id: "inputcountry_id",
      label: "Country*",
      type: "select",
      col: 6,
      name: "country_id",
      placeholder: "Select an Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is Required",
        },
      ],
      description:
        "Indicate the country to which the tax applies. (Example: 'United States', 'Canada', 'United Kingdom' etc.)",
    },
    {
      id: "inputtax_types_id",
      label: "Tax Type*",
      type: "select",
      col: 6,
      name: "tax_type_id",
      placeholder: "Select an Tax Type",
      searchField: "title",
      model: TaxType,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Tax Type is Required",
        },
      ],
      description:
        "Define the type of tax (e.g., sales tax, income tax, custom duty).",
      onChangeModalStatus: (status: boolean) => setTaxTypeModalStatus(status),
      modalStatus: taxTypeModalStatus,
      addNewBtnStatus: true,
      widget: (
        <AddUpdateTaxType
          title={TAX_TYPE_TITLE}
          listRoute={TAX_TYPE_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) =>
            setTaxTypeModalStatus(status)
          }
        />
      ),
    },
  ];

  const getFormData = async (itemId: string) => {
    const item: any = await Tax.$query()
      .with(["country", "taxType"])
      .find(itemId);

    return item.$attributes;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const itemData = await getFormData(id);
          let data = itemData;
          if (data["country_id"]) {
            data["country_id"] = {
              label: itemData.country["name"],
              value: itemData.country["id"],
            };
          }
          if (data["tax_type_id"]) {
            data["tax_type_id"] = {
              label: itemData.tax_type["title"],
              value: itemData.tax_type["id"],
            };
          }
          setFormData(data);
          setLoading(false);
        } catch (error: any) {
          setToast("error", error.response.data.message);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={formFields}
          model={Tax}
          title={title}
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          listRoute={listRoute}
          dynamicFormError={formError}
          isNotUpdate={isNotUpdate}
          customSubmitting={customSubmitting}
          loadingStatus={loading}
          onChangeModalStatus={onChangeModalStatus}
        />
      </div>

      {id && (
        <div className="card mt-4" style={{ marginBottom: "30px" }}>
          <div className="card-header d-flex">
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "notes" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("notes")}
            >
              Notes
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "activityLogs" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("activityLogs")}
            >
              Activity Logs
            </h5>
          </div>

          <div
            className="card-body"
            style={{
              height: "auto",
              overflow: "auto",
              backgroundColor: "#e4e4e4",
            }}
          >
            {activeTab === "notes" && <Notes id={id} model={Tax} />}
            {activeTab === "activityLogs" && (
              <ActivityLogs auditable_type={"TAXES"} id={id} />
            )}
          </div>
        </div>
      )}
      <div id="form-bottom">
        <FieldDescription
          title={title}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
