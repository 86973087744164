import { Model } from "@tailflow/laravel-orion/lib/model";

export class BatchManagement extends Model<{
  organization_id: any;
  batch_id: any;
  products_id: any;
  expiry_date: string;
  description: string;
  quantity: any;
  country_id: any;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  country: any;
  product: any;
}> {
  public $resource(): string {
    return "batches";
  }
}
