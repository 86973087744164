import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/UserType/List";
import AddUpdate from "../../Pages/Setting/UserType/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_USER_TYPE,
  DELETE_USER_TYPE,
  RESTORE_USER_TYPE,
  UPDATE_USER_TYPE,
  VIEW_USER_TYPE,
} from "../../Util/PermissionList";

export const USER_TYPE_TITLE = "User Type";
export const USER_TYPE_LIST = "/user-types";
export const USER_TYPE_ADD = "/user-types/add";
export const USER_TYPE_EDIT = "/user-types/:id/edit";
export const USER_TYPE_ARCHIVE = "/user-types/archive";

const UserType: RouteObject[] = [
  {
    path: USER_TYPE_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_USER_TYPE}
        checkPermisson={{
          addPermission: ADD_USER_TYPE,
          updatePermission: UPDATE_USER_TYPE,
          deletePermission: DELETE_USER_TYPE,
          restorePermission: RESTORE_USER_TYPE,
          viewPermission: VIEW_USER_TYPE,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: USER_TYPE_ADD,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate title={USER_TYPE_TITLE} listRoute={USER_TYPE_LIST} />
        }
        checkPermisson={{
          addPermission: ADD_USER_TYPE,
          updatePermission: UPDATE_USER_TYPE,
          deletePermission: DELETE_USER_TYPE,
          restorePermission: RESTORE_USER_TYPE,
          viewPermission: VIEW_USER_TYPE,
        }}
        permission={ADD_USER_TYPE}
      />
    ),
  },
  {
    path: USER_TYPE_EDIT,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate title={USER_TYPE_TITLE} listRoute={USER_TYPE_LIST} />
        }
        checkPermisson={{
          addPermission: ADD_USER_TYPE,
          updatePermission: UPDATE_USER_TYPE,
          deletePermission: DELETE_USER_TYPE,
          restorePermission: RESTORE_USER_TYPE,
          viewPermission: VIEW_USER_TYPE,
        }}
        permission={UPDATE_USER_TYPE}
      />
    ),
  },
  {
    path: USER_TYPE_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_USER_TYPE,
          updatePermission: UPDATE_USER_TYPE,
          deletePermission: DELETE_USER_TYPE,
          restorePermission: RESTORE_USER_TYPE,
          viewPermission: VIEW_USER_TYPE,
        }}
        permission={DELETE_USER_TYPE}
      />
    ),
  },
];

export default UserType;
