import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { Country } from "../../../Model/Setting/Country";
import { setToast } from "../../../Util/Toast";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import { OrganizationAddress } from "../../../Model/Setting/OrganizationAddress";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";

interface AddUpdateProps {
  title?: any;
  listRoute?: any;
  isNotUpdate?: any;
}

const AddUpdate: React.FC<AddUpdateProps> = ({
  title,
  listRoute,
  isNotUpdate,
}) => {
  let { id }: any = useParams();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [isShows, setIsShows] = useState<boolean>(false);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status

  const formFields = [
    {
      id: "inputAddressType",
      label: "Address Type*",
      addNewBtnStatus: false,
      type: "select",
      col: 6,
      name: "address_type",
      placeholder: "Enter Name",
      options: [
        { label: "Billing", value: "billing" },
        { label: "Delivery", value: "delivery" },
      ],
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Address Type is required",
        },
      ],
      description: "Specify the type of address.",
    },
    {
      id: "inputAddressName",
      label: "Address Name*",
      type: "text",
      col: 6,
      name: "address_name",
      placeholder: "Enter Address Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Address Name is required",
        },
      ],
      description:
        "Provide a unique name for the address. Use underscores to separate words. Keep it short and descriptive.",
    },
    {
      id: "inputAddressLine1",
      label: "Address Line 1*",
      type: "text",
      col: 6,
      name: "address_line1",
      placeholder: "Enter Address Line 1",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Address Line 1 is required",
        },
      ],
      description: "Enter the first line of the address.",
    },

    {
      id: "inputAddressLine2",
      label: "Address Line 2",
      type: "text",
      col: 6,
      name: "address_line2",
      placeholder: "Enter Address Line 2",
      description: "Enter the second line of the address, if applicable.",
    },
    {
      id: "inputEmail",
      label: "Email",
      type: "email",
      col: 6,
      name: "email",
      placeholder: "Enter email",
      description: "Enter the email address.",
    },
    {
      id: "inputphone_no",
      label: "Phone Number",
      type: "phone_no",
      col: 6,
      name: "phone_no",
      placeholder: "Enter Phone Number",
      description: "Enter the Phone Number.",
    },
    {
      id: "inputfax_number",
      label: "Fax Number",
      type: "input",
      col: 6,
      name: "fax_no",
      placeholder: "Enter Fax Number",
      description: "Enter the Fax Number.",
    },
    {
      id: "inputZipCode",
      label: "ZIP Code*",
      type: "text",
      col: 6,
      name: "zip_code",
      placeholder: "Enter ZIP Code",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "ZIP Code is required",
        },
      ],
      description: "Enter the ZIP code for the address.",
    },
    {
      id: "inputCity",
      label: "City*",
      type: "text",
      col: 6,
      name: "city",
      placeholder: "Enter City",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "City is required",
        },
      ],
      description: "Enter the city where the address is located.",
    },
    {
      id: "inputState",
      label: "State*",
      type: "text",
      col: 6,
      name: "state",
      placeholder: "Enter State",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "State is required",
        },
      ],
      description: "Enter the state where the address is located.",
    },
    {
      id: "inputCountryId",
      label: "Country*",
      type: "select",
      col: 6,
      name: "country_id",
      placeholder: "Select a Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is required",
        },
      ],
      description: "Select the country where the address is located.",
    },
  ];

  const navigate = useNavigate();
  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    const {
      address_name,
      address_line1,
      address_line2,
      city,
      state,
      zip_code,
      country_id,
      fax_no,
      phone_no,
      email,
    } = formData;

    const neededData: any = {
      address_name,
      address_line1,
      address_line2,
      city,
      state,
      zip_code,
      country_id,
      fax_no,
      phone_no,
      email,
    };
    neededData.address_type = formData.address_type?.value;
    if (neededData.type === "select") {
      neededData.data = [
        {
          option_type: "options",
          optionValue: Object.entries(formData.options).map(
            ([key, value]: any) => ({
              id: value.id,
              name: value.name || value,
            }),
          ),
        },
      ];
    }
    delete neededData.options;
    const countryIdNumber = parseInt(neededData.country_id.value);

    neededData.country_id = countryIdNumber;

    try {
      if (formData.id) {
        await OrganizationAddress.$query().update(formData.id, neededData);
        setToast("success", `${title} Successfully Updated`);
      } else {
        await OrganizationAddress.$query().store(neededData);
        setToast("success", `${title} Successfully Added`);
      }
      navigate(`${listRoute?.toLowerCase().replace(" ", "-")}`);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const getFormData = async (itemId: string) => {
    const item: any = await OrganizationAddress.$query()
      .with(["country"])
      .find(itemId);

    return item.$attributes;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const itemData = await getFormData(id);
          let data = itemData;
          if (data["address_type"]) {
            data["address_type"] = {
              label: CapitalizeAndRemoveUnderscore(itemData.address_type),
              value: itemData.address_type,
            };
          }
          if (data["country_id"]) {
            data["country_id"] = {
              label: itemData.country["name"],
              value: itemData.country["id"],
            };
          }
          setFormData(data);
          setLoading(false);
        } catch (error: any) {
          setToast("error", error.response.data.message);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={formFields}
          model={OrganizationAddress}
          title={title}
          listRoute={listRoute}
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          dynamicFormError={formError}
          customSubmitting={customSubmitting}
          isNotUpdate={isNotUpdate}
          loadingStatus={loading}
        />
      </div>
      {/* {id && <Notes id={id} model={Store}></Notes>} */}
      <div id="form-bottom">
        <FieldDescription
          title={title}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
