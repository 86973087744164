import "bootstrap/dist/css/bootstrap.min.css";
import { LogisticDetail } from "../../../Model/Setting/LogisticDetail";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { Button } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import { useEffect, useState } from "react";
import { DynamicFormFields } from "../../../Util/DynamicFormFields";
interface AddUpdateProps {
  title?: any;
  listRoute?: any;
  isNotUpdate?: any;
  onChangeModalStatus?: (status: boolean) => void;
}

const AddUpdate: React.FC<AddUpdateProps> = ({
  title,
  listRoute,
  isNotUpdate,
  onChangeModalStatus,
}) => {
  const formFields = [
    {
      id: "inputName",
      label: "Name*",
      type: "text",
      col: 6,
      name: "name",
      placeholder: "Enter Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Name is required",
        },
      ],
      description:
        "Enter a unique and descriptive name for the logistic detail.(Example: 'Shipment ID', 'Delivery Route', 'Carrier Information' etc.)",
    },
    {
      id: "inputDescription",
      label: "Description*",
      type: "text",
      col: 6,
      name: "description",
      placeholder: "Enter Description",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Description is required",
        },
      ],
      description:
        "Provide a brief description outlining the purpose and relevance of the logistic detail. (Example: 'Identifier for tracking individual shipments.')",
    },
  ];
  const [isShows, setIsShows] = useState<boolean>(false);
  const [dynamicFormFields, setDynamicFormFields] = useState([]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    DynamicFormFields(formFields, "logistic_details").then(
      (updatedFormFields) => {
        setDynamicFormFields(updatedFormFields);
      },
    );
  }, []); // eslint-disable-line
  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={dynamicFormFields}
          model={LogisticDetail}
          title={title}
          listRoute={listRoute}
          entityName={"logistic-details"}
          entityType={"logistic_details"}
          isDynamicFormFields={true}
          isNotUpdate={isNotUpdate}
          onChangeModalStatus={onChangeModalStatus}
        />
      </div>
      <div id="form-bottom">
        <FieldDescription
          title={title}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};
export default AddUpdate;
