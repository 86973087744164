import React, { useEffect, useState } from "react";
import { Form, Card, Table, Container, Navbar } from "react-bootstrap";
import CustomButton from "../../Components/Button/CustomButton";
import { useApi } from "../../Controller/ApiController";
import { setToast } from "../../Util/Toast";
import { Download, PlusCircleFill, XCircle } from "react-bootstrap-icons";
import CustomAsyncSelect from "../../Components/AsyncSelect";
import { useParams } from "react-router-dom";
import { setCookie } from "typescript-cookie";
import { PublicCountry } from "../../Model/Setting/PublicCountry";
import { Link } from "react-router-dom";
import { showErrorsInToasts } from "../../Util/ErrorMessageToast";

const QuotationFillupForm = () => {
  setCookie("publicRoute", true);
  const { token } = useParams();
  const [formErrors, setFormErrors] = useState<any>({});
  const [items, setItems] = useState<any[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState<any[]>([
    {
      manuf_company_name: "",
      manuf_country_id: "",
      pack_size: "",
      MOQ: "",
      price: "",
      lead_time: "",
      alternative_company_site: "",
      dossier_content: null,
      formula: null,
    },
  ]);
  const [rowCount, setRowCount] = useState<any>(1);
  const [invalid, setInvalid] = useState<any>(false);

  const { apiGetPublic, apiPostPublic } = useApi();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productData: any = await getProduct();
        setItems(productData.data);
      } catch (error: any) {
        setInvalid(true);
        if (error.response?.data?.errors) {
          setToast("error", error.response.data.errors);
        } else if (error.response?.data?.message) {
          setToast("error", error.response.data.message);
        } else {
          setToast("error", "An error occurred while processing your request");
        }
      }
    };
    fetchData();
    //eslint-disable-next-line
  }, []);

  const getProduct = async () => {
    try {
      const productData = await apiGetPublic(`public/projects/${token}`);
      return productData;
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setToast("error", error.response.data.errors);
      } else if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      let errors: any = {};
      const formattedFormData = formData.reduce(
        (acc: any, item: any, index: number) => {
          Object.entries(item).forEach(([key, value]: [string, any]) => {
            if (value || key === "comment") {
              acc[`quotations[${index}][${key}]`] = value;
            } else {
              acc[`quotations[${index}][${key}]`] = " ";
              let errorKey = key
                .replace(/^manuf/, "Manufacturer")
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
              const errorMessage = `${errorKey} is required`;
              errors[index] = {
                ...errors[index],
                [key]: errorMessage,
              };
            }
          });
          return acc;
        },
        {},
      );
      setFormErrors(errors);
      if (Object.keys(errors).length > 0) {
        setToast("error", "Validation failed. Please check the form fields");
        return;
      }

      const formDataToSend = new FormData();

      Object.entries(formattedFormData).forEach(([key, value]: any) => {
        formDataToSend.append(key, value);
      });

      // Now, formDataToSend contains the data in the desired format

      const productData: any = await apiPostPublic(
        `public/projects-manuf-quotations/${token}`,
        formDataToSend,
      );
      if (!productData.ok) {
        const errorResponse = await productData.json();
        if (errorResponse && errorResponse.errors) {
          showErrorsInToasts(errorResponse.errors);
        }
      } else {
        setToast("success", "Successfully Added");
      }
    } catch (error: any) {
      if (error.response && error.response.data.errors) {
        showErrorsInToasts(error.response.data.errors);
      } else if (error.response.data.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setSubmitting(false); // Set submitting state to false after API response or error
    }
    // Pass formattedFormData to your submit function
  };

  const handleAddItem = () => {
    setRowCount((prevCount: any) => prevCount + 1);
    setFormData((prevData) => [
      ...prevData,
      {
        manuf_company_name: "",
        manuf_country_id: "",
        pack_size: "",
        MOQ: "",
        price: "",
        lead_time: "",
        alternative_company_site: "",
        dossier_content: null,
        formula: null,
      },
    ]);
  };

  const handleChange = (e: any, rowIndex: number, fieldName: string) => {
    let newData = [...formData];
    if (fieldName === "dossier_content" || fieldName === "formula") {
      newData[rowIndex][fieldName] = e.target.files[0];
    } else if (fieldName === "manuf_country_id") {
      newData[rowIndex][fieldName] = e.value;
    } else {
      newData[rowIndex][fieldName] = e.target.value;
    }
    setFormData(newData);
  };
  const removeItem = (index: number) => {
    setFormData((prevData) => prevData.filter((_, i) => i !== index));
    setRowCount((prevCount: any) => prevCount - 1);
  };
  const customTheme = (theme: any) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    },
  });
  if (invalid) {
    return (
      <Container fluid>
        <div
          style={{
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            backgroundColor: "#f8d7da",
            color: "#721c24",
            marginTop: "10px",
          }}
        >
          <strong>Error:</strong> Not Access
        </div>
      </Container>
    );
  }
  return (
    <div className="">
      <Navbar bg="light" expand="lg" className="text-nowrap">
        <Navbar.Brand as={Link} to="/" style={{ paddingLeft: "40px" }}>
          <img
            src="/images/svg/ic_logo.svg"
            width="130"
            height="60"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
      </Navbar>
      <Container fluid>
        <>
          <div className="row py-4 m-0">
            <h2>Products</h2>
            <Card>
              <Card.Body>
                <Table className="table table-borderless w-max-content mb-2 font-12">
                  <thead>
                    <tr className="text-center">
                      <th>#</th>
                      <th>Ingredients</th>
                      <th>Dosage Form</th>
                      <th>Filling</th>
                      <th>Type of Filling</th>
                      <th>Concentration</th>
                      <th>Packaging material</th>
                    </tr>
                  </thead>
                  <tbody className="bg-transparent">
                    {items?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Form.Control
                            type="text"
                            className="py-1 form-control-bottom-border font-12"
                            value={item.ingredients}
                            required
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            className="py-1 form-control-bottom-border font-12"
                            value={item.dosage_form}
                            required
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            className="py-1 form-control-bottom-border font-12"
                            value={item.filling}
                            required
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            className="py-1 form-control-bottom-border font-12"
                            value={item.type_of_filling}
                            required
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            className="py-1 form-control-bottom-border font-12"
                            value={item.concentration}
                            required
                          />
                        </td>
                        <td className="d-flex justify-content-center">
                          <a
                            href={item.packaging_material}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Download
                              style={{ fill: "green", fontSize: "15px" }}
                            ></Download>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
          <div className="row py-4 m-0">
            <h2>Need to Fill</h2>
            <Card>
              <Card.Body>
                <Table className="table table-borderless w-max-content mb-2 bg-transparent font-12">
                  <thead className="bg-transparent">
                    <tr className="text-center">
                      <th>#</th>
                      <th>Manufacturing Company Name*</th>
                      <th>Manufacturing Company Country*</th>
                      <th>Pack Size*</th>
                      <th>MOQ*</th>
                      <th>Price*</th>
                      <th>Lead Time*</th>
                      <th>Alternative Site/Company*</th>
                      <th>Dossier Content*</th>
                      <th>Formula*</th>
                      <th>Comment</th>
                    </tr>
                  </thead>
                  <tbody className="bg-transparent">
                    {[...Array(rowCount)].map((_, index: any) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <span style={{ minWidth: "100px" }}>
                            <Form.Control
                              type="text"
                              className="py-1 form-control-bottom-border font-12"
                              value={formData[index]?.manuf_company_name || ""}
                              onChange={(e) =>
                                handleChange(e, index, "manuf_company_name")
                              }
                              required
                              placeholder="Enter Manufacturing Company Name"
                            />
                          </span>
                          {formErrors[index]?.manuf_company_name && (
                            <p className="text-danger">
                              {formErrors[index]?.manuf_company_name}
                            </p>
                          )}
                        </td>
                        <td
                          style={{
                            minWidth: "130px",
                            maxWidth: "150px",
                            fontSize: "12px",
                          }}
                          className="form-control-bottom-border"
                        >
                          <CustomAsyncSelect
                            customTheme={customTheme}
                            // model={Country}
                            model={PublicCountry}
                            // defaultOptions={[
                            //   { label: "United States", value: 1 },
                            //   { label: "Canada", value: 2 },
                            //   {
                            //     label: "Afghanistan",
                            //     value: 3,
                            //   },
                            //   { label: "Albania", value: 4 },
                            //   { label: "Algeria", value: 5 },
                            //   { label: "American Samoa", value: 6 },
                            //   { label: "Andorra", value: 7 },
                            //   { label: "Angola", value: 8 },
                            //   { label: "Anguilla", value: 9 },
                            //   { label: "Antarctica", value: 10 },
                            // ]}
                            inputfield={"name"}
                            label="Country"
                            fieldName={"country_id"}
                            isMultiSelect={false}
                            formData={"formData"}
                            placeholder={"Select Country"}
                            onChange={(selectedOption) =>
                              handleChange(
                                selectedOption,
                                index,
                                "manuf_country_id",
                              )
                            }
                            addNewBtnStatus={false}
                            usePublicApi={true}
                            publicApiToken={token}
                          />
                          {formErrors[index]?.manuf_country_id && (
                            <p className="text-danger">
                              {formErrors[index]?.manuf_country_id}
                            </p>
                          )}
                        </td>
                        <td>
                          <span style={{ minWidth: "110px" }}>
                            <Form.Control
                              type="text"
                              className="py-1 form-control-bottom-border font-12"
                              value={formData[index]?.pack_size || ""}
                              onChange={(e) =>
                                handleChange(e, index, "pack_size")
                              }
                              required
                              placeholder="Enter Pack Size"
                            />
                          </span>
                          {formErrors[index]?.pack_size && (
                            <p className="text-danger">
                              {formErrors[index]?.pack_size}
                            </p>
                          )}
                        </td>
                        <td style={{ minWidth: "65px" }}>
                          <Form.Control
                            type="text"
                            className="py-1 form-control-bottom-border font-12"
                            value={formData[index]?.MOQ || ""}
                            onChange={(e) => handleChange(e, index, "MOQ")}
                            required
                            placeholder="Enter MOQ"
                          />
                          {formErrors[index]?.MOQ && (
                            <p className="text-danger">
                              {formErrors[index]?.MOQ}
                            </p>
                          )}
                        </td>
                        <td style={{ minWidth: "70px" }}>
                          <Form.Control
                            type="text"
                            className="py-1 form-control-bottom-border font-12"
                            value={formData[index]?.price || ""}
                            onChange={(e) => handleChange(e, index, "price")}
                            required
                            placeholder="Enter Price"
                          />
                          {formErrors[index]?.price && (
                            <p className="text-danger">
                              {formErrors[index]?.price}
                            </p>
                          )}
                        </td>
                        <td style={{ minWidth: "100px" }}>
                          <Form.Control
                            type="text"
                            className="py-1 form-control-bottom-border font-12"
                            value={formData[index]?.lead_time || ""}
                            onChange={(e) =>
                              handleChange(e, index, "lead_time")
                            }
                            required
                            placeholder="Enter Lead Time"
                          />
                          {formErrors[index]?.lead_time && (
                            <p className="text-danger">
                              {formErrors[index]?.lead_time}
                            </p>
                          )}
                        </td>
                        <td style={{ minWidth: "100px" }}>
                          <Form.Control
                            type="text"
                            className="py-1 form-control-bottom-border font-12"
                            value={
                              formData[index]?.alternative_company_site || ""
                            }
                            onChange={(e) =>
                              handleChange(e, index, "alternative_company_site")
                            }
                            required
                            placeholder="Enter Alternative Site/Company	"
                          />
                          {formErrors[index]?.alternative_company_site && (
                            <p className="text-danger">
                              {formErrors[index]?.alternative_company_site}
                            </p>
                          )}
                        </td>
                        <td style={{ minWidth: "100px" }}>
                          <Form.Control
                            type="file"
                            className="py-1 form-control-bottom-border font-12"
                            onChange={(e) =>
                              handleChange(e, index, "dossier_content")
                            }
                            required
                            placeholder="Enter "
                          />
                          {formErrors[index]?.dossier_content && (
                            <p className="text-danger">
                              {formErrors[index]?.dossier_content}
                            </p>
                          )}
                        </td>
                        <td style={{ minWidth: "100px" }}>
                          <Form.Control
                            type="file"
                            className="py-1 form-control-bottom-border font-12"
                            onChange={(e) => handleChange(e, index, "formula")}
                            required
                          />
                          {formErrors[index]?.formula && (
                            <p className="text-danger">
                              {formErrors[index]?.formula}
                            </p>
                          )}
                        </td>
                        <td style={{ minWidth: "100px" }}>
                          <Form.Control
                            type="text"
                            className="py-1 form-control-bottom-border font-12"
                            value={formData[index]?.comment || ""}
                            onChange={(e) => handleChange(e, index, "comment")}
                            required
                            placeholder="Enter Comment"
                          />
                        </td>
                        {index > 0 && (
                          <td>
                            <span
                              onClick={() => removeItem(index)}
                              style={{ cursor: "pointer", color: "red" }}
                            >
                              <XCircle />
                            </span>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex" style={{ marginBottom: "-10px" }}>
                  <div>
                    <footer style={{ cursor: "pointer", color: "blue" }}>
                      <span
                        onClick={handleAddItem}
                        style={{ width: "maxContent" }}
                      >
                        <p>
                          Add Item <PlusCircleFill />
                        </p>
                      </span>
                    </footer>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="mb-4 d-flex justify-content-end mt-3">
            <CustomButton
              type="submit"
              label={"Submit"}
              variant="primary"
              onClick={handleSubmit}
              size="sm"
              disableStatus={submitting ? true : false}
            />
          </div>
        </>
      </Container>
    </div>
  );
};

export default QuotationFillupForm;
