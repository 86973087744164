import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { StockInventory } from "../../../Model/Inventory/StockInventory";
import {
  STOCK_INVENTORY_LIST,
  STOCK_INVENTORY_TITLE,
} from "../../../Routes/Inventory/StockInventory";
import { Product } from "../../../Model/Inventory/Product";
import AddUpdateProduct from "../../../Pages/Inventory/Product/AddUpdate";
import AddUpdateStore from "../../../Pages/Setting/Store/AddUpdate";
import AddUpdateUom from "../../../Pages/Setting/UnitOfMeasurement/AddUpdate";
import { PRODUCT_LIST, PRODUCT_TITLE } from "../../../Routes/Inventory/Product";
import { BatchManagement } from "../../../Model/SalesAndPurchase/BatchManagement/BatchManagement";
import { Store } from "../../../Model/Setting/Store";
import { STORE_LIST, STORE_TITLE } from "../../../Routes/Setting/Store";
import {
  UNIT_OF_MEASUREMENT_LIST,
  UNIT_OF_MEASUREMENT_TITLE,
} from "../../../Routes/Setting/UnitOfMeasurement";
import { useApi } from "../../../Controller/ApiController";
import { setToast } from "../../../Util/Toast";
import { UnitOfMeasurement } from "../../../Model/Setting/UnitOfMeasurement";
import FieldDescription from "../../../Components/FieldDescription";

interface AddUpdateStockModalProps {
  show?: boolean;
  handleClose?: () => void;
  product?: any;
  batch?: any;
  // onSubmit: () => void;
}

const AddUpdate: React.FC<AddUpdateStockModalProps> = ({
  show,
  handleClose,
  product,
  batch,
}) => {
  const { apiGet } = useApi();
  const [formData, setFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [customSubmitting, setCustomSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [productModalStatus, setProductModalStatus] = useState(false);
  const [uomModalStatus, setUomModalStatus] = useState(false);
  const [storeModalStatus, setStoreModalStatus] = useState(false);
  const [isShows, setIsShows] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState(null);

  const DocumentNumber = async () => {
    try {
      const response = await apiGet(
        `next-document-number/inventory_document_approval`,
      );
      const responseForManualAdjustment = await apiGet(
        `next-document-number/manual_adjustment`,
      );
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        document_number: response.data?.value,
        document_number_format_type: responseForManualAdjustment.data?.value,
      }));
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (product) {
      setSelectedProductId(product?.products_id?.value);
      setFormData(product);
    } else if (batch) {
      setFormData(batch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, batch]);

  let formFields = [
    {
      id: "inputDocumentNumber",
      label: "Document Number",
      type: "text",
      col: 6,
      name: "document_number",
      placeholder: "Enter Document Number",
      description:
        "Unique identifier for the document related to this stock action.",
      disabled: true,
    },
    {
      id: "inputProductId",
      label: "Product*",
      type: "select",
      includeWith: ["unitOfMeasurement"],
      col: 6,
      name: "products_id",
      placeholder: "Select Product",
      searchField: "product_name",
      disabled: product || batch || formData.batches_id ? true : false,
      isNeedFullData: true,
      addNewBtnStatus: false,
      model: Product,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Product is required",
        },
      ],
      description:
        "Select the product for which the stock action will be performed.",
      onChangeModalStatus: (status: boolean) => setProductModalStatus(status),
      modalStatus: productModalStatus,
      widget: (
        <AddUpdateProduct
          title={PRODUCT_TITLE}
          listRoute={PRODUCT_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) =>
            setProductModalStatus(status)
          }
        />
      ),
    },
    {
      id: "inputBatchId",
      label: "Batch",
      type: "select",
      col: 6,
      name: "batches_id",
      placeholder: "Select Batch",
      searchField: "batch_number",
      isNeedFullData: true,
      includeWith: ["product"],
      model: BatchManagement,
      disabled: batch && true,
      customFilter: [],
      description:
        "Select the batch from which the stock action will be performed.",
    },
    {
      id: "inputStoreId",
      label: "Store",
      type: "select",
      col: 6,
      name: "store_id",
      placeholder: "Select Store",
      searchField: "name",
      model: Store,
      description: "Choose the store where the stock action will take place.",
      addNewBtnStatus: false,
      onChangeModalStatus: (status: boolean) => setStoreModalStatus(status),
      modalStatus: storeModalStatus,
      widget: (
        <AddUpdateStore
          title={STORE_TITLE}
          listRoute={STORE_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setStoreModalStatus(status)}
        />
      ),
    },
    {
      id: "inputChangeQuantityByProduct",
      label: "Change Quantity By Product*",
      type: "number",
      col: 6,
      name: "change_quantity_by_product",
      placeholder: "Enter Change Quantity By Product",
      description:
        "Specify the quantity to be added or reduced for the selected product.",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Change Quantity By Product is required",
        },
      ],
    },
    {
      id: "inputStockAction",
      label: "Stock Action*",
      type: "radio",
      addNewBtnStatus: false,
      col: 6,
      name: "stock_action",
      options: [
        { label: "Add", value: "1" },
        { label: "Reduce", value: "0" },
      ],
      placeholder: "Enter Stock Action",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Stock Action is required",
        },
      ],
      description:
        "Choose whether to add to or reduce the stock quantity for the selected product.",
    },
    {
      id: "inputUnitOfMeasurementId",
      label: "Unit Of Measurement*",
      type: "select",
      col: 6,
      name: "unit_of_measurement_id",
      placeholder: "Select Unit Of Measurement",
      searchField: "name",
      disabled: product && true,
      model: UnitOfMeasurement,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Unit Of Measurement is required",
        },
      ],
      description: "Select the unit of measurement for the stock action.",
      addNewBtnStatus: true,
      onChangeModalStatus: (status: boolean) => setUomModalStatus(status),
      modalStatus: uomModalStatus,
      widget: (
        <AddUpdateUom
          title={UNIT_OF_MEASUREMENT_TITLE}
          listRoute={UNIT_OF_MEASUREMENT_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setUomModalStatus(status)}
        />
      ),
    },
    {
      id: "inputcomment",
      label: "Comment",
      type: "text",
      col: 6,
      name: "comment",
      placeholder: "Enter comment",
      description:
        "Provide any additional comments or details regarding the stock action.",
    },
  ];
  const [modifiedfields, setModifiedfields] = useState(formFields);
  const [RefreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const updatedFormFields: any = formFields.map((field: any) => {
      setRefreshKey((prev: any) => prev + 1);
      if (field.name === "batches_id") {
        delete field.customFilter;
        if (selectedProductId) {
          field.customFilter = [
            {
              field: "products_id",
              value: selectedProductId,
            },
          ];
          field.componentKey = RefreshKey;
        }
      }
      return field;
    });
    setModifiedfields(updatedFormFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductId, batch]);

  useEffect(() => {
    DocumentNumber();
    setIsShows(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleCloseModel = () => {
    handleClose && handleClose();
    setFormError(null);
    setFormData({});
    setSelectedProductId(null);
  };

  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    let inputData = Object.assign({}, formData);

    try {
      inputData.products_id = parseInt(inputData.products_id?.value);
      inputData.change_quantity_by_product = parseInt(
        inputData.change_quantity_by_product,
      );
      inputData.unit_of_measurement_id = parseInt(
        inputData.unit_of_measurement_id?.value,
      );
      inputData.batches_id = parseInt(inputData.batches_id?.value);
      inputData.store_id = parseInt(inputData.store_id?.value);
      inputData.document_type = "Manual Adjustment";
      delete inputData.options;
      await StockInventory.$query().store(inputData);
      setToast("success", `${STOCK_INVENTORY_TITLE} Successfully Added`);
      // onSubmit();
      handleCloseModel();
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (typeof error.response?.data?.message === "object") {
        const dynamicErrors = Object.keys(error.response.data.message)
          .map((key) => error.response.data.message[key])
          .filter((value) => typeof value === "string");

        setFormError(dynamicErrors.join(", "));
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };
  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <Modal show={show} onHide={handleCloseModel} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Update Product Stock
          <span style={{ marginLeft: "15px" }}>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={handleHelpButtonClick}
            >
              Need Help?
            </Button>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddUpdateForm
          formFields={modifiedfields}
          model={StockInventory}
          title={STOCK_INVENTORY_TITLE}
          customTitle=" "
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          listRoute={STOCK_INVENTORY_LIST}
          dynamicFormError={formError}
          customSubmitting={customSubmitting}
          loadingStatus={loading}
          isBackBtnDisable={true}
          autoUpdateField={true}
          onOptionChange={(selectedOption: any, field: any, _formData: any) => {
            if (field === "products_id") {
              setSelectedProductId(selectedOption.value);
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                batches_id: null,
                products_id: selectedOption,
                unit_of_measurement_id: {
                  label: selectedOption.data?.unit_of_measurement.name,
                  value: selectedOption.data?.unit_of_measurement?.id,
                },
              }));
            }
            if (field === "batches_id") {
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                batches_id: selectedOption,
                products_id: {
                  label: selectedOption.data?.product.product_name,
                  value: selectedOption.data?.product?.id,
                },
              }));
            }
          }}
        />
        <FieldDescription
          title={""}
          customTitle={"Help For Update Product Stock"}
          formFields={formFields}
          isShows={isShows}
        />
      </Modal.Body>
    </Modal>
  );
};

export default AddUpdate;
