// Category Permissions
export const VIEW_PAYMENT = "view-payment";
export const ADD_PAYMENT = "add-payment";
export const UPDATE_PAYMENT = "update-payment";
export const DELETE_PAYMENT = "delete-payment";
export const RESTORE_PAYMENT = "restore-payment";

// Reports Permissions
export const VIEW_REPORTS = "view-reports";
export const ADD_REPORTS = "add-reports";
export const UPDATE_REPORTS = "update-reports";
export const DELETE_REPORTS = "delete-reports";
export const RESTORE_REPORTS = "restore-reports";

// Purchase Permissions
export const VIEW_PURCHASE = "view-purchase";
export const ADD_PURCHASE = "add-purchase";
export const UPDATE_PURCHASE = "update-purchase";
export const DELETE_PURCHASE = "delete-purchase";
export const RESTORE_PURCHASE = "restore-purchase";

// Category Permissions
export const VIEW_CATEGORY = "view-category";
export const ADD_CATEGORY = "add-category";
export const UPDATE_CATEGORY = "update-category";
export const DELETE_CATEGORY = "delete-category";
export const RESTORE_CATEGORY = "restore-category";

// Attribute Permissions
export const VIEW_ATTRIBUTE = "view-attribute";
export const ADD_ATTRIBUTE = "add-attribute";
export const UPDATE_ATTRIBUTE = "update-attribute";
export const DELETE_ATTRIBUTE = "delete-attribute";
export const RESTORE_ATTRIBUTE = "restore-attribute";

// Bank Detail Permissions
export const VIEW_BANK_DETAIL = "view-bank-detail";
export const ADD_BANK_DETAIL = "add-bank-detail";
export const UPDATE_BANK_DETAIL = "update-bank-detail";
export const DELETE_BANK_DETAIL = "delete-bank-detail";
export const RESTORE_BANK_DETAIL = "restore-bank-detail";

// Counter Party Tag Permissions
export const VIEW_COUNTER_PARTY_TAG = "view-counter-party-tag";
export const ADD_COUNTER_PARTY_TAG = "add-counter-party-tag";
export const UPDATE_COUNTER_PARTY_TAG = "update-counter-party-tag";
export const DELETE_COUNTER_PARTY_TAG = "delete-counter-party-tag";
export const RESTORE_COUNTER_PARTY_TAG = "restore-counter-party-tag";

// Counter Party Type Permissions
export const VIEW_COUNTER_PARTY_TYPE = "view-counter-party-type";
export const ADD_COUNTER_PARTY_TYPE = "add-counter-party-type";
export const UPDATE_COUNTER_PARTY_TYPE = "update-counter-party-type";
export const DELETE_COUNTER_PARTY_TYPE = "delete-counter-party-type";
export const RESTORE_COUNTER_PARTY_TYPE = "restore-counter-party-type";

// Counter Party Company Permissions
export const VIEW_COUNTER_PARTY_COMPANY = "view-counter-party-company";
export const ADD_COUNTER_PARTY_COMPANY = "add-counter-party-company";
export const UPDATE_COUNTER_PARTY_COMPANY = "update-counter-party-company";
export const DELETE_COUNTER_PARTY_COMPANY = "delete-counter-party-company";
export const RESTORE_COUNTER_PARTY_COMPANY = "restore-counter-party-company";

// Counter Party Person Permissions
export const VIEW_COUNTER_PARTY_PERSON = "view-counter-party-person";
export const ADD_COUNTER_PARTY_PERSON = "add-counter-party-person";
export const UPDATE_COUNTER_PARTY_PERSON = "update-counter-party-person";
export const DELETE_COUNTER_PARTY_PERSON = "delete-counter-party-person";
export const RESTORE_COUNTER_PARTY_PERSON = "restore-counter-party-person";

// Counter Party Address Permissions
export const VIEW_COUNTER_PARTY_ADDRESS = "view-counter-party-address";
export const ADD_COUNTER_PARTY_ADDRESS = "add-counter-party-address";
export const UPDATE_COUNTER_PARTY_ADDRESS = "update-counter-party-address";
export const DELETE_COUNTER_PARTY_ADDRESS = "delete-counter-party-address";
export const RESTORE_COUNTER_PARTY_ADDRESS = "restore-counter-party-address";

// Country Permissions
export const VIEW_COUNTRY = "view-country";
export const ADD_COUNTRY = "add-country";
export const UPDATE_COUNTRY = "update-country";
export const DELETE_COUNTRY = "delete-country";
export const RESTORE_COUNTRY = "restore-country";

// Currency Permissions
export const VIEW_CURRENCY = "view-currency";
export const ADD_CURRENCY = "add-currency";
export const UPDATE_CURRENCY = "update-currency";
export const DELETE_CURRENCY = "delete-currency";
export const RESTORE_CURRENCY = "restore-currency";

// Designation Permissions
export const VIEW_DESIGNATION = "view-designation";
export const ADD_DESIGNATION = "add-designation";
export const UPDATE_DESIGNATION = "update-designation";
export const DELETE_DESIGNATION = "delete-designation";
export const RESTORE_DESIGNATION = "restore-designation";

// Lead Permissions
export const VIEW_LEAD = "view-lead";
export const ADD_LEAD = "add-lead";
export const UPDATE_LEAD = "update-lead";
export const DELETE_LEAD = "delete-lead";
export const RESTORE_LEAD = "restore-lead";

// Logistic Detail Permissions
export const VIEW_LOGISTIC_DETAIL = "view-logistic-detail";
export const ADD_LOGISTIC_DETAIL = "add-logistic-detail";
export const UPDATE_LOGISTIC_DETAIL = "update-logistic-detail";
export const DELETE_LOGISTIC_DETAIL = "delete-logistic-detail";
export const RESTORE_LOGISTIC_DETAIL = "restore-logistic-detail";

// Material Permissions
export const VIEW_MATERIAL = "view-material";
export const ADD_MATERIAL = "add-material";
export const UPDATE_MATERIAL = "update-material";
export const DELETE_MATERIAL = "delete-material";
export const RESTORE_MATERIAL = "restore-material";

// Organization Permissions
export const VIEW_ORGANIZATION = "view-organization";
export const ADD_ORGANIZATION = "add-organization";
export const UPDATE_ORGANIZATION = "update-organization";
export const DELETE_ORGANIZATION = "delete-organization";
export const RESTORE_ORGANIZATION = "restore-organization";

// Payment Terms Permissions
export const VIEW_PAYMENT_TERMS = "view-payment-terms";
export const ADD_PAYMENT_TERMS = "add-payment-terms";
export const UPDATE_PAYMENT_TERMS = "update-payment-terms";
export const DELETE_PAYMENT_TERMS = "delete-payment-terms";
export const RESTORE_PAYMENT_TERMS = "restore-payment-terms";

// Production Process Type Permissions
export const VIEW_PRODUCTION_PROCESS_TYPE = "view-production-process-type";
export const ADD_PRODUCTION_PROCESS_TYPE = "add-production-process-type";
export const UPDATE_PRODUCTION_PROCESS_TYPE = "update-production-process-type";
export const DELETE_PRODUCTION_PROCESS_TYPE = "delete-production-process-type";
export const RESTORE_PRODUCTION_PROCESS_TYPE =
  "restore-production-process-type";

// Production Process Permissions
export const VIEW_PRODUCTION_PROCESS = "view-production-process";
export const ADD_PRODUCTION_PROCESS = "add-production-process";
export const UPDATE_PRODUCTION_PROCESS = "update-production-process";
export const DELETE_PRODUCTION_PROCESS = "delete-production-process";

// Product Permissions
export const VIEW_PRODUCT = "view-product";
export const ADD_PRODUCT = "add-product";
export const UPDATE_PRODUCT = "update-product";
export const DELETE_PRODUCT = "delete-product";
export const RESTORE_PRODUCT = "restore-product"; // Assuming restore permission

// Store Permissions
export const VIEW_STORE = "view-store";
export const ADD_STORE = "add-store";
export const UPDATE_STORE = "update-store";
export const DELETE_STORE = "delete-store";
export const RESTORE_STORE = "restore-store"; // Assuming restore permission

// Sub Category Permissions (assuming same permissions as existing code)
export const VIEW_SUB_CATEGORY = "view-sub-category";
export const ADD_SUB_CATEGORY = "add-sub-category";
export const UPDATE_SUB_CATEGORY = "update-sub-category";
export const DELETE_SUB_CATEGORY = "delete-sub-category";

// Tax Permissions
export const VIEW_TAX = "view-tax";
export const ADD_TAX = "add-tax";
export const UPDATE_TAX = "update-tax";
export const DELETE_TAX = "delete-tax";
export const RESTORE_TAX = "restore-tax"; // Assuming restore permission

// Tax Type Permissions
export const VIEW_TAX_TYPE = "view-tax-type";
export const ADD_TAX_TYPE = "add-tax-type";
export const UPDATE_TAX_TYPE = "update-tax-type";
export const DELETE_TAX_TYPE = "delete-tax-type";
export const RESTORE_TAX_TYPE = "restore-tax-type"; // Assuming restore permission

// Terms And Condition Permissions
export const VIEW_TERMS_AND_CONDITION = "view-terms-and-condition";
export const ADD_TERMS_AND_CONDITION = "add-terms-and-condition";
export const UPDATE_TERMS_AND_CONDITION = "update-terms-and-condition";
export const DELETE_TERMS_AND_CONDITION = "delete-terms-and-condition";
export const RESTORE_TERMS_AND_CONDITION = "restore-terms-and-condition"; // Assuming restore permission

// Unit Of Measurement Permissions
export const VIEW_UNIT_OF_MEASUREMENT = "view-unit-of-measurement";
export const ADD_UNIT_OF_MEASUREMENT = "add-unit-of-measurement";
export const UPDATE_UNIT_OF_MEASUREMENT = "update-unit-of-measurement";
export const DELETE_UNIT_OF_MEASUREMENT = "delete-unit-of-measurement";
export const RESTORE_UNIT_OF_MEASUREMENT = "restore-unit-of-measurement"; // Assuming restore permission

// User Permissions
export const VIEW_USER = "view-user";
export const ADD_USER = "add-user";
export const UPDATE_USER = "update-user";
export const DELETE_USER = "delete-user";
export const RESTORE_USER = "restore-user";

// User Type Permissions
export const VIEW_USER_TYPE = "view-user-type";
export const ADD_USER_TYPE = "add-user-type";
export const UPDATE_USER_TYPE = "update-user-type";
export const DELETE_USER_TYPE = "delete-user-type";
export const RESTORE_USER_TYPE = "restore-user-type";

// Audit Detail Permissions
export const VIEW_AUDIT_DETAIL = "view-audit-detail";
export const ADD_AUDIT_DETAIL = "add-audit-detail";
export const UPDATE_AUDIT_DETAIL = "update-audit-detail";
export const DELETE_AUDIT_DETAIL = "delete-audit-detail";
export const RESTORE_AUDIT_DETAIL = "restore-audit-detail";

// Document Number Format Permissions
export const VIEW_DOCUMENT_NUMBER_FORMAT = "view-document-number-format";
export const ADD_DOCUMENT_NUMBER_FORMAT = "add-document-number-format";
export const UPDATE_DOCUMENT_NUMBER_FORMAT = "update-document-number-format";
export const DELETE_DOCUMENT_NUMBER_FORMAT = "delete-document-number-format";
export const RESTORE_DOCUMENT_NUMBER_FORMAT = "restore-document-number-format";

// Attachment Permissions
export const VIEW_ATTACHMENT = "view-attachment";
export const ADD_ATTACHMENT = "add-attachment";
export const UPDATE_ATTACHMENT = "update-attachment";
export const DELETE_ATTACHMENT = "delete-attachment";
export const RESTORE_ATTACHMENT = "restore-attachment";

// Batch Permissions
export const VIEW_BATCH = "view-batch";
export const ADD_BATCH = "add-batch";
export const UPDATE_BATCH = "update-batch";
export const DELETE_BATCH = "delete-batch";
export const RESTORE_BATCH = "restore-batch";

// Bill Of Materials Permissions
export const VIEW_BILL_OF_MATERIALS = "view-bill-of-materials";
export const ADD_BILL_OF_MATERIALS = "add-bill-of-materials";
export const UPDATE_BILL_OF_MATERIALS = "update-bill-of-materials";
export const DELETE_BILL_OF_MATERIALS = "delete-bill-of-materials";
export const RESTORE_BILL_OF_MATERIALS = "restore-bill-of-materials";

// Bill Of Materials Finished Goods Permissions
export const VIEW_BILL_OF_MATERIALS_FINISHED_GOODS =
  "view-bill-of-materials-finished-goods";
export const ADD_BILL_OF_MATERIALS_FINISHED_GOODS =
  "add-bill-of-materials-finished-goods";
export const UPDATE_BILL_OF_MATERIALS_FINISHED_GOODS =
  "update-bill-of-materials-finished-goods";
export const DELETE_BILL_OF_MATERIALS_FINISHED_GOODS =
  "delete-bill-of-materials-finished-goods";
export const RESTORE_BILL_OF_MATERIALS_FINISHED_GOODS =
  "restore-bill-of-materials-finished-goods";

// Bill Of Materials Raw Materials Permissions
export const VIEW_BILL_OF_MATERIALS_RAW_MATERIALS =
  "view-bill-of-materials-raw-materials";
export const ADD_BILL_OF_MATERIALS_RAW_MATERIALS =
  "add-bill-of-materials-raw-materials";
export const UPDATE_BILL_OF_MATERIALS_RAW_MATERIALS =
  "update-bill-of-materials-raw-materials";
export const DELETE_BILL_OF_MATERIALS_RAW_MATERIALS =
  "delete-bill-of-materials-raw-materials";
export const RESTORE_BILL_OF_MATERIALS_RAW_MATERIALS =
  "restore-bill-of-materials-raw-materials";

// Bill Of Materials Scrap Permissions
export const VIEW_BILL_OF_MATERIALS_SCRAP = "view-bill-of-materials-scrap";
export const ADD_BILL_OF_MATERIALS_SCRAP = "add-bill-of-materials-scrap";
export const UPDATE_BILL_OF_MATERIALS_SCRAP = "update-bill-of-materials-scrap";
export const DELETE_BILL_OF_MATERIALS_SCRAP = "delete-bill-of-materials-scrap";
export const RESTORE_BILL_OF_MATERIALS_SCRAP =
  "restore-bill-of-materials-scrap";

// Bill Of Materials Routines Permissions
export const VIEW_BILL_OF_MATERIALS_ROUTINES =
  "view-bill-of-materials-routines";
export const ADD_BILL_OF_MATERIALS_ROUTINES = "add-bill-of-materials-routines";
export const UPDATE_BILL_OF_MATERIALS_ROUTINES =
  "update-bill-of-materials-routines";
export const DELETE_BILL_OF_MATERIALS_ROUTINES =
  "delete-bill-of-materials-routines";
export const RESTORE_BILL_OF_MATERIALS_ROUTINES =
  "restore-bill-of-materials-routines";

// Routines Permissions
export const VIEW_ROUTINES = "view-routines";
export const ADD_ROUTINES = "add-routines";
export const UPDATE_ROUTINES = "update-routines";
export const DELETE_ROUTINES = "delete-routines";
export const RESTORE_ROUTINES = "restore-routines";

// Document Templates Permissions
export const VIEW_DOCUMENT_TEMPLATES = "view-document-templates";
export const ADD_DOCUMENT_TEMPLATES = "add-document-templates";
export const UPDATE_DOCUMENT_TEMPLATES = "update-document-templates";
export const DELETE_DOCUMENT_TEMPLATES = "delete-document-templates";
export const RESTORE_DOCUMENT_TEMPLATES = "restore-document-templates";

// Agreement Permissions
export const VIEW_AGREEMENT = "view-agreement";
export const ADD_AGREEMENT = "add-agreement";
export const UPDATE_AGREEMENT = "update-agreement";
export const DELETE_AGREEMENT = "delete-agreement";
export const RESTORE_AGREEMENT = "restore-agreement";

// Organization Address Permissions
export const VIEW_ORGANIZATION_ADDRESS = "view-organization-address";
export const ADD_ORGANIZATION_ADDRESS = "add-organization-address";
export const UPDATE_ORGANIZATION_ADDRESS = "update-organization-address";
export const DELETE_ORGANIZATION_ADDRESS = "delete-organization-address";
export const RESTORE_ORGANIZATION_ADDRESS = "restore-organization-address";

// Tax Invoice Permissions
export const VIEW_TAX_INVOICE = "view-tax-invoice";
export const ADD_TAX_INVOICE = "add-tax-invoice";
export const UPDATE_TAX_INVOICE = "update-tax-invoice";
export const DELETE_TAX_INVOICE = "delete-tax-invoice";
export const RESTORE_TAX_INVOICE = "restore-tax-invoice";

// Proforma Invoice Permissions
export const VIEW_PROFORMA_INVOICE = "view-proforma-invoice";
export const ADD_PROFORMA_INVOICE = "add-proforma-invoice";
export const UPDATE_PROFORMA_INVOICE = "update-proforma-invoice";
export const DELETE_PROFORMA_INVOICE = "delete-proforma-invoice";
export const RESTORE_PROFORMA_INVOICE = "restore-proforma-invoice";

// Inward Document Permissions
export const VIEW_INWARD_DOCUMENT = "view-inward-document";
export const ADD_INWARD_DOCUMENT = "add-inward-document";
export const UPDATE_INWARD_DOCUMENT = "update-inward-document";
export const DELETE_INWARD_DOCUMENT = "delete-inward-document";
export const RESTORE_INWARD_DOCUMENT = "restore-inward-document";

// Good Received Note Permissions
export const VIEW_GOOD_RECEIVED_NOTE = "view-good-received-note";
export const ADD_GOOD_RECEIVED_NOTE = "add-good-received-note";
export const UPDATE_GOOD_RECEIVED_NOTE = "update-good-received-note";
export const DELETE_GOOD_RECEIVED_NOTE = "delete-good-received-note";
export const RESTORE_GOOD_RECEIVED_NOTE = "restore-good-received-note";

// Purchase Order Permissions
export const VIEW_PURCHASE_ORDER = "view-purchase-order";
export const ADD_PURCHASE_ORDER = "add-purchase-order";
export const UPDATE_PURCHASE_ORDER = "update-purchase-order";
export const DELETE_PURCHASE_ORDER = "delete-purchase-order";
export const RESTORE_PURCHASE_ORDER = "restore-purchase-order";

// Stock Inventory Permissions
export const VIEW_STOCK_INVENTORY = "view-stock-inventory";
export const ADD_STOCK_INVENTORY = "add-stock-inventory";
export const UPDATE_STOCK_INVENTORY = "update-stock-inventory";
export const DELETE_STOCK_INVENTORY = "delete-stock-inventory";
export const RESTORE_STOCK_INVENTORY = "restore-stock-inventory";

// Material Stock Inventory Permissions
export const VIEW_MATERIAL_STOCK_INVENTORY = "view-material-stock-inventory";
export const ADD_MATERIAL_STOCK_INVENTORY = "add-material-stock-inventory";
export const UPDATE_MATERIAL_STOCK_INVENTORY =
  "update-material-stock-inventory";
export const DELETE_MATERIAL_STOCK_INVENTORY =
  "delete-material-stock-inventory";
export const RESTORE_MATERIAL_STOCK_INVENTORY =
  "restore-material-stock-inventory";

// User Group Permissions
export const VIEW_USER_GROUP = "view-user-group";
export const ADD_USER_GROUP = "add-user-group";
export const UPDATE_USER_GROUP = "update-user-group";
export const DELETE_USER_GROUP = "delete-user-group";
export const RESTORE_USER_GROUP = "restore-user-group";

// Project Permissions
export const VIEW_PROJECT = "view-project";
export const ADD_PROJECT = "add-project";
export const UPDATE_PROJECT = "update-project";
export const DELETE_PROJECT = "delete-project";
export const RESTORE_PROJECT = "restore-project";

// Project Product Permissions
export const VIEW_PROJECT_PRODUCT = "view-project-product";
export const ADD_PROJECT_PRODUCT = "add-project-product";
export const UPDATE_PROJECT_PRODUCT = "update-project-product";
export const DELETE_PROJECT_PRODUCT = "delete-project-product";
export const RESTORE_PROJECT_PRODUCT = "restore-project-product";

// Project Manufactures Quotation Permissions
export const VIEW_PROJECT_MANUFACTURES_QUOTATION =
  "view-project-manufactures-quotation";
export const ADD_PROJECT_MANUFACTURES_QUOTATION =
  "add-project-manufactures-quotation";
export const UPDATE_PROJECT_MANUFACTURES_QUOTATION =
  "update-project-manufactures-quotation";
export const DELETE_PROJECT_MANUFACTURES_QUOTATION =
  "delete-project-manufactures-quotation";
export const RESTORE_PROJECT_MANUFACTURES_QUOTATION =
  "restore-project-manufactures-quotation";

// Project Manufactures Quotation Data Permissions
export const VIEW_PROJECT_MANUFACTURES_QUOTATION_DATA =
  "view-project-manufactures-quotation-data";
export const ADD_PROJECT_MANUFACTURES_QUOTATION_DATA =
  "add-project-manufactures-quotation-data";
export const UPDATE_PROJECT_MANUFACTURES_QUOTATION_DATA =
  "update-project-manufactures-quotation-data";
export const DELETE_PROJECT_MANUFACTURES_QUOTATION_DATA =
  "delete-project-manufactures-quotation-data";
export const RESTORE_PROJECT_MANUFACTURES_QUOTATION_DATA =
  "restore-project-manufactures-quotation-data";

// Sales Quotation Permissions
export const VIEW_SALES_QUOTATION = "view-sales-quotation";
export const ADD_SALES_QUOTATION = "add-sales-quotation";
export const UPDATE_SALES_QUOTATION = "update-sales-quotation";
export const DELETE_SALES_QUOTATION = "delete-sales-quotation";
export const RESTORE_SALES_QUOTATION = "restore-sales-quotation";

// Sales Return Permissions
export const VIEW_SALES_RETURN = "view-sales-return";
export const ADD_SALES_RETURN = "add-sales-return";
export const UPDATE_SALES_RETURN = "update-sales-return";
export const DELETE_SALES_RETURN = "delete-sales-return";
export const RESTORE_SALES_RETURN = "restore-sales-return";
