import { Model } from "@tailflow/laravel-orion/lib/model";

export class Product extends Model<{
  tax_id: any;
  category_id: any;
  unit_of_measurement_id: any;
  product_id: string;
  product_name: string;
  type: any;
  current_stock: number;
  // hsn_code: string;
  default_price: number;
  regular_buying_price: number;
  wholesale_buying_price: number;
  regular_selling_price: number;
  mrp: number;
  dealer_price: number;
  distributor_price: number;
  created_at: string;
  updated_at: string;
  tax?: any;
  category?: any;
  unit_of_measurement?: any;
}> {
  public $resource(): string {
    return "products";
  }
}
