import React, { ReactNode } from "react";
import EditButton from "./Button/EditButton";

interface CardProps {
  title: string;
  onEdit: () => void;
  children?: ReactNode;
  isHideEditBtn?: boolean;
  buttonTitle?: string;
  customStyle?: any;
}

const CustomCard: React.FC<CardProps> = ({
  title,
  children,
  onEdit,
  isHideEditBtn,
  buttonTitle,
  customStyle,
}) => (
  <div
    style={{
      width: "50%",
      border: "1px solid rgba(0, 0, 0, 0.175)",
      borderRadius: "0.375rem",
    }}
    className="mx-2"
  >
    <div
      style={{
        minHeight: customStyle?.cardHight ? customStyle.cardHight : "240px",
        border: "1px solid #E7E7E7",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          backgroundColor: "#F8F8F8",
          height: customStyle?.titleDivHeight
            ? customStyle.titleDivHeight
            : "51px",
          paddingRight: "10px",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          borderTopLeftRadius: customStyle?.borderTopLeftRadius
            ? customStyle.borderTopLeftRadius
            : "",
          borderTopRightRadius: customStyle?.borderTopRightRadius
            ? customStyle.borderTopRightRadius
            : "",
        }}
      >
        <div
          style={{
            marginLeft: "10px",
            fontSize: "15px",
          }}
        >
          {title}
        </div>
        {!isHideEditBtn && <EditButton onClick={onEdit} title={buttonTitle} />}
      </div>
      <div>{children}</div>
    </div>
  </div>
);

export default CustomCard;
