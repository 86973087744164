import React, { useEffect, useState } from "react";
import Select, { ActionMeta } from "react-select";
import { useNavigate } from "react-router-dom";

interface Action {
  name: string;
  url?: string;
  modal?: any;
  keywords: string[];
}

function SearchField({
  handleCloseSearchModal,
  setModalStatus,
}: {
  handleCloseSearchModal: () => void;
  setModalStatus: any;
}) {
  const actions: Action[] = [
    {
      name: "Add Product",
      url: "/products/add",
      keywords: ["add products", "create products"],
    },
    {
      name: "Add Category",
      url: "/categories/add",
      keywords: ["add categories", "create categories"],
    },
    {
      name: "Add Role",
      url: "/roles/add",
      keywords: ["add store", "create store"],
    },
    {
      name: "Add Attribute",
      url: "/attributes/add",
      keywords: ["add attributes", "create attributes"],
    },
    {
      name: "Add Tax Type",
      url: "/tax-types/add",
      keywords: ["add taxTypes", "create taxTypes"],
    },
    {
      name: "Add User Type",
      url: "/user-types/add",
      keywords: ["add userTypes", "create userTypes"],
    },
    {
      name: "Add Payment Terms",
      url: "/payment-terms/add",
      keywords: ["add paymentTerms", "create paymentTerms"],
    },
    {
      name: "Add Bank Details",
      url: "/bank-details/add",
      keywords: ["add bankDetails", "create bankDetails"],
    },
    {
      name: "Add Designation",
      url: "/designations/add",
      keywords: ["add designations", "create designations"],
    },
    {
      name: "Add Store",
      url: "/stores/add",
      keywords: ["add stores", "create stores"],
    },
    {
      name: "Add Logistic Detail",
      url: "/logistic-details/add",
      keywords: ["add logisticDetails", "create logisticDetails"],
    },
    {
      name: "Add Terms & Condition",
      url: "/terms-and-conditions/add",
      keywords: ["add termsAndConditions", "create termsAndConditions"],
    },
    {
      name: "Add User",
      url: "/users/add",
      keywords: ["add users", "create users"],
    },
    {
      name: "Add Unit Of Measurement",
      url: "/unit-of-measurements/add",
      keywords: ["add unitOfMeasurements", "create unitOfMeasurements"],
    },
    {
      name: "Add Tax",
      url: "/taxs/add",
      keywords: ["add taxs", "create taxs"],
    },
    {
      name: "Add Buyer & Supplier",
      modal: { buyersAndSupplierStatus: true },
      keywords: ["add taxs", "create taxs"],
    },
  ];
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: Action;
  } | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [options, setOptions] = useState<Action[]>([]);

  useEffect(() => {
    const searchActions = (input: string): Action[] => {
      return actions.filter(
        (action) =>
          action.keywords.some((keyword) =>
            keyword.toLowerCase().includes(input),
          ) || action.name.toLowerCase().includes(input),
      );
    };
    setOptions(searchActions(searchTerm));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleInputChange = (inputValue: string) => {
    setSearchTerm(inputValue);
  };

  const handleOptionChange = (
    newValue: { label: string; value: Action } | null,
    actionMeta: ActionMeta<{ label: string; value: Action }>,
  ) => {
    setSelectedOption(newValue);
    if (newValue?.value?.url) {
      navigate(newValue.value.url);
      handleCloseSearchModal();
    } else if (newValue?.value?.modal) {
      setModalStatus((prevState: any) => ({
        ...prevState,
        ...newValue?.value?.modal,
      }));
      handleCloseSearchModal();
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div style={{ width: "100%", marginRight: "10px" }}>
        <Select
          options={options.map((option) => ({
            label: option.name as string,
            value: option,
          }))}
          isSearchable
          placeholder="Search...."
          onChange={handleOptionChange}
          onInputChange={handleInputChange}
          value={selectedOption}
        />
      </div>
    </div>
  );
}

export default SearchField;
