import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { Eye } from "react-bootstrap-icons";

interface CustomViewButtonProps {
  onClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number,
    option?: any,
    isDisable?: boolean,
  ) => void;
  id: number;
  isDisabled?: boolean;
  customFill?: any;
}

const CustomViewButton: React.FC<CustomViewButtonProps> = ({
  onClick,
  id,
  isDisabled,
  customFill,
}) => {
  return (
    <ButtonGroup>
      <button
        className="btn btn-xs border-0"
        disabled={isDisabled}
        onClick={(e) => onClick(e, id)}
        title="View"
      >
        <Eye className="ml-4" style={{ fill: customFill || "blue" }} />
      </button>
    </ButtonGroup>
  );
};

export default CustomViewButton;
