import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Col, Modal, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { Country } from "../../../Model/Setting/Country";
import { TaxType } from "../../../Model/Setting/TaxType";
import CustomButton from "../../../Components/Button/CustomButton";
import AddUpdateModel from "../../BuyersAndSuppliers/Components/Modal";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import {
  PURCHASE_ADD,
  PURCHASE_LIST,
} from "../../../Routes/SalesAndPurchase/Purchase";
import CustomAsyncSelect from "../../../Components/AsyncSelect";
import { BuyersAndSuppliers } from "../../../Model/BuyersAndSuppliers/BuyersAndSuppliers";
import { setToast } from "../../../Util/Toast";
import { fetchData } from "../../../Util/OrionList";
import CustomDataTable from "../../../Components/CustomDataTable";
import { TableColumn } from "react-data-table-component";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { InwardDocument } from "../../../Model/SalesAndPurchase/InwardDocument/InwardDocument";
import {
  INWARD_DOCUMENT_ADD,
  INWARD_DOCUMENT_EDIT,
  INWARD_DOCUMENT_FOR_PURCHASE_ORDER_ADD,
  INWARD_DOCUMENT_FOR_PURCHASE_ORDER_VIEW,
  INWARD_DOCUMENT_TITLE,
  INWARD_DOCUMENT_VIEW,
} from "../../../Routes/SalesAndPurchase/InwardDocument";
import CustomViewButton from "../../../Components/Button/CustomViewButton";
import CustomDownloadButton from "../../../Components/Button/CustomDownloadButton";
import { getCookie } from "typescript-cookie";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import { PurchaseOrder } from "../../../Model/SalesAndPurchase/PurchaseOrder/PurchaseOrder";
import { AmountFormat } from "../../../Util/AmountFormat";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";
interface PurchaseOrderItem {
  id: number;
  title: string;
  description: string;
  days: number;
  delivery_date: string;
  status: string;
  document_number: string;
  created_at: string;
  updated_at: string;
}

function List({ permissions }: any) {
  const location = useLocation();
  const { purchase_order_id, date }: any = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<PurchaseOrderItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState<any>([]);
  const [paymentTermsData, setPaymentTermsData] = useState<any>([]);
  const [currency, setCurrency] = useState<any>([]);

  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>(
    purchase_order_id
      ? { purchase_order_id: { value: purchase_order_id } }
      : {},
  );
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showAddUpdateModel, setShowAddUpdateModel] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status

  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (purchase_order_id) {
      navigate(
        INWARD_DOCUMENT_FOR_PURCHASE_ORDER_ADD.replace(
          ":purchase_order_id",
          purchase_order_id,
        ),
      );
    } else {
      navigate(INWARD_DOCUMENT_ADD);
    }
  };

  const handleCompanyDetailsClose = () => setShowCompanyModal(false);
  const handleAddCompanyDetailsClose = () => setShowAddUpdateModel(false);

  const companyFormFields = [
    {
      id: "inputCompanyType",
      label: "Company Type*",
      type: "select",
      col: 4,
      name: "company_type",
      addNewBtnStatus: false,
      options: [
        { label: "Buyer", value: "buyer" },
        { label: "Supplier", value: "supplier" },
        { label: "Both", value: "both" },
      ],
      placeholder: "Select an Company Type",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Type is Required",
        },
      ],
      description:
        "Select the type of business entity: Buyer, Supplier, or Both.",
    },
    {
      id: "inputCompanyName",
      label: "Company Name*",
      type: "text",
      col: 4,
      name: "company_name",
      placeholder: "Enter Your Company Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Name is Required",
        },
      ],
      description:
        "Enter the name of the company, ensuring accuracy and consistency.",
    },
    {
      id: "inputEmail",
      label: "Email*",
      type: "text",
      col: 4,
      name: "email",
      placeholder: "Enter Your Email",
      validate: [
        {
          rule: (value: any) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
          message: "Enter a valid email address",
        },
        {
          rule: (value: any) => !!value,
          message: "Email is Required",
        },
      ],
      description:
        "Provide the email address associated with the company for communication.",
    },
    {
      id: "inputTaxNumber",
      label: "Tax Number*",
      type: "text",
      col: 4,
      name: "tax_number",
      placeholder: "Enter Your Tax Number",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Tax Number is Required",
        },
      ],
      description:
        "Include the Value Added Tax (VAT) number for taxation purposes.",
    },
    {
      id: "inputaddress_line1",
      label: "Address Line 1*",
      type: "text",
      col: 4,
      name: "address_line1",
      placeholder: "Enter Your Address Line 1",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Address Line 1 is Required",
        },
      ],
      description: "Specify the physical address of the company.",
    },
    {
      id: "inputaddress_line2",
      label: "Address Line 2",
      type: "text",
      col: 4,
      name: "address_line2",
      placeholder: "Enter Your Address Line 2",
    },
    {
      id: "inputPinCode",
      label: "Zip Code*",
      type: "text",
      col: 4,
      name: "zip_code",
      placeholder: "Enter Your Zip Code",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Zip Code is Required",
        },
      ],
      description:
        "Enter the postal code or Zip code associated with the company's location.",
    },
    {
      id: "inputCity",
      label: "City*",
      type: "text",
      col: 4,
      name: "city",
      placeholder: "Enter Your City",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "City is Required",
        },
      ],
      description: "Provide the city in which the company is situated.",
    },
    {
      id: "inputState",
      label: "State*",
      type: "text",
      col: 4,
      name: "state",
      placeholder: "Enter Your State",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "State is Required",
        },
      ],
      description: "Specify the state or region of the company's location.",
    },
    {
      id: "inputcompany_tcs_setting",
      label: "Company Tax Collection At Source Setting*",
      type: "select",
      col: 4,
      name: "company_tcs_setting",
      placeholder: "Select an Company Tax Collection At Source Setting",
      addNewBtnStatus: false,
      options: [
        { label: "None", value: "none" },
        { label: "Purchase", value: "purchase" },
        { label: "Sales ", value: "sales" },
        { label: "Both", value: "both" },
      ],
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Tax Collection At Source Setting is Required",
        },
      ],
      description:
        "Select the Tax Collection at Source (TCS) setting for the company.",
    },
    {
      id: "inputcountry_id",
      label: "Country*",
      type: "select",
      col: 4,
      name: "country_id",
      placeholder: "Select an Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is Required",
        },
      ],
      description: "Choose the country in which the company is located.",
    },
    {
      id: "inputtax_types_id",
      label: "Tax Type*",
      type: "select",
      col: 4,
      name: "tax_type_id",
      placeholder: "Select an TaxType",
      searchField: "title",
      model: TaxType,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Tax Type is Required",
        },
      ],
      description:
        "Select the applicable tax type for the company, such as GST, VAT, etc.",
    },
  ];

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  const handleCompanyCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    //  Creating a new object with the updated values
    const updatedData = {
      ...formData,
      tax_type_id: parseInt(formData.tax_type_id.value),
      country_id: parseInt(formData.country_id.value),
      company_type: formData.company_type.value,
      company_tcs_setting: formData.company_tcs_setting.value,
    };
    try {
      const response = await BuyersAndSuppliers.$query().store(updatedData);
      setToast("success", `Company Successfully Added`);
      const Id: number = response.$attributes.id as number;
      navigate(PURCHASE_ADD.replace(":id", Id.toString()));
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const fetchInwardDocument = useCallback(async () => {
    const params = date ? new URLSearchParams(date) : null;
    const fromDate = params?.get("fromDate");

    const filters = {
      ...(fromDate
        ? { created_at: { filter_operation: ">=", value: fromDate } }
        : {}),
      ...(inputValues ? inputValues : {}),
    };

    if (purchase_order_id) {
      setLoading(true);
      try {
        fetchData(
          page,
          limit,
          filters,
          setLoading,
          setData,
          setTotalRows,
          navigate,
          new InwardDocument(),
          sortField,
          sortOrder,
        );
        setLimit(limit);
      } catch (error: any) {
        setToast("error", error.response.data.message);
      }
    } else {
      try {
        await fetchData(
          page,
          limit,
          filters,
          setLoading,
          setData,
          setTotalRows,
          navigate,
          new InwardDocument(),
          sortField,
          sortOrder,
        );
      } catch (error: any) {
        setToast("error", error.response.data.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, page, limit, inputValues, sortField, sortOrder, navigate]);

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchInwardDocument();
  }, [purchase_order_id, limit, page]); // eslint-disable-line

  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    if (purchase_order_id) {
      navigate(
        INWARD_DOCUMENT_FOR_PURCHASE_ORDER_VIEW.replace(
          ":purchase_order_id",
          purchase_order_id.toString(),
        ).replace(":inward_id", id.toString()),
      );
    } else {
      navigate(INWARD_DOCUMENT_VIEW.replace(":id", id.toString()));
    }
  };
  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(INWARD_DOCUMENT_EDIT.replace(":id", id.toString()));
  };
  const authToken = getCookie("authToken");
  const token = authToken ? authToken.replace(/["']/g, "") : "";

  const handlePDFDownloadButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL + `api/inward-document/pdf/`;
      const response = await fetch(apiUrl + id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        const anchor = document.createElement("a");
        anchor.href = responseData.data;
        anchor.download = "inward document";
        anchor.rel = "noreferrer";
        anchor.target = "_blank";
        anchor.click();
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } catch (error) {
      setToast("error", "An error occurred while processing your request");
    }
  };
  const columns: TableColumn<PurchaseOrderItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Document Number",
      cell: (row) => row.document_number,
    },
    {
      name: "Status",
      cell: (row) => (
        <Badge
          pill
          bg={row.status === "draft" ? "secondary" : "success"}
          style={{ padding: "7px 10px", fontSize: "smaller" }}
        >
          {CapitalizeAndRemoveUnderscore(row.status)}
        </Badge>
      ),
    },
    {
      name: "Delivery Date",
      cell: (row: any) => row.delivery_date,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Download",
      button: true,
      cell: (row: any) => (
        <div>
          <CustomDownloadButton
            id={row.id}
            onClick={handlePDFDownloadButtonClick}
          />
        </div>
      ),
    },
    {
      name: "Actions",
      wrap: true,
      button: true,
      omit: !permissions.update && !permissions.delete,
      cell: (row: any) => (
        <div className="d-flex">
          <CustomViewButton
            id={row.id}
            onClick={(e) => {
              return handleRowViewButtonClick(e, row.id);
            }}
          />
          {permissions.update && (
            <CustomEditButton
              isDisabled={row.status === "sent" && true}
              id={row.id}
              onClick={handleRowEditButtonClick}
            />
          )}
        </div>
      ),
    },
  ];
  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "document_number",
      value: "document_number",
      label: "Document Number",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "delivery_date",
      value: "delivery_date",
      label: "Delivery Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
    {
      id: "status",
      value: "status",
      label: "Status",
      fieldType: "select",
      fieldOption: {
        All: "",
        Draft: "draft",
        Sent: "sent",
      },
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
  ];
  function GoBack(): void {
    navigate(PURCHASE_LIST);
  }
  useEffect(() => {
    if (purchase_order_id) {
      getPurchaseOrderDetails(purchase_order_id);
    }
  }, [purchase_order_id]);

  const getPurchaseOrderDetails = async (itemId: string) => {
    try {
      let item: any = await PurchaseOrder.$query()
        .with([
          "items",
          "termsAndCondition",
          "supplierBillingAddress",
          "buyerBillingAddress",
          "buyerDeliveryAddress",
          "items.product",
          "paymentTerms",
          "items.tax",
          "charges",
          "charges.tax",
          "bankDetail",
          "logisticDetail",
          "items.unit",
          "supplierBillingAddress.counterPartyCompany",
          "currency",
          "organization.currency",
          "buyerDeliveryAddress.country",
          "buyerBillingAddress.country",
          "supplierBillingAddress.country",
        ])
        .find(itemId);

      setCurrency(
        item.$attributes.currency
          ? item.$attributes.currency
          : item.$attributes.organization.currency,
      );

      setPaymentTermsData(
        item.$attributes.status === "delivered" &&
          item.$attributes.payment_terms_data
          ? JSON.parse(item.$attributes.payment_terms_data)
          : item.$attributes.payment_terms,
      );

      await setPurchaseOrderDetails(item.$attributes);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };

  return (
    <>
      <div className="mb-4">
        {purchase_order_id && (
          <div className="card mb-4">
            <h4 className="card-header">Purchase Order Details</h4>
            <div className="d-flex justify-content-around align-items-center pt-3">
              <p>
                <span className="fw-semibold">PO Number : </span>
                {purchaseOrderDetails.document_number
                  ? purchaseOrderDetails.document_number
                  : "Not Found"}
              </p>
              <p>
                <span className="fw-semibold">PO Title : </span>
                {purchaseOrderDetails.title
                  ? purchaseOrderDetails.title
                  : "Not Found"}
              </p>
              <p>
                <span className="fw-semibold"> PO Date :</span>
                {purchaseOrderDetails.document_date
                  ? purchaseOrderDetails.document_date
                  : "Not Found"}
              </p>
              <p>
                <span className="fw-semibold"> PO Amount : </span>
                {purchaseOrderDetails?.total_amount
                  ? currency?.symbol +
                    AmountFormat(purchaseOrderDetails?.total_amount)
                  : "Null"}
              </p>
              <p className="d-flex pt-1">
                <span className="fw-semibold pt-1">Status : </span>
                <span className="ms-1 fs-6">
                  <h5>
                    <Badge pill>
                      {purchaseOrderDetails.status &&
                        CapitalizeAndRemoveUnderscore(
                          purchaseOrderDetails.status,
                        )}
                    </Badge>
                  </h5>
                </span>
              </p>
              <p>
                <span className="fw-semibold">Payment Terms : </span>
                {paymentTermsData?.name ? paymentTermsData?.name : "Not Found"}
              </p>
              <p>
                <span className="fw-semibold">Delivery Date : </span>
                {purchaseOrderDetails.delivery_date
                  ? purchaseOrderDetails.delivery_date
                  : "Not Found"}
              </p>
            </div>
          </div>
        )}
        <h3>{INWARD_DOCUMENT_TITLE} List</h3>
      </div>
      <Row>
        <CustomFilter
          options={filterOptions}
          inputValues={inputValues}
          inputInitialValues={
            purchase_order_id && {
              purchase_order_id: { value: purchase_order_id },
            }
          }
          setInputValues={setInputValues}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          getData={fetchInwardDocument}
          setPage={setPage}
        />
        <Col className="text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {permissions.add && (
            <CustomButton
              label={`Add`}
              variant="success"
              onClick={handleRowCreateButtonClick}
            />
          )}
          {purchase_order_id && (
            <button
              type="button"
              className="btn btn-primary btn-sm ms-2"
              onClick={GoBack}
            >
              Back
            </button>
          )}
        </Col>
      </Row>
      <div className="mb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"InwardDocument"} // Pass optional fields to CustomDataTable
        />
      </div>
      <Modal
        dialogClassName="custom-modal"
        className="mt-3"
        size="lg"
        show={showCompanyModal}
        onHide={handleCompanyDetailsClose}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title>Select Company</Modal.Title>
          <Button
            className="btn btn-secondary"
            onClick={() => setShowAddUpdateModel(true)}
          >
            Add New Company
          </Button>
        </Modal.Header>

        <Modal.Body>
          <CustomAsyncSelect
            model={BuyersAndSuppliers}
            inputfield={"company_name"}
            fieldName={"company_name"}
            isMultiSelect={false}
            formData={"company_name"}
            label={"Select Company"}
            addNewBtnStatus={false}
            onChange={(selectedOption) => {
              navigate(
                INWARD_DOCUMENT_ADD.replace(
                  ":id",
                  selectedOption.value.toString(),
                ),
              );
            }}
          />
        </Modal.Body>
      </Modal>
      <AddUpdateModel
        title={`Add Company`}
        show={showAddUpdateModel}
        handleClose={() => {
          handleAddCompanyDetailsClose();
          setFormError(null);
        }}
        formFields={companyFormFields}
        widget={
          <AddUpdateForm
            formFields={companyFormFields}
            model={BuyersAndSuppliers}
            title="Buyers&Suppliers"
            customTitle=" "
            customFormSubmit={handleCompanyCustomSubmit}
            isBackBtnDisable={true}
            dynamicFormError={formError}
            customSubmitting={customSubmitting}
          />
        }
      />
    </>
  );
}

export default List;
