import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  const goToHomepage = () => {
    navigate("/");
  };

  return (
    <Container className="border-5" style={{ marginTop: "10rem" }}>
      <div className="row fluid">
        <div className="col-3"></div>
        <div className="col-6 text-center">
          <div className="notfound">
            <div className="notfound-404">
              <h1 style={{ fontSize: "5rem", fontFamily: "sans-serif" }}>
                404
              </h1>
              <h5> Oops!The Page can't be found</h5>
            </div>
            <Button variant="primary" onClick={goToHomepage}>
              Go To Homepage
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}
