import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/RolePermission/List";
import AddUpdate from "../../Pages/Setting/RolePermission/AddUpdate";

export const ROLE_PERMISSION_TITLE = "Role Permission";
export const ROLE_PERMISSION_LIST = "/role-permissions";
export const ROLE_PERMISSION_ADD = "/role-permissions/add";
export const ROLE_PERMISSION_EDIT = "/role-permissions/:id/edit";
export const ROLE_PERMISSION_ARCHIVE = "/role-permissions/archive";

const RolePermission: RouteObject[] = [
  { path: ROLE_PERMISSION_LIST, element: <List /> },
  { path: ROLE_PERMISSION_ADD, element: <AddUpdate /> },
  { path: ROLE_PERMISSION_EDIT, element: <AddUpdate /> },
  { path: ROLE_PERMISSION_ARCHIVE, element: <List /> },
];

export default RolePermission;
