import "bootstrap/dist/css/bootstrap.min.css";
import { PaymentTerms } from "../../../Model/Setting/PaymentTerms";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { Button } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import { useEffect, useState } from "react";
import { DynamicFormFields } from "../../../Util/DynamicFormFields";

interface AddUpdateProps {
  title?: any;
  listRoute?: any;
  isNotUpdate?: any;
  onChangeModalStatus?: (status: boolean) => void;
}

const AddUpdate: React.FC<AddUpdateProps> = ({
  isNotUpdate,
  title,
  listRoute,
  onChangeModalStatus,
}: any) => {
  const formFields = [
    {
      id: "inputName",
      label: "Name*",
      type: "text",
      col: 6,
      name: "name",
      placeholder: "Enter Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Name is required",
        },
      ],
      description:
        "Enter a distinctive and clear name for the payment term. (Example: 'Net 30', 'Immediate Payment', 'Quarterly Installments' etc.)",
    },
    {
      id: "inputDescription",
      label: "Description*",
      type: "text",
      col: 6,
      name: "description",
      placeholder: "Enter Description",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Description is required",
        },
      ],
      description:
        "Provide a brief description outlining the details and conditions associated with the payment term.",
    },
    {
      id: "inputDays",
      label: "Days*",
      type: "number",
      col: 6,
      name: "days",
      placeholder: "Enter Days",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Days is required",
        },
      ],
      description:
        "Specify the number of days associated with the payment term.",
    },
  ];
  const [isShows, setIsShows] = useState<boolean>(false);
  const [dynamicFormFields, setDynamicFormFields] = useState([]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    DynamicFormFields(formFields, "payment_terms").then((updatedFormFields) => {
      setDynamicFormFields(updatedFormFields);
    });
  }, []); // eslint-disable-line

  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={dynamicFormFields}
          model={PaymentTerms}
          title={title}
          listRoute={listRoute}
          entityName={"payment-terms"}
          entityType={"payment_terms"}
          isDynamicFormFields={true}
          isNotUpdate={isNotUpdate}
          onChangeModalStatus={onChangeModalStatus}
        />
      </div>
      <div id="form-bottom">
        <FieldDescription
          title={title}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
