import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setToast } from "../../../Util/Toast";
import { TaxInvoice } from "../../../Model/SalesAndPurchase/TaxInvoice/TaxInvoice";
import { getUser } from "../../../Util/Auth";
import { Badge, Card, Table } from "react-bootstrap";
import {
  TAX_INVOICE_LIST,
  TAX_INVOICE_TITLE,
} from "../../../Routes/SalesAndPurchase/TaxInvoice";
import { getCookie } from "typescript-cookie";
import { AmountFormat } from "../../../Util/AmountFormat";
import { DateWithoutTime } from "../../../Util/DateFormat";
import { Attachment } from "../../../Model/Setting/Attachment";
import {
  Download,
  FiletypeDoc,
  FiletypeDocx,
  FiletypePdf,
  FiletypePptx,
  FiletypeCsv,
  FiletypeXls,
  FiletypeXlsx,
  FiletypePpt,
  ExclamationTriangle,
  FileEarmarkImage,
  Plus,
} from "react-bootstrap-icons";
import { Signature } from "../../../Model/Setting/Signature";
import CustomSkeleton from "../../../Components/CustomSkeleton";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";
import { PAYMENT_WTH_INVOICE } from "../../../Routes/Payment/Payment";

function View() {
  const { id }: any = useParams();
  const user = getUser();
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [organizationAddress, setOrganizationAddress] = useState<any>([]);
  const [invoiceToAddress, setInvoiceToAddress] = useState<any>([]);
  const [BuyersAndSuppliersAddress, setBuyersAndSuppliersAddress] =
    useState<any>([]);
  const [manufactureBillingAddress, setManufactureBillingAddress] =
    useState<any>([]);
  const [currency, setCurrency] = useState<any>([]);
  const [attachmentData, setAttachmentData] = useState<any>([]);
  const [signatureData, setSignatureData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const [buyerName, setBuyerName] = useState<any>("");
  const [supplierName, setSupplierName] = useState<any>("");
  const [manufactureName, setManufactureName] = useState<any>("");
  const [bankDetailData, setBankDetailData] = useState<any>([]);
  const [logisticDetailData, setLogisticDetailData] = useState<any>([]);
  const [paymentTermsData, setPaymentTermsData] = useState<any>([]);
  const [storeData, setStoreData] = useState<any>([]);
  const [purchaseOrderData, setPurchaseOrderData] = useState<any>({});
  const [termsAndConditionData, setTermsAndConditionData] = useState<any>({});

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          await getInvoiceData(id);
          await getAttchmentData(id);
          await getSignatureData(id);
        } catch (error: any) {
          setToast("error", error.response?.data.message);
        }
      } else {
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const groupedItems: any = {};

  // Combine items and charges arrays
  const allItems = data?.items?.concat(data?.charges);

  allItems?.forEach((item: any) => {
    if (item?.tax) {
      const taxName =
        data.status !== "draft" && item?.tax_data
          ? JSON.parse(item?.tax_data)?.tax_name
          : item?.tax?.tax_name; // Assuming a default name for items without a tax
      if (!groupedItems[taxName]) {
        groupedItems[taxName] = [];
      }
      groupedItems[taxName].push(item);
    }
  });
  const getInvoiceData = async (itemId: string) => {
    try {
      let item: any = await TaxInvoice.$query()
        .with([
          "items",
          "store",
          "items.product",
          "paymentTerms",
          "items.tax",
          "items.batch",
          "termsAndCondition",
          "charges",
          "charges.tax",
          "bankDetail",
          "supplierBillingAddress",
          "buyerBillingAddress",
          "buyerDeliveryAddress",
          "manufactureBillingAddress",
          "logisticDetail",
          "items.unit",
          "buyerDeliveryAddress.counterPartyCompany",
          "buyerBillingAddress.counterPartyCompany",
          "supplierBillingAddress.organization",
          "purchaseOrder",
          "purchaseOrder.items",
          "currency",
          "organization.currency",
          "buyerDeliveryAddress.country",
          "buyerBillingAddress.country",
          "supplierBillingAddress.country",
          "manufactureBillingAddress.country",
          "manufactureBillingAddress.counterPartyCompany",
        ])
        .find(itemId);

      setOrganizationAddress(
        item.$attributes.status !== "draft" &&
          item.$attributes.supplier_billing_address_data
          ? JSON.parse(item.$attributes.supplier_billing_address_data)
          : item.$attributes.supplier_billing_address,
      );

      setSupplierName(
        item.$attributes.supplier_billing_address?.organization?.company_name,
      );

      setInvoiceToAddress(
        item.$attributes.status !== "draft" &&
          item.$attributes.buyer_billing_address_data
          ? JSON.parse(item.$attributes.buyer_billing_address_data)
          : item.$attributes.buyer_billing_address,
      );

      setBuyersAndSuppliersAddress(
        item.$attributes.status !== "draft" &&
          item.$attributes.buyer_delivery_address_data
          ? JSON.parse(item.$attributes.buyer_delivery_address_data)
          : item.$attributes.buyer_delivery_address,
      );

      setManufactureBillingAddress(
        item.$attributes.status !== "draft" &&
          item.$attributes.manufacture_billing_address_data
          ? JSON.parse(item.$attributes.manufacture_billing_address_data)
          : item.$attributes.manufacture_billing_address,
      );

      setManufactureName(
        item.$attributes.status !== "draft" && item.$attributes.manufacture_name
          ? item.$attributes.manufacture_name
          : item.$attributes.manufacture_billing_address?.counter_party_company
              ?.company_name,
      );

      setBuyerName(
        item.$attributes.status !== "draft" && item.$attributes.buyer_name
          ? item.$attributes.buyer_name
          : item.$attributes.buyer_billing_address?.counter_party_company
              ?.company_name,
      );

      setBankDetailData(
        item.$attributes.status !== "draft" && item.$attributes.bank_detail_data
          ? JSON.parse(item.$attributes.bank_detail_data)
          : item.$attributes.bank_detail,
      );

      setLogisticDetailData(
        item.$attributes.status !== "draft" &&
          item.$attributes.logistic_detail_data
          ? JSON.parse(item.$attributes.logistic_detail_data)
          : item.$attributes.logistic_detail,
      );

      setPaymentTermsData(
        item.$attributes.status !== "draft" &&
          item.$attributes.payment_terms_data
          ? JSON.parse(item.$attributes.payment_terms_data)
          : item.$attributes.payment_terms,
      );

      setStoreData(
        item.$attributes.status !== "draft" && item.$attributes.store_data
          ? JSON.parse(item.$attributes.store_data)
          : item.$attributes.store,
      );

      setTermsAndConditionData(
        item.$attributes.status !== "draft" &&
          item.$attributes.terms_and_condition_data
          ? JSON.parse(item.$attributes.terms_and_condition_data)
          : item.$attributes.terms_and_condition,
      );

      setPurchaseOrderData(
        item.$attributes.status !== "draft" &&
          item.$attributes.purchase_order_data
          ? JSON.parse(item.$attributes.purchase_order_data)
          : item.$attributes.purchase_order,
      );

      setCurrency(
        item.$attributes.currency
          ? item.$attributes.currency
          : item.$attributes.organization.currency,
      );
      await setData(item.$attributes);

      return item.$attributes;
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setLoading(false);
    }
  };

  const getAttchmentData = async (itemId: string) => {
    try {
      const response: any = await Attachment.$query()
        .getHttpClient()
        .get(`/search?field=tax_invoice_id&operator==&value=${id}`);

      setAttachmentData(response.data.data);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };
  const getSignatureData = async (itemId: string) => {
    try {
      const response: any = await Signature.$query()
        .getHttpClient()
        .get(`/search?field=tax_invoice_id&operator==&value=${id}`);
      setSignatureData(response.data.data);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };

  const GoBack = () => {
    navigate(TAX_INVOICE_LIST);
  };
  const authToken = getCookie("authToken");
  const token = authToken ? authToken.replace(/["']/g, "") : "";
  const handlePDFDownloadButtonClick = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL + `api/tax-invoice/pdf/`;
      const response = await fetch(apiUrl + id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        const anchor = document.createElement("a");
        anchor.href = responseData.data;
        anchor.download = "purchase order";
        anchor.rel = "noreferrer";
        anchor.target = "_blank";
        anchor.click();
      } else {
        setToast("error", "Failed to download pdf");
      }
    } catch (error) {
      setToast("error", "An error occurred while processing your request");
    }
  };

  const getFileTypeImage = (filePath: string) => {
    const extension = filePath.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "png":
      case "jpeg":
      case "jpg":
      case "gif":
        return (
          <span>
            <FileEarmarkImage
              style={{ fill: "grey" }}
              width={"100%"}
              height={"30px"}
            ></FileEarmarkImage>
          </span>
        );
      case "pdf":
        return (
          <FiletypePdf
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypePdf>
        );
      case "ppt":
        return (
          <FiletypePpt
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypePpt>
        );
      case "pptx":
        return (
          <FiletypePptx
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypePptx>
        );
      case "doc":
        return (
          <FiletypeDoc
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeDoc>
        );
      case "docx":
        return (
          <FiletypeDocx
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeDocx>
        );
      case "csv":
        return (
          <FiletypeCsv
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeCsv>
        );
      case "xls":
        return (
          <FiletypeXls
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeXls>
        );
      case "xlsx":
        return (
          <FiletypeXlsx
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeXlsx>
        );
      default:
        return (
          <ExclamationTriangle
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></ExclamationTriangle>
        );
    }
  };

  const handleDownload = (filePath: any, title: any) => {
    if (!filePath) {
      return;
    }
    const downloadUrl = filePath;
    const isImage = /\.(jpeg|jpg|png|gif|pdf)$/i.test(filePath);

    if (isImage) {
      window.open(downloadUrl, "_blank");
    } else {
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", title || "download");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleMakePaymentButtonClick = () => {
    navigate(PAYMENT_WTH_INVOICE.replace(":invoiceid", id.toString()));
  };

  return (
    <div className="container">
      <div className="mb-2 d-flex justify-content-between">
        <div className="d-flex">
          <span>
            <h5>View {TAX_INVOICE_TITLE}</h5>
          </span>
          <span className="ms-2">
            <h5>
              <Badge
                pill
                style={{ padding: "7px 10px" }}
                bg={
                  data.status === "draft"
                    ? "secondary"
                    : data.status === "sent"
                    ? "warning"
                    : data.status === "partially-paid" ||
                      data.status === "partially-refunded"
                    ? "info"
                    : data.status === "paid"
                    ? "success"
                    : data.status === "refunded"
                    ? "danger"
                    : "success"
                }
              >
                {data.status && CapitalizeAndRemoveUnderscore(data.status)}
              </Badge>
            </h5>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!loading && (
            <>
              {data.status !== "draft" && (
                <button
                  type="button"
                  className="btn btn-success btn-sm mb-2 mx-2"
                  onClick={handleMakePaymentButtonClick}
                >
                  Make Payment <Plus />
                </button>
              )}
              <button
                type="button"
                className="btn btn-secondary btn-sm mb-2 mx-2"
                onClick={handlePDFDownloadButtonClick}
              >
                Download PDF
                <Download className="mx-2" style={{ fill: "white" }} />
              </button>
            </>
          )}
          <button
            type="button"
            className="btn btn-primary btn-sm mb-2"
            onClick={GoBack}
          >
            Back
          </button>
        </div>
      </div>
      {loading ? (
        <CustomSkeleton
          height={500}
          width={"100%"}
          center={true}
          customOuterStyle={{ padding: 0 }}
        />
      ) : (
        <div
          style={{ border: "1px solid black", padding: "0 10px" }}
          className="mb-5"
        >
          <div
            className="Tax Invoice"
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "bold",
              borderBottom: "1px solid black",
              fontSize: "13px",
            }}
          >
            <div>
              {" "}
              <img
                src="/images/svg/ic_logo.svg"
                width="160"
                height="80"
                className="d-inline-block align-top"
                alt="Logo"
              />
            </div>
            <span>
              <p className="mb-0 mt-2">Tax Invoice</p>
              <p>{data.document_number}</p>
            </span>
          </div>

          <div className="address" style={{ fontSize: "11px" }}>
            <div
              className="d-flex justify-content-between"
              style={{ borderBottom: "1px solid black" }}
            >
              <div
                className="col-md-6 py-1 pe-1"
                style={{ borderRight: "1px solid black" }}
              >
                <div className="col-md-12 pb-1" style={{}}>
                  <div className="row">
                    <div
                      className="col-12 d-flex"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <span className="pe-1">Invoice From </span>
                      {supplierName && (
                        <span style={{ fontWeight: "500" }}>
                          : {supplierName}
                        </span>
                      )}
                    </div>
                    <div className="col-12 py-1">
                      {!organizationAddress && (
                        <p className="text-center">Address is not found</p>
                      )}
                      <span className="text-justify">
                        {organizationAddress?.address_name &&
                          organizationAddress?.address_name + ", "}
                        {organizationAddress?.address_line1 &&
                          organizationAddress?.address_line1 + ", "}
                        {organizationAddress?.address_line2 &&
                          organizationAddress?.address_line2 + ", "}
                        {organizationAddress?.city &&
                          organizationAddress?.city + ", "}
                        {organizationAddress?.state &&
                          organizationAddress?.state + ", "}
                        {organizationAddress?.country?.name &&
                          organizationAddress?.country?.name}
                        {organizationAddress?.zip_code &&
                          "-" + organizationAddress?.zip_code}
                      </span>
                    </div>
                    <div className="col-6">
                      <p className="mb-0">
                        <span style={{ fontWeight: "500" }}>
                          Contact Person :
                        </span>
                        {user?.first_name} {user?.last_name}
                      </p>
                      {organizationAddress?.email && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Email : </span>
                          {organizationAddress?.email}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      {organizationAddress?.phone_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>
                            Contact No :{" "}
                          </span>
                          {organizationAddress?.phone_no}
                        </p>
                      )}
                      {organizationAddress?.fax_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Fax No : </span>
                          {organizationAddress?.fax_no}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 py-1 ps-1">
                <div className="col-md-12 pb-1" style={{}}>
                  <div className="row">
                    <div
                      className="col-12 d-flex"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <span className="pe-1">Invoice To </span>
                      {buyerName && (
                        <span style={{ fontWeight: "500" }}>: {buyerName}</span>
                      )}
                    </div>
                    <div className="col-12 py-1">
                      {!invoiceToAddress && (
                        <p className="text-center">Address is not found</p>
                      )}
                      <span className="text-justify">
                        {invoiceToAddress?.address_name &&
                          invoiceToAddress?.address_name + ", "}
                        {invoiceToAddress?.address_line1 &&
                          invoiceToAddress?.address_line1 + ", "}
                        {invoiceToAddress?.address_line2 &&
                          invoiceToAddress?.address_line2 + ", "}
                        {invoiceToAddress?.city &&
                          invoiceToAddress?.city + ", "}
                        {invoiceToAddress?.state &&
                          invoiceToAddress?.state + ", "}
                        {invoiceToAddress?.country?.name &&
                          invoiceToAddress?.country?.name}
                        {invoiceToAddress?.zip_code &&
                          "-" + invoiceToAddress?.zip_code}
                      </span>
                    </div>
                    <div className="col-6">
                      {invoiceToAddress?.email && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Email : </span>
                          {invoiceToAddress?.email}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      {invoiceToAddress?.phone_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>
                            Contact No :{" "}
                          </span>
                          {invoiceToAddress?.phone_no}
                        </p>
                      )}
                      {invoiceToAddress?.fax_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Fax No : </span>
                          {invoiceToAddress?.fax_no}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="d-flex justify-content-between"
              style={{ borderBottom: "1px solid black" }}
            >
              <div
                className="col-md-6 py-1 pe-1"
                style={{ borderRight: "1px solid black" }}
              >
                <div className="col-md-12 pb-1" style={{}}>
                  <div className="row">
                    <div
                      className="col-12 d-flex"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <span className="pe-1">Shipped To </span>
                      {buyerName && (
                        <span style={{ fontWeight: "500" }}>: {buyerName}</span>
                      )}
                    </div>
                    <div className="col-12 py-1">
                      {!BuyersAndSuppliersAddress && (
                        <p className="text-center">Address is not found</p>
                      )}
                      <span className="text-justify">
                        {BuyersAndSuppliersAddress?.address_name &&
                          BuyersAndSuppliersAddress?.address_name + ", "}
                        {BuyersAndSuppliersAddress?.address_line1 &&
                          BuyersAndSuppliersAddress?.address_line1 + ", "}
                        {BuyersAndSuppliersAddress?.address_line2 &&
                          BuyersAndSuppliersAddress?.address_line2 + ", "}
                        {BuyersAndSuppliersAddress?.city &&
                          BuyersAndSuppliersAddress?.city + ", "}
                        {BuyersAndSuppliersAddress?.state &&
                          BuyersAndSuppliersAddress?.state + ", "}
                        {BuyersAndSuppliersAddress?.country?.name &&
                          BuyersAndSuppliersAddress?.country?.name}
                        {BuyersAndSuppliersAddress?.zip_code &&
                          "-" + BuyersAndSuppliersAddress?.zip_code}
                      </span>
                    </div>
                    <div className="col-6">
                      {BuyersAndSuppliersAddress?.email && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Email : </span>
                          {BuyersAndSuppliersAddress?.email}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      {BuyersAndSuppliersAddress?.phone_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>
                            Contact No :{" "}
                          </span>
                          {BuyersAndSuppliersAddress?.phone_no}
                        </p>
                      )}
                      {BuyersAndSuppliersAddress?.fax_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Fax No : </span>
                          {BuyersAndSuppliersAddress?.fax_no}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 py-1 ps-1">
                <div className="col-md-12 pb-1" style={{}}>
                  <div className="row">
                    <div
                      className="col-12 d-flex"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <span className="pe-1">Manufactured By </span>
                      {manufactureName && (
                        <span style={{ fontWeight: "500" }}>
                          : {manufactureName}
                        </span>
                      )}
                    </div>
                    <div className="col-12 py-1">
                      {!manufactureBillingAddress && (
                        <p className="text-center">Address is not found</p>
                      )}
                      <span className="text-justify">
                        {manufactureBillingAddress?.address_name &&
                          manufactureBillingAddress?.address_name + ", "}
                        {manufactureBillingAddress?.address_line1 &&
                          manufactureBillingAddress?.address_line1 + ", "}
                        {manufactureBillingAddress?.address_line2 &&
                          manufactureBillingAddress?.address_line2 + ", "}
                        {manufactureBillingAddress?.city &&
                          manufactureBillingAddress?.city + ", "}
                        {manufactureBillingAddress?.state &&
                          manufactureBillingAddress?.state + ", "}
                        {manufactureBillingAddress?.country?.name &&
                          manufactureBillingAddress?.country?.name}
                        {manufactureBillingAddress?.zip_code &&
                          "-" + manufactureBillingAddress?.zip_code}
                      </span>
                    </div>
                    <div className="col-6">
                      {manufactureBillingAddress?.email && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Email : </span>
                          {manufactureBillingAddress?.email}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      {manufactureBillingAddress?.phone_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>
                            Contact No :{" "}
                          </span>
                          {manufactureBillingAddress?.phone_no}
                        </p>
                      )}
                      {manufactureBillingAddress?.fax_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Fax No : </span>
                          {manufactureBillingAddress?.fax_no}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="Invoice Details">
              {/* <span
                className="d-flex justify-content-center my-2"
                style={{ fontWeight: "500", fontSize: "14px" }}
              >
                {TAX_INVOICE_TITLE} Details
              </span> */}
              <div
                className="my-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                }}
              >
                <span
                  className="mt-1"
                  style={{ flex: 1, borderBottom: "1px solid black" }}
                ></span>
                <span
                  style={{
                    padding: "0 10px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  {TAX_INVOICE_TITLE} Details
                </span>
                <span
                  className="mt-1"
                  style={{ flex: 1, borderBottom: "1px solid black" }}
                ></span>
              </div>
              <div className="row" style={{ fontSize: "12px" }}>
                <div className="col-4">
                  <p className="mb-0">
                    <span style={{ fontWeight: "500" }}>Invoice Number :</span>{" "}
                    {data.document_number ? data.document_number : "Not Found"}
                  </p>
                </div>
                <div className="col-4">
                  <p className="mb-0">
                    <span style={{ fontWeight: "500" }}>Invoice Date :</span>{" "}
                    {data.document_date
                      ? DateWithoutTime(data.document_date)
                      : "Not Found"}
                  </p>
                </div>
                <div className="col-4">
                  <p className="mb-0">
                    <span style={{ fontWeight: "500" }}>Store :</span>{" "}
                    {storeData?.name ? storeData?.name : "Not Found"}
                  </p>
                </div>
              </div>
              <div className="row" style={{ fontSize: "12px" }}>
                <div className="col-4">
                  <p className="mb-0">
                    <span style={{ fontWeight: "500" }}>Payment Terms :</span>{" "}
                    {paymentTermsData?.name
                      ? paymentTermsData?.name
                      : "Not Found"}
                  </p>
                </div>
                <div className="col-4">
                  <p className="mb-0">
                    <span style={{ fontWeight: "500" }}>PO Number :</span>{" "}
                    {purchaseOrderData?.document_number
                      ? purchaseOrderData?.document_number
                      : "Not Found"}
                  </p>
                </div>
                <div className="col-4">
                  <p className="mb-0">
                    <span style={{ fontWeight: "500" }}>PO Date :</span>{" "}
                    {data.purchase_order_date
                      ? DateWithoutTime(data.purchase_order_date)
                      : "Not Found"}
                  </p>
                </div>
              </div>
              <div className="row" style={{ fontSize: "12px" }}>
                <div className="col-4">
                  <p className="mb-0">
                    <span style={{ fontWeight: "500" }}>OC Number :</span>{" "}
                    {data.oc_number ? data.oc_number : "Not Found"}
                  </p>
                </div>
                <div className="col-4">
                  <p className="mb-0">
                    <span style={{ fontWeight: "500" }}>OC Date :</span>{" "}
                    {data.oc_date ? DateWithoutTime(data.oc_date) : "Not Found"}
                  </p>
                </div>
                <div className="col-4">
                  <p className="mb-0">
                    <span style={{ fontWeight: "500" }}>Payment Date :</span>{" "}
                    {data.payment_date
                      ? DateWithoutTime(data.payment_date)
                      : "Not Found"}
                  </p>
                </div>
              </div>
              <div className="row" style={{ fontSize: "12px" }}>
                <div className="col-4">
                  <p className="mb-0">
                    <span style={{ fontWeight: "500" }}>
                      Logistic Details :
                    </span>{" "}
                    {logisticDetailData
                      ? `${logisticDetailData.name} - ${logisticDetailData.description}`
                      : "null"}
                  </p>
                </div>
              </div>
            </div>
            <div className="table w-100 col-md-12 m-0">
              <div
                className="my-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                }}
              >
                <span
                  className="mt-1"
                  style={{ flex: 1, borderBottom: "1px solid black" }}
                ></span>
                <span
                  style={{
                    padding: "0 10px",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  Product Details
                </span>
                <span
                  className="mt-1"
                  style={{ flex: 1, borderBottom: "1px solid black" }}
                ></span>
              </div>
              <Table
                responsive
                bordered
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  fontSize: "12px",
                }}
              >
                <thead className="bg-transparent">
                  <tr className="bg-transparent">
                    <th className="bg-transparent">#</th>
                    <th className="bg-transparent">Description</th>
                    {/* <th className="bg-transparent" style={{ width: "12%" }}>
                      HSN/SAC Code
                    </th> */}
                    <th className="bg-transparent">Quantity</th>
                    <th className="bg-transparent">Unit</th>
                    <th className="bg-transparent">Price</th>
                    <th className="bg-transparent">Discount</th>
                    <th className="bg-transparent" style={{ width: "13%" }}>
                      Total Before Tax
                    </th>
                    <th className="bg-transparent">Tax</th>
                    <th className="bg-transparent">Total</th>
                    <th className="bg-transparent">Comments</th>
                    <th className="bg-transparent" style={{ width: "12%" }}>
                      Delivery Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-transparent">
                  {data?.items?.map((item: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className="bg-transparent">{index + 1}</td>
                        <td className="bg-transparent" style={{ width: "30%" }}>
                          {item?.product ? (
                            <div style={{ textAlign: "left" }}>
                              {data.status !== "draft" && item.products_data
                                ? JSON.parse(item.products_data)?.product_name
                                : item.product?.product_name}
                              <br />
                              <span style={{ fontSize: "small" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  Item ID:{" "}
                                </span>
                                {data.status !== "draft" && item.products_data
                                  ? JSON.parse(item.products_data)?.product_id
                                  : item.product?.product_id}
                                <span style={{ fontWeight: "700" }}>|</span>
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  Description:{" "}
                                </span>
                                {data.status !== "draft" && item.products_data
                                  ? JSON.parse(item.products_data)?.description
                                  : item.product?.description}{" "}
                                {item.batch && (
                                  <>
                                    <span style={{ fontWeight: "700" }}>|</span>
                                    <span style={{ fontWeight: "bold" }}>
                                      {" "}
                                      Batch Number:{" "}
                                    </span>
                                    {data.status !== "draft" &&
                                    item.batches_data
                                      ? JSON.parse(item.batches_data)
                                          ?.batch_number
                                      : item.batch.batch_number}{" "}
                                    <span style={{ fontWeight: "700" }}>|</span>
                                    <span style={{ fontWeight: "bold" }}>
                                      {" "}
                                      Expiry Date:{" "}
                                    </span>
                                    {data.status !== "draft" &&
                                    item.batches_data
                                      ? DateWithoutTime(
                                          JSON.parse(item.batches_data)
                                            ?.expiry_date,
                                        )
                                      : DateWithoutTime(
                                          item.batch.expiry_date,
                                        )}{" "}
                                  </>
                                )}
                              </span>
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                        {/* <td className="bg-transparent">
                        {item["hsn/sac_code"] ? item["hsn/sac_code"] : "-"}
                      </td> */}
                        <td className="bg-transparent">
                          {item.quantity ? item.quantity : "-"}
                        </td>
                        <td className="bg-transparent">
                          {data.status !== "draft" &&
                          item.unit_of_measurement_data
                            ? JSON.parse(item.unit_of_measurement_data)?.name
                            : item.unit.name}
                        </td>
                        <td className="bg-transparent">
                          {item.price ? AmountFormat(item.price) : "-"}
                        </td>
                        <td className="bg-transparent">
                          {item.discount
                            ? item.discount_type === "pct"
                              ? `${item.discount}%`
                              : `${currency.symbol}${item.discount}`
                            : "-"}
                        </td>
                        <td className="bg-transparent">
                          {item.total_price
                            ? AmountFormat(item.total_price)
                            : "-"}
                        </td>
                        <td className="bg-transparent">
                          {item.total_tax ? AmountFormat(item.total_tax) : "-"}
                        </td>
                        <td className="bg-transparent">
                          {AmountFormat(
                            (
                              Number(item.total_price) + Number(item.total_tax)
                            ).toFixed(2),
                          )}
                        </td>
                        <td className="bg-transparent">
                          {item.comments ? item.comments : "-"}
                        </td>
                        <td className="bg-transparent">
                          {item.delivery_date
                            ? DateWithoutTime(item.delivery_date)
                            : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {data?.charges?.length > 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className="mt-1"
                      style={{ flex: 1, borderBottom: "1px solid black" }}
                    ></span>
                    <span
                      style={{
                        padding: "0 10px",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      Extra Charges
                    </span>
                    <span
                      className="mt-1"
                      style={{ flex: 1, borderBottom: "1px solid black" }}
                    ></span>
                  </div>
                  <Table
                    responsive
                    bordered
                    className="my-2"
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    <thead className="bg-transparent">
                      <tr className="bg-transparent">
                        <th className="bg-transparent">Description</th>
                        <th className="bg-transparent">Total Before Tax</th>
                        <th className="bg-transparent">Total Tax</th>
                        <th className="bg-transparent">Total After Tax</th>
                      </tr>
                    </thead>
                    <tbody className="bg-transparent">
                      {data?.charges?.map((item: any, index: any) => {
                        return (
                          <tr key={index}>
                            <td
                              className="bg-transparent"
                              style={{ textAlign: "left" }}
                            >
                              {item.extra_charge_description}
                            </td>
                            <td className="bg-transparent">
                              {item.total ? AmountFormat(item.total) : "-"}
                            </td>
                            <td className="bg-transparent">
                              {item.total_tax
                                ? AmountFormat(item.total_tax)
                                : "-"}
                            </td>
                            <td className="bg-transparent">
                              {AmountFormat(
                                (
                                  Number(item.total) + Number(item.total_tax)
                                ).toFixed(2),
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}
              <div
                className="d-flex justify-content-between"
                style={{
                  borderBottom: "1px solid lightgrey",
                  fontSize: "12px",
                }}
              >
                <div>
                  <div className="d-flex bg-transparent p-0">
                    <p className="mb-0" style={{ fontWeight: "500" }}>
                      Invoice Amount :&nbsp;
                    </p>
                    <p className="mb-0">
                      {data?.amounts_in_words?.total_amount}
                    </p>
                  </div>
                  <div className="d-flex bg-transparent p-0">
                    <p className="mb-0" style={{ fontWeight: "500" }}>
                      Total Tax :&nbsp;
                    </p>
                    <p className="mb-0">{data?.amounts_in_words?.total_tax}</p>
                  </div>
                  <div className="d-flex bg-transparent p-0">
                    <p className="mb-0" style={{ fontWeight: "500" }}>
                      Payable Amount :&nbsp;
                    </p>
                    <p className="mb-0">
                      {data?.amounts_in_words?.payable_amount}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="d-flex mb-0 justify-content-end bg-transparent">
                    <span style={{ fontWeight: "500" }}>
                      Total (before Tax) :
                    </span>
                    <span className="mb-0">
                      {data?.items
                        ? AmountFormat(
                            currency?.symbol +
                              (
                                data?.items?.reduce(
                                  (sum: any, item: any) =>
                                    sum + parseFloat(item.total_price || 0),
                                  0,
                                ) +
                                data?.charges?.reduce(
                                  (sum: any, charge: any) =>
                                    sum + parseFloat(charge.total || 0),
                                  0,
                                )
                              ).toFixed(2),
                          )
                        : ""}
                    </span>
                  </p>
                  <div
                    className="bg-transparent p-0"
                    style={{ border: "1px solid black", fontSize: "14px" }}
                  >
                    {Object.entries(groupedItems).map(
                      ([taxName, items]: any, index: number, array: any[]) => (
                        <Card
                          key={taxName}
                          style={{
                            minWidth: "150px",
                            margin: "0",
                            width: "auto",
                            borderRadius: "0",
                            border: "0",
                            justifyContent: "center",
                            borderBottom:
                              index === array.length - 1
                                ? "none"
                                : "1px solid black",
                          }}
                          className="bg-transparent"
                        >
                          <Card.Body
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              padding: "0",
                              fontSize: "12px",
                            }}
                          >
                            <Card.Title
                              style={{
                                textAlign: "start",
                                flex: "1",
                                borderRight: "1px solid black",
                                margin: "0",
                                padding: "7px",
                                paddingLeft: "10px",
                                fontSize: "14px",
                              }}
                              className="bg-transparent"
                            >
                              {taxName}
                            </Card.Title>
                            <Card.Text
                              style={{
                                textAlign: "start",
                                flex: "1",
                                margin: "0",
                                marginLeft: "15px",
                                fontSize: "14px",
                              }}
                              className="bg-transparent"
                            >
                              {currency?.symbol +
                                AmountFormat(
                                  items
                                    .reduce(
                                      (sum: any, item: any) =>
                                        sum + parseFloat(item.total_tax),
                                      0,
                                    )
                                    .toFixed(2),
                                )}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      ),
                    )}
                  </div>
                  <div className="d-flex justify-content-end bg-transparent">
                    <span>
                      <p className="mb-0" style={{ fontWeight: "500" }}>
                        Total Tax
                      </p>
                      <p className="mb-0" style={{ fontWeight: "500" }}>
                        Grand Total
                      </p>
                    </span>
                    <span className="ms-5">
                      <p className="mb-0">
                        <span className="mb-0">
                          {data?.total_tax
                            ? currency?.symbol +
                              AmountFormat(Number(data?.total_tax)?.toFixed(2))
                            : ""}
                        </span>
                      </p>
                      <p className="mb-0">
                        <span className="mb-0">
                          {data?.total_amount
                            ? currency?.symbol +
                              AmountFormat(
                                Number(data?.total_amount)?.toFixed(2),
                              )
                            : ""}
                        </span>
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="py-2"
              style={{ borderBottom: "1px solid lightgrey", fontSize: "12px" }}
            >
              <span style={{ fontSize: "14px", fontWeight: "500" }}>
                Comments:
              </span>
              <p className="m-0">{data.comments || "Not Added"}</p>
            </div>
            <div
              className="py-2"
              style={{ borderBottom: "1px solid lightgrey", fontSize: "12px" }}
            >
              <span style={{ fontSize: "14px", fontWeight: "500" }}>
                Terms And Conditions:
              </span>
              {termsAndConditionData ? (
                <p>
                  {termsAndConditionData?.name} -{" "}
                  {termsAndConditionData?.description}
                </p>
              ) : (
                <p className="mb-0">Not Added</p>
              )}
            </div>
            <div
              className="py-2"
              style={{ borderBottom: "1px solid lightgrey", fontSize: "12px" }}
            >
              <span style={{ fontSize: "14px", fontWeight: "500" }}>
                Bank Details
              </span>
              {bankDetailData ? (
                <div className="d-flex" style={{ width: "900px" }}>
                  <div style={{ width: "100%" }}>
                    <p className="mb-0">
                      <span style={{ fontWeight: "bold" }}> Bank Name : </span>
                      {bankDetailData?.bank_name
                        ? " " + bankDetailData?.bank_name
                        : " Not found"}
                    </p>
                    <p className="mb-0">
                      <span style={{ fontWeight: "bold" }}> A/C Name : </span>
                      {bankDetailData?.account_name
                        ? " " + bankDetailData?.account_name
                        : " Not found"}
                    </p>
                    <p className="mb-0">
                      <span style={{ fontWeight: "bold" }}> A/C Number : </span>
                      {bankDetailData?.account_number
                        ? " " + bankDetailData?.account_number
                        : " Not found"}
                    </p>
                    <p className="mb-0">
                      <span style={{ fontWeight: "bold" }}> Branch : </span>
                      {bankDetailData?.branch
                        ? " " + bankDetailData?.branch
                        : " Not found"}
                    </p>
                    <p className="mb-0">
                      <span style={{ fontWeight: "bold" }}> SWIFT Code : </span>
                      {bankDetailData?.swift_code
                        ? " " + bankDetailData?.swift_code
                        : " Not found"}
                    </p>
                    <p className="mb-0">
                      <span style={{ fontWeight: "bold" }}> MICR : </span>
                      {bankDetailData?.micr
                        ? " " + bankDetailData?.micr
                        : " Not found"}
                    </p>
                  </div>
                </div>
              ) : (
                <p className="mb-0">Not Added</p>
              )}
            </div>

            <div
              className="py-2"
              style={{ borderBottom: "1px solid lightgrey", fontSize: "12px" }}
            >
              <span key={data.id}>
                <div>
                  <span style={{ fontSize: "14px", fontWeight: "500" }}>
                    Existing Attachments:
                  </span>
                  <span className="d-flex flex-wrap align-items-center mb-0 mt-2">
                    {attachmentData && attachmentData.length > 0 ? (
                      attachmentData.map((data: any) => (
                        <span>
                          <div
                            className="text-center me-2 p-2"
                            style={{
                              background: "#dddddd",
                              cursor: "pointer",
                              flexBasis: "13%",
                              flexShrink: 0,
                              minWidth: "100px",
                              borderRadius: "5px",
                            }}
                            onClick={() =>
                              handleDownload(data?.file_path, data?.title)
                            }
                          >
                            {getFileTypeImage(data.file_path)}
                            <div style={{ fontSize: "10px", marginTop: "5px" }}>
                              {data.file_path.split("/").pop()}
                            </div>
                          </div>
                        </span>
                      ))
                    ) : (
                      <p className="mb-0">Not Added</p>
                    )}
                  </span>
                </div>
              </span>
            </div>

            <div style={{ width: "300px", marginLeft: "auto" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <span className="d-flex justify-content-end my-2">
                  For {user.organization.company_name}
                </span>
                <span className="">
                  {signatureData && signatureData.length > 0 ? (
                    signatureData.map((data: any) => (
                      <div
                        className="text-end"
                        style={{ flexBasis: "13%", flexShrink: 0 }}
                      >
                        <img
                          src={`${data?.file_path}?${Date.now()}`}
                          alt={data?.title}
                          className="uploaded-image"
                          style={{
                            maxWidth: "150px",
                            maxHeight: "50pt",
                            borderRadius: "3px",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                          }}
                        />
                      </div>
                    ))
                  ) : (
                    <p
                      className="mb-0"
                      style={{
                        width: "150px",
                        height: "50pt",
                      }}
                    ></p>
                  )}
                </span>
                <span className="d-flex justify-content-end my-2">
                  Authorised Signatory
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default View;
