import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/Tax/List";
import AddUpdate from "../../Pages/Setting/Tax/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_TAX,
  DELETE_TAX,
  RESTORE_TAX,
  UPDATE_TAX,
  VIEW_TAX,
} from "../../Util/PermissionList";

export const TAX_TITLE = "Tax";
export const TAX_LIST = "/taxs";
export const TAX_ADD = "/taxs/add";
export const TAX_EDIT = "/taxs/:id/edit";
export const TAX_ARCHIVE = "/taxs/archive";

const Tax: RouteObject[] = [
  {
    path: TAX_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_TAX}
        checkPermisson={{
          addPermission: ADD_TAX,
          updatePermission: UPDATE_TAX,
          deletePermission: DELETE_TAX,
          restorePermission: RESTORE_TAX,
          viewPermission: VIEW_TAX,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: TAX_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate title={TAX_TITLE} listRoute={TAX_LIST} />}
        permission={ADD_TAX}
        checkPermisson={{
          addPermission: ADD_TAX,
          updatePermission: UPDATE_TAX,
          deletePermission: DELETE_TAX,
          restorePermission: RESTORE_TAX,
          viewPermission: VIEW_TAX,
        }}
      />
    ),
  },
  {
    path: TAX_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate title={TAX_TITLE} listRoute={TAX_LIST} />}
        permission={UPDATE_TAX}
        checkPermisson={{
          addPermission: ADD_TAX,
          updatePermission: UPDATE_TAX,
          deletePermission: DELETE_TAX,
          restorePermission: RESTORE_TAX,
          viewPermission: VIEW_TAX,
        }}
      />
    ),
  },
  {
    path: TAX_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_TAX,
          updatePermission: UPDATE_TAX,
          deletePermission: DELETE_TAX,
          restorePermission: RESTORE_TAX,
          viewPermission: VIEW_TAX,
        }}
        permission={DELETE_TAX}
      />
    ),
  },
];

export default Tax;
