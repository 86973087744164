import { getUser } from "./Auth";

export function AmountFormat(number: any) {
  const user = getUser();
  const Delimiter = user.organization.currency_delimiter;
  const DecimalDelimiter = user.organization.currency_decimal_delimiter;
  return number
    ? number
        .toString()
        .replace(/\./g, DecimalDelimiter)
        .replace(/\B(?=(\d{3})+(?!\d))/g, Delimiter)
    : "";
}
