export const AuditLogModule: any = {
  "bank-details": "BANK_DETAILS",
  batches: "BATCH",
  bill_of_material: "BILL_OF_MATERIAL",
  bill_of_material_finished_goods: "BILL_OF_MATERIAL_FINISHED_GOODS",
  bill_of_material_raw_materials: "BILL_OF_MATERIAL_RAW_MATERIALS",
  bill_of_material_routines: "BILL_OF_MATERIAL_ROUTINES",
  bill_of_material_scrap: "BILL_OF_MATERIAL_SCRAP",
  "counter-party-address": "COUNTER_PARTY_ADDRESSES",
  "counter-party-people": "COUNTER_PARTY_PEOPLE",
  country: "COUNTRY",
  currencies: "CURRENCY",
  designations: "DESIGNATIONS",
  "document-templates": "DOCUMENT_TEMPLATES",
  "logistic-details": "LOGISTIC_DETAILS",
  "organization-addresses": "ORGANIZATION_ADDRESS",
  "payment-terms": "PAYMENT_TERMS",
  "production-process-types": "PRODUCTION_PROCESS_TYPES",
  roles: "ROLE",
  "tax-types": "TAX_TYPES",
  "terms-and-conditions": "TERMS_AND_CONDITIONS",
  "unit-of-measurements": "UNIT_OF_MEASUREMENT",
  "user-types": "USER_TYPE",
};
