import { useEffect, useState } from "react";
import { useApi } from "../Controller/ApiController";
import { getUser } from "./Auth";
import { setToast } from "./Toast";

export function useDateFormat() {
  const [dateFormat, setDateFormat] = useState("");

  const { apiGet } = useApi();
  const user = getUser();

  useEffect(() => {
    async function fetchDateFormat() {
      try {
        const formats = await apiGet("dateformat-list");
        const format = Object.keys(formats?.data).map((key) => ({
          label: formats?.data[key],
          value: key,
        }));
        const matchedFormat = format.find(
          (format) => format.value === user.organization.dateformat,
        );
        if (matchedFormat) {
          const dateFormat = matchedFormat.label
            .replace(/\(e\.g\., .+?\)/, "")
            .trim()
            .replace("DD", "dd");
          setDateFormat(dateFormat);
        }
      } catch (error: any) {
        if (error.response?.data?.errors) {
          setToast("error", error.response.data.errors);
        } else if (error.response?.data?.message) {
          setToast("error", error.response.data.message);
        } else {
          setToast("error", "An error occurred while processing your request");
        }
      }
    }

    fetchDateFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organization?.dateformat]);

  return dateFormat;
}
