export const LABELS = {
  EMAIL: "Email ID",
  VERIFY_OTP: "Verify OTP",
  PASSWORD: "Password",
};

export const BUTTONS = {
  CONTINUE: "Continue",
  VERIFY_OTP: "Verify OTP",
  SIGN_IN: "Sign In",
  SIGN_UP: "Sign Up",
};

export const HEADINGS = {
  SIGN_IN: "Sign In",
  SIGN_UP: "Sign Up",
};

export const TEXT = {
  RESEND_OTP: "RESEND OTP",
  DO_NOT_RECEIVE_OTP: "Didn`t Receive OTP ?",
};
