import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { setToast } from "../../../Util/Toast";
import FieldDescription from "../../../Components/FieldDescription";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { Product } from "../../../Model/Inventory/Product";
import { Country } from "../../../Model/Setting/Country";
import {
  BATCH_MANAGEMENT_LIST,
  BATCH_MANAGEMENT_TITLE,
} from "../../../Routes/SalesAndPurchase/BatchManagement/BatchManagement";
import { BatchManagement } from "../../../Model/SalesAndPurchase/BatchManagement/BatchManagement";
import {
  DateFormatWithStartOFTheDay,
  DateString,
  DateWithoutTime,
} from "../../../Util/DateFormat";
import { useDateFormat } from "../../../Util/InputDateFormate";
import { PRODUCT_LIST, PRODUCT_TITLE } from "../../../Routes/Inventory/Product";
import AddUpdateProduct from "../../Inventory/Product/AddUpdate";
const AddUpdate = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const [isShows, setIsShows] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status
  const [expiryDatechange, setExpiryDatechange] = useState<any>(null); // State variable to track form submission status
  const dateFormat: any = useDateFormat();
  const [productsModalStatus, setProductsModalStatus] =
    useState<boolean>(false);
  const formFields = [
    {
      id: "batch_number",
      label: "Batch Number*",
      type: "text",
      col: 12,
      name: "batch_number",
      placeholder: "Enter Batch Number",
      disabled: formData && formData.batch_number ? true : false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Batch Number is required",
        },
        {
          rule: (value: any) => value && value.length >= 3,
          message: "The Batch Number field must be at least 3 characters.",
        },
      ],
      description:
        "Unique identifier for a group of related transactions or items processed together.",
    },
    {
      id: "product",
      label: "Product*",
      type: "select",
      col: 6,
      name: "products_id",
      placeholder: "Select an Product",
      searchField: "product_name",
      addNewBtnStatus: true,
      model: Product,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Product is required",
        },
      ],
      description: "Choose the applicable product for the  Batch Management.  ",
      onChangeModalStatus: (status: boolean) => setProductsModalStatus(status),
      modalStatus: productsModalStatus,
      widget: (
        <AddUpdateProduct
          title={PRODUCT_TITLE}
          listRoute={PRODUCT_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) =>
            setProductsModalStatus(status)
          }
        />
      ),
    },
    {
      id: "expiry_date",
      label: "Expiry Date*",
      type: "datePicker",
      datePicker: true,
      col: 6,
      name: "expiry_date",
      placeholder: dateFormat.replace("dd", "DD"),
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Expiry Date is required",
        },
      ],
      description:
        "The date when the item or record is set to expire. Please enter the expiration date for this entry.",
    },
    {
      id: "current_stock",
      label: "Current Stock",
      type: "number",
      col: 6,
      name: "current_stock",
      placeholder: "Current Stock",
      disabled: true,
      description:
        "The numerical amount or count of items. Please enter the Current Stock associated with this entry.",
    },
    {
      id: "inputConcentration",
      label: "Concentration*",
      type: "text",
      col: 6,
      name: "concentration",
      placeholder: "Enter Concentration",
      description: "The Concentration for product.",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Concentration is required",
        },
      ],
    },
    {
      id: "inputPackSize",
      label: "Pack Size*",
      type: "number",
      col: 6,
      name: "pack_size",
      placeholder: "Enter Pack Size",
      description: "The Pack Size for product.",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Pack Size is required",
        },
      ],
    },
    {
      id: "inputcountry_id",
      label: "Country*",
      type: "select",
      col: 6,
      name: "country_id",
      placeholder: "Select an Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is required",
        },
      ],
      description:
        "Indicate the country in which the Batch Management is located.",
    },
    {
      id: "inputDescription",
      label: "Description*",
      type: "text",
      col: 12,
      name: "description",
      placeholder: "Enter Description",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Description is required",
        },
      ],
      description:
        "Provide a comprehensive description outlining the purpose and scope of the terms and conditions.",
    },
  ];

  const navigate = useNavigate();
  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);

    let inputData = Object.assign({}, formData);
    if (
      formFields.find(
        (field) => field.name === "batch_number" && field.disabled,
      )
    ) {
      delete inputData.batch_number;
    }
    const productIdNumber = formData.products_id
      ? parseInt(formData.products_id.value)
      : null;
    const countryIdNumber = inputData.country_id
      ? parseInt(inputData.country_id.value)
      : null;

    inputData.products_id = productIdNumber;
    inputData.country_id = countryIdNumber;
    inputData.expiry_date = DateFormatWithStartOFTheDay(
      inputData.expiry_date,
      dateFormat?.replace("dd", "DD"),
    );

    try {
      if (inputData.id) {
        let item: any = await BatchManagement.$query().find(inputData.id);
        if (
          formFields.find(
            (field) => field.name === "batch_number" && field.disabled,
          )
        ) {
          delete item.$attributes.batch_number;
        }
        item.$attributes = {
          ...item.$attributes,
          ...inputData,
        };

        item = await item.$save();
        setToast("success", `${BATCH_MANAGEMENT_TITLE} Successfully Updated`);
      } else {
        await BatchManagement.$query().store(inputData);
        setToast("success", `${BATCH_MANAGEMENT_TITLE} Successfully Added`);
      }
      navigate(`${BATCH_MANAGEMENT_LIST.toLowerCase().replace(" ", "-")}`);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const getFormData = async (itemId: string) => {
    const item = await BatchManagement.$query()
      .with(["country", "product"])
      .find(itemId);
    return item.$attributes;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const itemData = await getFormData(id);
          let data: any = itemData;
          if (data["country_id"]) {
            data["country_id"] = {
              label: itemData.country["name"],
              value: itemData.country["id"],
            };
          }

          if (data["products_id"]) {
            data["products_id"] = {
              label: itemData.product["product_name"],
              value: itemData.product["id"],
            };
          }
          if (dateFormat && data["expiry_date"]) {
            const date: any = DateString(
              DateWithoutTime(itemData.expiry_date),
              dateFormat.split(" ")[0],
            );
            data["expiry_date"] = date;
            setExpiryDatechange(date);
          }
          setFormData(data);
          setLoading(false);
        } catch (error: any) {
          setToast("error", error.response.data.message);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dateFormat, expiryDatechange]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={formFields}
          model={BatchManagement}
          title={BATCH_MANAGEMENT_TITLE}
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          listRoute={BATCH_MANAGEMENT_LIST}
          dynamicFormError={formError}
          customSubmitting={customSubmitting}
          loadingStatus={loading}
        />
      </div>
      <div id="form-bottom">
        <FieldDescription
          title={"Batch Management"}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
