import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/Store/List";
import AddUpdate from "../../Pages/Setting/Store/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_STORE,
  DELETE_STORE,
  RESTORE_STORE,
  UPDATE_STORE,
  VIEW_STOCK_INVENTORY,
  VIEW_STORE,
} from "../../Util/PermissionList";
import StoreInventoryHistoryList from "../../Pages/Setting/Store/StoreInventoryHistoryList";

export const STORE_TITLE = "Stores / Warehouses";
export const STORE_LIST = "/stores";
export const STORE_ADD = "/stores/add";
export const STORE_EDIT = "/stores/:id/edit";
export const STORE_INVENTORY_HISTORY = "/stores/:id/inventory-history";
export const STORE_ARCHIVE = "/stores/archive";

const Store: RouteObject[] = [
  {
    path: STORE_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_STORE}
        exception={true}
        checkPermisson={{
          addPermission: ADD_STORE,
          updatePermission: UPDATE_STORE,
          deletePermission: DELETE_STORE,
          restorePermission: RESTORE_STORE,
          viewPermission: VIEW_STORE,
          VIEW_STOCK_INVENTORY,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: STORE_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate title={STORE_TITLE} listRoute={STORE_LIST} />}
        permission={ADD_STORE}
        checkPermisson={{
          addPermission: ADD_STORE,
          updatePermission: UPDATE_STORE,
          deletePermission: DELETE_STORE,
          restorePermission: RESTORE_STORE,
          viewPermission: VIEW_STORE,
        }}
      />
    ),
  },
  {
    path: STORE_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate title={STORE_TITLE} listRoute={STORE_LIST} />}
        permission={UPDATE_STORE}
        checkPermisson={{
          addPermission: ADD_STORE,
          updatePermission: UPDATE_STORE,
          deletePermission: DELETE_STORE,
          restorePermission: RESTORE_STORE,
          viewPermission: VIEW_STORE,
        }}
      />
    ),
  },
  {
    path: STORE_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_STORE,
          updatePermission: UPDATE_STORE,
          deletePermission: DELETE_STORE,
          restorePermission: RESTORE_STORE,
          viewPermission: VIEW_STORE,
          VIEW_STOCK_INVENTORY,
        }}
        permission={DELETE_STORE}
      />
    ),
  },
  {
    path: STORE_INVENTORY_HISTORY,
    element: (
      <AuthorizedRoute
        element={<StoreInventoryHistoryList />}
        permission={VIEW_STOCK_INVENTORY}
      />
    ),
  },
];

export default Store;
