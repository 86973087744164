import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { Archive } from "react-bootstrap-icons";

interface CustomArchiveButtonProps {
  onClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number,
    option?: any,
  ) => void;
  id: number;
  isDisabled?: boolean;
}

const CustomArchiveButton: React.FC<CustomArchiveButtonProps> = ({
  onClick,
  id,
  isDisabled,
}) => {
  return (
    <ButtonGroup>
      <button
        className="btn btn-xs border-0 text-danger"
        disabled={isDisabled}
        style={{ paddingLeft: "1px", paddingRight: "5px" }}
        onClick={(e) => onClick(e, id)}
        title="Archive"
      >
        <Archive />
      </button>
    </ButtonGroup>
  );
};

export default CustomArchiveButton;
