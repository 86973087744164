export const setLocalStorage = (key: any, data: string) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getLocalStorage = (key: any) => {
  return localStorage.getItem(key);
};

export const removeLocalStorageItem = (key: any) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};
