import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomDataTable from "../../../../Components/CustomDataTable";
import { useApi } from "../../../../Controller/ApiController";
import { TableColumn } from "react-data-table-component";
import { Download } from "react-bootstrap-icons";

interface CategoryProductItem {
  id: number;
  product_name: string;
  description: string;
  current_stock: number;
  packaging_material: any;
  regular_selling_price: number;
  default_price: number;
}

const CategoryProducts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { apiGet } = useApi();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CategoryProductItem[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const searchParams = new URLSearchParams(location.search);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const [CategoryName, setCategoryName] = useState("");

  useEffect(() => {
    fetchPayments();
    fetchCategory();
  }, [limit, page]); // eslint-disable-line
  const fetchCategory = async () => {
    setLoading(true);
    try {
      if (id) {
        const item = await apiGet(`categories/${id}`);
        setCategoryName(item.data.name);
      } else {
        console.warn("CategoryId is not defined, API call skipped.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchPayments = async () => {
    setLoading(true);
    try {
      if (id) {
        const item = await apiGet(
          `categories/${id}/products?page=${page}&limit=${limit}`,
        );
        setData(item.data || []);
        setTotalRows(item?.meta?.total || 0);
      } else {
        console.warn("CategoryId is not defined, API call skipped.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns: TableColumn<CategoryProductItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Product Name",
      cell: (row) => row.product_name,
    },
    {
      name: "Description",
      cell: (row) => row.description,
    },
    {
      name: "Current Stock",
      cell: (row) => row.current_stock,
    },
    {
      name: "Default Price",
      cell: (row) => row.default_price,
    },
    {
      name: "Regular Selling Price",
      cell: (row) => row.regular_selling_price,
    },
    {
      name: "Packaging Material",
      cell: (row) => (
        <div className="d-flex justify-content-center w-50">
          {row.packaging_material ? (
            <a
              href={row.packaging_material}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Download className="mx-2" style={{ fill: "blue" }} />
            </a>
          ) : (
            <Download
              className="mx-2"
              style={{ fill: "gray", cursor: "not-allowed" }}
            />
          )}
        </div>
      ),
    },
  ];

  const handlePerRowsChange = (rowLimit: number, page: number) => {
    setLimit(rowLimit);
    setPage(page);
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center">
        <h2>Products by Category: {CategoryName}</h2>
        <div>
          <button
            type="button"
            className="btn btn-secondary btn-sm mb-2 mx-2"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      </div>
      <CustomDataTable
        columns={columns}
        data={data}
        progressPending={loading}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        currentPages={page}
        currentRowsPerPage={limit}
      />
    </div>
  );
};

export default CategoryProducts;
