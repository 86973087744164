import React, { useEffect, useRef, useState } from "react";
import { setToast } from "../Util/Toast";
import { Attachment } from "../Model/Setting/Attachment";
import { Form } from "react-bootstrap";
import CustomEditButton from "./Button/CustomEditButton";
import {
  Trash3,
  Download,
  FiletypeDoc,
  FiletypeDocx,
  FiletypePdf,
  FiletypePptx,
  FiletypeCsv,
  FiletypeXls,
  FiletypeXlsx,
  FiletypePpt,
  ExclamationTriangle,
} from "react-bootstrap-icons";
import ConfirmationModal from "./ConfirmationModel";

function AttachmentsForDocuments({
  fieldType,
  moduleType,
  id,
  customSubmit,
  setAttchmentTitle,
  setSelectedAttchmentFile,
  attachmentError,
  setIsAttchmentExist,
}: any) {
  const fileInputRef: any = useRef(null); // Create a ref for the file input element
  const [title, setTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState<any>("");
  const [attachmentData, setAttachmentData] = useState<any>([]);
  const [showConfirmation, setShowConfirmation] = useState<any>(false);
  const [actionId, setActionId] = useState<any>("");
  const [showFullScreen, setShowFullScreen] = useState<boolean>(false);
  const [editButtonClicked, setEditButtonClicked] = useState<boolean>(false);
  const [clickedImage, setClickedImage] = useState<string>("");
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status

  useEffect(() => {
    if (customSubmit) {
      saveAttachment();
    }
    // eslint-disable-next-line
  }, [customSubmit, attachmentError]);

  useEffect(() => {
    if (id) {
      getAttchmentData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const handleImageClick = (imageUrl: string) => {
    setClickedImage(imageUrl);
    setShowFullScreen(true);
  };
  const getAttchmentData = async (itemId: string) => {
    try {
      const response: any = await Attachment.$query()
        .getHttpClient()
        .get(`/search?field=${fieldType}&operator==&value=${itemId}`);
      setAttachmentData(response.data.data);
      if (response && response.data?.data?.length > 0) {
        setIsAttchmentExist(true);
      }
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };
  const handleFileChange = (event: any) => {
    const selectedFile: any = event.target.files[0];
    setSelectedFile(selectedFile);
    setSelectedAttchmentFile(selectedFile);
  };

  const saveAttachment = async (e?: React.FormEvent) => {
    try {
      e?.preventDefault();
      if (selectedFile && fieldType && id && moduleType) {
        setCustomSubmitting(true);
        const formData: any = new FormData();
        formData.append("title", title);
        selectedFile && formData.append("file_path", selectedFile);
        formData.append(fieldType, id);
        formData.append("type", moduleType);

        if (actionId) {
          await Attachment.$query()
            .getHttpClient()
            .post("/" + actionId, formData);
          getAttchmentData(id);

          setActionId("");
          setTitle("");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        } else {
          await Attachment.$query().getHttpClient().post("/", formData);
          getAttchmentData(id);
          setActionId("");
          setTitle("");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
      setEditButtonClicked(false);
    }
  };

  function handleDelete(index: any) {
    setActionId(index);
    setShowConfirmation(true);
  }
  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleConfirmDelete = async () => {
    try {
      setCustomSubmitting(true);
      await Attachment.$query()
        .getHttpClient()
        .delete("/" + actionId);
      getAttchmentData(id);
      setToast("success", `Attachment Successfully Deleted`);
      setActionId("");
      setShowConfirmation(false);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
      setTitle("");
      setEditButtonClicked(false);
    }
  };
  const handleEditAttachmentButtonClick = () => {
    const formBottom = document.getElementById("attachment");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  // Modify the handleDownload function
  const handleDownload = async (filePath: string, fileName: string) => {
    try {
      const response = await fetch(filePath);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };

  const isImageFile = (filePath: string) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const extension = filePath.split(".").pop()?.toLowerCase();
    return imageExtensions.includes(extension || "");
  };

  const getFileTypeImage = (filePath: string) => {
    const extension = filePath.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "pdf":
        return (
          <FiletypePdf
            style={{ fill: "green" }}
            width={"100%"}
            height={"30px"}
          ></FiletypePdf>
        );
      case "ppt":
        return (
          <FiletypePpt
            style={{ fill: "green" }}
            width={"100%"}
            height={"30px"}
          ></FiletypePpt>
        );
      case "pptx":
        return (
          <FiletypePptx
            style={{ fill: "green" }}
            width={"100%"}
            height={"30px"}
          ></FiletypePptx>
        );
      case "doc":
        return (
          <FiletypeDoc
            style={{ fill: "green" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeDoc>
        );
      case "docx":
        return (
          <FiletypeDocx
            style={{ fill: "green" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeDocx>
        );
      case "csv":
        return (
          <FiletypeCsv
            style={{ fill: "green" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeCsv>
        );
      case "xls":
        return (
          <FiletypeXls
            style={{ fill: "green" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeXls>
        );
      case "xlsx":
        return (
          <FiletypeXlsx
            style={{ fill: "green" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeXlsx>
        );
      default:
        return (
          <ExclamationTriangle
            style={{ fill: "green" }}
            width={"100%"}
            height={"30px"}
          ></ExclamationTriangle>
        );
    }
  };
  const FullScreenImage = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <button
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "#fff",
          fontSize: "18px",
        }}
        onClick={() => setShowFullScreen(false)}
      >
        &#x2715;
      </button>
      <img
        src={clickedImage}
        alt="Attachment"
        style={{ maxWidth: "90%", maxHeight: "90%" }}
      />
    </div>
  );

  return (
    <div
      style={{
        // backgroundColor: "#e4e4e4",
        width: "100%",
        marginTop: "30px",
        marginBottom: "10px",
      }}
    >
      <div>
        <Form encType="multipart/form-data">
          <Form.Label>Title:*</Form.Label>
          <Form.Control
            id="attachment"
            type="text"
            value={title}
            placeholder="Enter Title"
            onChange={(event) => {
              setTitle(event.target.value);
              setAttchmentTitle(event.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
          />
          {attachmentError.titleError && (
            <div
              className="error-message text-danger"
              style={{ fontSize: "small" }}
            >
              {attachmentError.titleError}
            </div>
          )}

          {/* <Form.Label className="mt-2">Choose file to import:*</Form.Label> */}
          <Form.Label className="mt-2">
            {!editButtonClicked
              ? "Choose file to import:*"
              : "Choose file for update:*"}
          </Form.Label>
          <Form.Control
            type="file"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
        </Form>
        {attachmentError.attachmentsError && (
          <div
            className="error-message text-danger"
            style={{ fontSize: "small" }}
          >
            {attachmentError.attachmentsError}
          </div>
        )}

        {id && (!attachmentData || attachmentData.length === 0) && (
          <div
            style={{ textAlign: "center", color: "gray", marginTop: "20px" }}
          >
            No attachments available
          </div>
        )}
        {attachmentData &&
          attachmentData.map((data: any) => (
            <span key={data.id}>
              <div
                className="d-flex flex-wrap align-items-center mb-0"
                style={{
                  borderRadius: "5px",
                  backgroundColor: "rgb(255, 255, 255)",
                  margin: "0.8rem 0",
                  padding: "10px",
                  boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div
                  className="flex-grow-1"
                  style={{
                    maxWidth: "70%",
                    textOverflow: "ellipsis",
                  }}
                >
                  <p className="mb-0">{data?.title}</p>
                </div>
                <div
                  className="text-center"
                  style={{ flexBasis: "13%", flexShrink: 0, minWidth: "100px" }}
                >
                  {isImageFile(data.file_path) ? (
                    <img
                      src={`${data?.file_path}?${Date.now()}`}
                      alt={data?.title}
                      className="uploaded-image"
                      style={{
                        width: "65px",
                        height: "35px",
                        borderRadius: "3px",
                        cursor: "pointer",
                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                      }}
                      onClick={() =>
                        handleImageClick(`${data?.file_path}?${Date.now()}`)
                      }
                    />
                  ) : (
                    getFileTypeImage(data.file_path)
                  )}
                </div>
                <div
                  className="d-flex justify-content-end"
                  style={{ flexBasis: "12%", flexShrink: 0 }}
                >
                  <CustomEditButton
                    id={data?.id}
                    style={{ marginRight: "-5px" }}
                    onClick={() => {
                      setTitle(data.title);
                      setActionId(data.id);
                      setEditButtonClicked(true);
                      handleEditAttachmentButtonClick();
                    }}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => handleDelete(data?.id)}
                  >
                    <Trash3 style={{ fill: "red" }}></Trash3>
                  </button>

                  {data.file_path.toLowerCase().endsWith(".pdf") ||
                  data.file_path.toLowerCase().endsWith(".csv") ||
                  data.file_path.toLowerCase().endsWith(".xlsx") ||
                  data.file_path.toLowerCase().endsWith(".doc") ||
                  data.file_path.toLowerCase().endsWith(".docx") ||
                  data.file_path.toLowerCase().endsWith(".xls") ||
                  data.file_path.toLowerCase().endsWith(".xlsx") ||
                  data.file_path.toLowerCase().endsWith(".ppt") ||
                  data.file_path.toLowerCase().endsWith(".pptx") ? (
                    <button className="btn btn-success btn-sm border-0 bg-transparent">
                      <a href={data.file_path} target="_blank" rel="noreferrer">
                        <Download style={{ fill: "green" }}></Download>
                      </a>
                    </button>
                  ) : (
                    <button
                      className="btn btn-success btn-sm border-0 bg-transparent"
                      onClick={() =>
                        handleDownload(data?.file_path, data?.title)
                      }
                    >
                      <Download style={{ fill: "green" }}></Download>
                    </button>
                  )}
                </div>
              </div>
              {showFullScreen && <FullScreenImage />}
            </span>
          ))}

        <ConfirmationModal
          show={showConfirmation}
          onHide={handleCancelDelete}
          title="Delete Attachment"
          message={`Are you sure you want to delete this attachment?`}
          onConfirm={handleConfirmDelete}
          confirmButtonColor="danger"
          spinnerStatus={customSubmitting ? customSubmitting : false}
        />
      </div>
    </div>
  );
}

export default AttachmentsForDocuments;
