import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/Address/List";
import AddUpdate from "../../Pages/Setting/Address/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_ORGANIZATION_ADDRESS,
  DELETE_ORGANIZATION_ADDRESS,
  RESTORE_ORGANIZATION_ADDRESS,
  UPDATE_ORGANIZATION_ADDRESS,
  VIEW_ORGANIZATION_ADDRESS,
} from "../../Util/PermissionList";

export const ADDRESS_TITLE = "Organization Address";
export const ADDRESS_LIST = "/organization-address";
export const ADDRESS_EDIT = "/organization-address/:id/edit";
export const ADDRESS_ADD = "/organization-address/add";
export const ADDRESS_ARCHIVE = "/organization-address/archive";

const Address: RouteObject[] = [
  {
    path: ADDRESS_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_ORGANIZATION_ADDRESS}
        checkPermisson={{
          viewPermission: VIEW_ORGANIZATION_ADDRESS,
          addPermission: ADD_ORGANIZATION_ADDRESS,
          updatePermission: UPDATE_ORGANIZATION_ADDRESS,
          deletePermission: DELETE_ORGANIZATION_ADDRESS,
          restorePermission: RESTORE_ORGANIZATION_ADDRESS,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: ADDRESS_EDIT,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate
            title={ADDRESS_TITLE}
            listRoute={ADDRESS_LIST}
            isNotUpdate={false}
          />
        }
        permission={UPDATE_ORGANIZATION_ADDRESS}
        checkPermisson={{
          viewPermission: VIEW_ORGANIZATION_ADDRESS,
          addPermission: ADD_ORGANIZATION_ADDRESS,
          updatePermission: UPDATE_ORGANIZATION_ADDRESS,
          deletePermission: DELETE_ORGANIZATION_ADDRESS,
          restorePermission: RESTORE_ORGANIZATION_ADDRESS,
        }}
      />
    ),
  },
  {
    path: ADDRESS_ADD,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate
            title={ADDRESS_TITLE}
            listRoute={ADDRESS_LIST}
            isNotUpdate={true}
          />
        }
        permission={ADD_ORGANIZATION_ADDRESS}
        checkPermisson={{
          viewPermission: VIEW_ORGANIZATION_ADDRESS,
          addPermission: ADD_ORGANIZATION_ADDRESS,
          updatePermission: UPDATE_ORGANIZATION_ADDRESS,
          deletePermission: DELETE_ORGANIZATION_ADDRESS,
          restorePermission: RESTORE_ORGANIZATION_ADDRESS,
        }}
      />
    ),
  },
  {
    path: ADDRESS_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          viewPermission: VIEW_ORGANIZATION_ADDRESS,
          addPermission: ADD_ORGANIZATION_ADDRESS,
          updatePermission: UPDATE_ORGANIZATION_ADDRESS,
          deletePermission: DELETE_ORGANIZATION_ADDRESS,
          restorePermission: RESTORE_ORGANIZATION_ADDRESS,
        }}
        permission={DELETE_ORGANIZATION_ADDRESS}
      />
    ),
  },
];

export default Address;
