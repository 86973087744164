import { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Permission } from "../../../Model/Setting/Permission";
import {
  PERMISSION_ADD,
  PERMISSION_EDIT,
  PERMISSION_TITLE,
} from "../../../Routes/Setting/Permission";
import { fetchData } from "../../../Util/OrionList";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomDataTable from "../../../Components/CustomDataTable";
import { setToast } from "../../../Util/Toast";
import { Col, Container, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import CustomButton from "../../../Components/Button/CustomButton";
import pluralize from "pluralize";

interface PermissionItem {
  id: number;
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
}

function List() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<PermissionItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(PERMISSION_EDIT.replace(":id", id.toString()));
  };

  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(PERMISSION_ADD);
  };

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  const columns: TableColumn<PermissionItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Name",
      cell: (row) => row.name,
    },
    {
      name: "Slug",
      cell: (row) => row.slug,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <CustomEditButton id={row.id} onClick={handleRowEditButtonClick} />
      ),
    },
  ];

  // @ts-ignore
  const fetchPermissions = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new Permission(),
        sortField,
        sortOrder,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchPermissions();
  }, [limit, page]); // eslint-disable-line
  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "name",
      value: "name",
      label: "Name",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "slug",
      value: "slug",
      label: "slug",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
  ];
  return (
    <>
      <Container>
        <div className="mb-4">
          <h2>{pluralize(PERMISSION_TITLE)} List</h2>
        </div>
        <Row>
          <CustomFilter
            options={filterOptions}
            inputValues={inputValues}
            setInputValues={setInputValues}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            getData={fetchPermissions}
            setPage={setPage}
          />
          <Col className="text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
            <CustomButton
              label={`Add`}
              variant="success"
              onClick={handleRowCreateButtonClick}
            />
          </Col>
        </Row>
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
        />
      </Container>
    </>
  );
}

export default List;
