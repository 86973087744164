import { Model } from "@tailflow/laravel-orion/lib/model";

export class AuditLogs extends Model<{
  user_type: string;
  user_id: number;
  event: string;
  auditable_type: string;
  auditable_id: number;
  old_values: string;
  new_values: string;
  url: string;
  ip_address: string;
  user_agent: string;
  tags: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  organization_id: number;
}> {
  public $resource(): string {
    return "audit-logs";
  }
}
