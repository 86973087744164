import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/TermsAndCondition/List";
import AddUpdate from "../../Pages/Setting/TermsAndCondition/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_TERMS_AND_CONDITION,
  DELETE_TERMS_AND_CONDITION,
  RESTORE_TERMS_AND_CONDITION,
  UPDATE_TERMS_AND_CONDITION,
  VIEW_TERMS_AND_CONDITION,
} from "../../Util/PermissionList";

export const TERMSANDCONDITION_TITLE = "Terms & Condition";
export const TERMSANDCONDITION_LIST = "/terms-and-conditions";
export const TERMSANDCONDITION_ADD = "/terms-and-conditions/add";
export const TERMSANDCONDITION_EDIT = "/terms-and-conditions/:id/edit";
export const TERMSANDCONDITION_ARCHIVE = "/terms-and-conditions/archive";

const TermsAndCondition: RouteObject[] = [
  {
    path: TERMSANDCONDITION_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_TERMS_AND_CONDITION}
        checkPermisson={{
          addPermission: ADD_TERMS_AND_CONDITION,
          updatePermission: UPDATE_TERMS_AND_CONDITION,
          deletePermission: DELETE_TERMS_AND_CONDITION,
          restorePermission: RESTORE_TERMS_AND_CONDITION,
          viewPermission: VIEW_TERMS_AND_CONDITION,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: TERMSANDCONDITION_ADD,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate
            title={TERMSANDCONDITION_TITLE}
            listRoute={TERMSANDCONDITION_LIST}
          />
        }
        checkPermisson={{
          addPermission: ADD_TERMS_AND_CONDITION,
          updatePermission: UPDATE_TERMS_AND_CONDITION,
          deletePermission: DELETE_TERMS_AND_CONDITION,
          restorePermission: RESTORE_TERMS_AND_CONDITION,
          viewPermission: VIEW_TERMS_AND_CONDITION,
        }}
        permission={ADD_TERMS_AND_CONDITION}
      />
    ),
  },
  {
    path: TERMSANDCONDITION_EDIT,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate
            title={TERMSANDCONDITION_TITLE}
            listRoute={TERMSANDCONDITION_LIST}
          />
        }
        checkPermisson={{
          addPermission: ADD_TERMS_AND_CONDITION,
          updatePermission: UPDATE_TERMS_AND_CONDITION,
          deletePermission: DELETE_TERMS_AND_CONDITION,
          restorePermission: RESTORE_TERMS_AND_CONDITION,
          viewPermission: VIEW_TERMS_AND_CONDITION,
        }}
        permission={UPDATE_TERMS_AND_CONDITION}
      />
    ),
  },
  {
    path: TERMSANDCONDITION_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        permission={DELETE_TERMS_AND_CONDITION}
        checkPermisson={{
          addPermission: ADD_TERMS_AND_CONDITION,
          updatePermission: UPDATE_TERMS_AND_CONDITION,
          deletePermission: DELETE_TERMS_AND_CONDITION,
          restorePermission: RESTORE_TERMS_AND_CONDITION,
          viewPermission: VIEW_TERMS_AND_CONDITION,
        }}
      />
    ),
  },
];

export default TermsAndCondition;
