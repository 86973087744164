import React, { useCallback, useEffect, useState } from "react";
import {
  SALES_RETURN_ADD,
  SALES_RETURN_ADD_BY_TAX_INVOICE,
  SALES_RETURN_EDIT,
  SALES_RETURN_TITLE,
  SALES_RETURN_VIEW,
} from "../../../Routes/SalesAndPurchase/SalesReturn";
import { Badge, Col, Row } from "react-bootstrap";
import CustomButton from "../../../Components/Button/CustomButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { SalesReturns } from "../../../Model/SalesAndPurchase/SalesReturn/SalesReturn";
import { setToast } from "../../../Util/Toast";
import { fetchData } from "../../../Util/OrionList";
import CustomDataTable from "../../../Components/CustomDataTable";
import { TableColumn } from "react-data-table-component";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomViewButton from "../../../Components/Button/CustomViewButton";
import CustomDownloadButton from "../../../Components/Button/CustomDownloadButton";
import { getCookie } from "typescript-cookie";
import { TAX_INVOICE_LIST } from "../../../Routes/SalesAndPurchase/TaxInvoice";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";

interface SalesReturnItem {
  id: number;
  title: string;
  description: string;
  status: string;
  days: number;
  delivery_date: string;
  created_at: string;
  document_number: string;
  transporter_name: string;
  payment_date: string;
}
const List = ({ permissions }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { tax_invoice_id, date } = useParams();
  // CustomFilter State
  const [inputValues, setInputValues] = useState<InputValues>(
    tax_invoice_id ? { tax_invoice_id: { value: tax_invoice_id } } : {},
  );
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Data State
  const [data, setData] = useState<SalesReturnItem[]>([]);

  // General State
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const [totalRows, setTotalRows] = useState(0);
  const [sortField] = useState<string>("id");
  const [sortOrder] = useState<string>("desc");

  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (tax_invoice_id) {
      navigate(
        SALES_RETURN_ADD_BY_TAX_INVOICE.replace(
          ":tax_invoice_id",
          tax_invoice_id,
        ),
      );
    } else {
      navigate(SALES_RETURN_ADD);
    }
  };

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "document_number",
      value: "document_number",
      label: "Document Number",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "status",
      value: "status",
      label: "Status",
      fieldType: "select",
      fieldOption: {
        All: "",
        Draft: "draft",
        Sent: "sent",
      },
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
    {
      id: "delivery_date",
      value: "delivery_date",
      label: "Delivery Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
  ];

  const handlePDFDownloadButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    try {
      const authToken = getCookie("authToken");
      const token = authToken ? authToken.replace(/["']/g, "") : "";
      const apiUrl = process.env.REACT_APP_API_URL + `api/sales-returns/pdf/`;
      const response = await fetch(apiUrl + id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        const anchor = document.createElement("a");
        anchor.href = responseData.data;
        anchor.download = "purchase order";
        anchor.rel = "noreferrer";
        anchor.target = "_blank";
        anchor.click();
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } catch (error) {
      setToast("error", "An error occurred while processing your request");
    }
  };

  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(SALES_RETURN_VIEW.replace(":id", id.toString()));
  };

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(SALES_RETURN_EDIT.replace(":id", id.toString()));
  };

  const columns: TableColumn<SalesReturnItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Document Number",
      cell: (row) => row.document_number,
    },
    {
      name: "Status",
      cell: (row) => (
        <Badge
          pill
          bg={row.status === "draft" ? "secondary" : "success"}
          style={{ padding: "7px 10px", fontSize: "smaller" }}
        >
          {CapitalizeAndRemoveUnderscore(row.status)}
        </Badge>
      ),
    },
    {
      name: "Delivery Date",
      cell: (row: any) => row.delivery_date,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Download",
      button: true,
      cell: (row: any) => (
        <div>
          <CustomDownloadButton
            id={row.id}
            onClick={handlePDFDownloadButtonClick}
          />
        </div>
      ),
    },
    {
      name: "Actions",
      button: true,
      wrap: true,
      cell: (row: any) => (
        <div className="d-flex align-items-center">
          {permissions.view && (
            <CustomViewButton
              id={row.id}
              onClick={(e) => {
                return handleRowViewButtonClick(e, row.id);
              }}
            />
          )}
          {permissions.update && (
            <CustomEditButton
              isDisabled={row.status === "sent" && true}
              id={row.id}
              onClick={handleRowEditButtonClick}
            />
          )}
        </div>
      ),
    },
  ];
  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };
  const GoBack = () => {
    navigate(TAX_INVOICE_LIST);
  };
  const handlePageChange = (page: any) => {
    setPage(page);
  };

  //   api modal function
  const fetchSalesReturn = useCallback(async () => {
    try {
      setLoading(true);
      const params = date ? new URLSearchParams(date) : null;
      const fromDate = params?.get("fromDate");

      const filters = {
        ...(fromDate
          ? { created_at: { filter_operation: ">=", value: fromDate } }
          : {}),
        ...(inputValues ? inputValues : {}),
      };

      await fetchData(
        page,
        limit,
        filters,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new SalesReturns(),
        sortField,
        sortOrder,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, page, limit, inputValues, sortField, sortOrder, navigate]);
  useEffect(() => {
    fetchSalesReturn();
  }, [fetchSalesReturn]);

  useEffect(() => {
    fetchSalesReturn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);
  return (
    <>
      <div className="mb-4">
        <h2>{SALES_RETURN_TITLE} List</h2>
      </div>
      <Row>
        <CustomFilter
          options={filterOptions}
          inputValues={inputValues}
          setInputValues={setInputValues}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          getData={fetchSalesReturn}
          setPage={setPage}
        />
        <Col className="text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {tax_invoice_id && (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={GoBack}
            >
              Back
            </button>
          )}

          {permissions.add && (
            <CustomButton
              label={`Add`}
              variant="success"
              onClick={handleRowCreateButtonClick}
            />
          )}
        </Col>
      </Row>
      <div className="mb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"SalesReturns"} // Pass optional fields to CustomDataTable
        />
      </div>
    </>
  );
};

export default List;
