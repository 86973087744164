import { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RolePermission } from "../../../Model/Setting/RolePermission";
import {
  ROLE_PERMISSION_ADD,
  ROLE_PERMISSION_EDIT,
} from "../../../Routes/Setting/RolePermission";
import { fetchData } from "../../../Util/OrionList";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomDataTable from "../../../Components/CustomDataTable";
import { setToast } from "../../../Util/Toast";

interface RolePermissionItem {
  id: number;
  resources: Array<number>;
  created_at: string;
  updated_at: string;
}

function List() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<RolePermissionItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues] = useState({});
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(ROLE_PERMISSION_EDIT.replace(":id", id.toString()));
  };

  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(ROLE_PERMISSION_ADD);
  };

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  const columns: TableColumn<RolePermissionItem>[] = [
    {
      name: "id",
      cell: (row) => row.id,
    },
    {
      name: "Name",
      cell: (row) => row.resources.toString(),
    },
    {
      name: "created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <CustomEditButton id={row.id} onClick={handleRowEditButtonClick} />
      ),
    },
  ];

  // @ts-ignore
  const fetchRolePermission = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new RolePermission(),
        sortField,
        sortOrder,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchRolePermission();
  }, [limit, page]); // eslint-disable-line

  return (
    <>
      <CustomDataTable
        columns={columns}
        data={data}
        progressPending={loading}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        handleRowCreateButtonClick={handleRowCreateButtonClick}
        currentPages={page}
        currentRowsPerPage={limit}
      />
    </>
  );
}

export default List;
