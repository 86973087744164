import { RouteObject } from "react-router-dom";
import OrganizationPage from "../../Pages/Organization/Organization";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import { VIEW_ORGANIZATION } from "../../Util/PermissionList";

export const ORGANIZATION_TITLE = "Our Organization";
export const ORGANIZATION_VIEW = "/our-organization";

const Organization: RouteObject[] = [
  {
    path: ORGANIZATION_VIEW,
    element: (
      <AuthorizedRoute
        permission={VIEW_ORGANIZATION}
        element={<OrganizationPage />}
      />
    ),
  },
];

export default Organization;
