import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LineController,
  BarController,
} from "chart.js";
import { useApi } from "../../Controller/ApiController";
import {
  startOfMonth,
  endOfMonth,
  format,
  startOfWeek,
  endOfWeek,
  subWeeks,
  subDays,
  startOfYear,
  endOfYear,
} from "date-fns";
import Select from "react-select";
import { Modal, Button, Table, Card } from "react-bootstrap";
import { CapitalizeAndRemoveUnderscore } from "../../Util/CapitalizeAndRemoveUnderscore";
// import { CustomView } from "../../Routes/Setting/CustomViewComponentRoute";
// import "chartjs-adapter-date-fns";
import { setToast } from "../../../src/Util/Toast";
import { PURCHASE_VIEW } from "../../Routes/SalesAndPurchase/Purchase";

ChartJS.register(
  BarController, // Register BarController
  LineController, // Register LineController
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
);

const PurchaseChart = () => {
  const [purchaseData, setPurchaseData] = useState<any>(null);
  const [productChartData, setProductChartData] = useState<any>(null);
  const [countryChartData, setCountryChartData] = useState<any>(null);
  const [reportType, setReportType] = useState<any>({
    label: "Day",
    value: "day",
  });
  const [reportDate, setReportDate] = useState<any>({
    label: "Current week",
    value: "current_week",
  });

  const getInitialDates = (type: any) => {
    const today = new Date();
    if (type === "day") {
      return {
        fromDate: format(startOfWeek(today, { weekStartsOn: 1 }), "yyyy-MM-dd"),
        toDate: format(endOfWeek(today, { weekStartsOn: 1 }), "yyyy-MM-dd"),
      };
    } else if (type === "month") {
      return {
        fromDate: format(startOfMonth(today), "yyyy-MM-dd"),
        toDate: format(endOfMonth(today), "yyyy-MM-dd"),
      };
    }
  };

  // Use the function to initialize state
  const initialDates: any = getInitialDates(reportType.value);

  const [fromDate, setFromDate] = useState(initialDates.fromDate);
  const [toDate, setToDate] = useState(initialDates.toDate);

  const [selectedFromDate, setSelectedFromDate] = useState<any>(initialDates);
  const [selectedToDate, setSelectedToDate] = useState<any>(null);
  const [status, setStatus] = useState<any>({ label: "All", value: "" });
  const [modelData, setModelData] = useState<any>([]);
  const [allPurchaseData, setAllPurchaseData] = useState<any>([]);
  const [allProductPurchaseData, setProductAllPurchaseData] = useState<any>([]);
  const [allCountryPurchaseData, setCountryAllPurchaseData] = useState<any>([]);
  const [modalShow, setModalShow] = useState(false);
  const { apiGet } = useApi();

  useEffect(() => {
    const fetchPurchaseData = async () => {
      try {
        let reportTypeValue = reportType.value === "month" ? "month" : "day";

        let url = `report/purchase-orders?is_report=${reportTypeValue}&from_date=${fromDate}&to_date=${toDate}`;
        if (status.value) {
          url += `&status=${status.value}`;
        }

        const item: any = await apiGet(url);
        const allPurchase = item.flatMap(
          (data: any) => data?.purchase_orders || [],
        );

        setAllPurchaseData(allPurchase);

        if (reportType.value === "month") {
          const labels = item.map((data: any) => `${data.year}-${data.month}`);
          const values = item.map((data: any) => parseFloat(data.total_amount));

          setPurchaseData({
            labels,
            datasets: [
              {
                type: "bar",
                label: "Total Amount",
                data: values,
                purchaseData: item,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
              {
                type: "line",
                label: "Total Amount Trend",
                data: values,
                purchaseData: item,
                backgroundColor: "rgba(153, 102, 255, 0.2)",
                borderColor: "rgba(153, 102, 255, 1)",
                borderWidth: 2,
                fill: false,
              },
            ],
          });
        } else {
          const labels = item.map((data: any) => data.date);
          const values = item.map((data: any) => parseFloat(data.total_amount));
          setPurchaseData({
            labels,
            datasets: [
              {
                type: "bar",
                label: "Total Amount",
                data: values,
                purchaseData: item,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
              {
                type: "line",
                label: "Total Amount Trend",
                data: values,
                purchaseData: item,
                backgroundColor: "rgba(153, 102, 255, 0.2)",
                borderColor: "rgba(153, 102, 255, 1)",
                borderWidth: 2,
                fill: false,
              },
            ],
          });
        }
      } catch (error) {
        setToast("error", "Error fetching Purchase data");
      }
    };
    fetchPurchaseData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, status]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        let url = `report/purchase_order_by_product?from_date=${fromDate}&to_date=${toDate}`;
        if (status.value) {
          url += `&status=${status.value}`;
        }
        const item: any = await apiGet(url);

        let allPurchase: any[] =
          item?.products?.flatMap(
            (data: any) =>
              data?.purchase_order?.map((po: any) => {
                // Calculate product_total_amount by matching product_id and summing total_amount + total_tax
                const productTotalAmount = po.items
                  ?.filter((item: any) => item.products_id === data.id)
                  ?.reduce(
                    (acc: number, curr: any) =>
                      acc +
                      parseFloat(curr.total_price || 0) +
                      parseFloat(curr.total_tax || 0),
                    0,
                  );

                return {
                  ...po,
                  product_name: data.product_name,
                  product_total_amount: productTotalAmount || 0,
                };
              }) || [],
          ) || [];

        setProductAllPurchaseData(allPurchase);

        const labels = item?.products?.map(
          (product: any) => product.product_name,
        );
        const data = item?.products?.map(
          (product: any) => product.total_amount,
        );

        setProductChartData({
          labels,
          datasets: [
            {
              type: "bar",
              label: "Total Amount (Bar)",
              data,
              productData: item,
              backgroundColor: "rgba(153, 102, 255, 0.2)",
              borderColor: "rgba(153, 102, 255, 1)",
              borderWidth: 1,
            },
            {
              type: "line",
              label: "Total Amount Trend (Line)",
              data,
              productData: item,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 2,
              fill: false,
            },
          ],
        });
      } catch (error) {
        setToast("error", "Error fetching product data");
        // setProductChartData([])
      }
    };
    fetchProductData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, status]);

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        let url = `report/purchase_order_by_country?from_date=${fromDate}&to_date=${toDate}`;
        if (status.value) {
          url += `&status=${status.value}`;
        }
        const item: any = await apiGet(url);

        let allPurchase: any[] =
          item?.countries?.flatMap(
            (data: any) =>
              data?.purchase_order?.map((po: any) => {
                // Calculate product_total_amount by matching product_id and summing total_amount + total_tax
                return {
                  ...po,
                  country_name: data.name,
                };
              }) || [],
          ) || [];

        setCountryAllPurchaseData(allPurchase);

        const labels = item?.countries?.map((country: any) => country.name);
        const data = item?.countries?.map(
          (country: any) => country.total_amount,
        );

        setCountryChartData({
          labels,
          datasets: [
            {
              type: "bar",
              label: "Total Amount (Bar)",
              data,
              countryData: item,
              backgroundColor: "rgba(153, 102, 255, 0.2)",
              borderColor: "rgba(153, 102, 255, 1)",
              borderWidth: 1,
            },
            {
              type: "line",
              label: "Total Amount Trend (Line)",
              data,
              countryData: item,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 2,
              fill: false,
            },
          ],
        });
      } catch (error) {
        setToast("error", "Error fetching country data");
      }
    };
    fetchCountryData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, status]);

  // ... (rest of your component remains the same)

  const handleChartClick = (
    _event: any,
    elements: any[],
    chartType: string,
  ) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      if (chartType === "purchase") {
        let data = purchaseData.datasets[0].purchaseData[index].purchase_orders;
        setModelData({ data, type: "purchase" });
      } else if (chartType === "country") {
        let data =
          countryChartData.datasets[0].countryData.countries[index]
            .purchase_order;
        setModelData({ data, type: "country" });
      } else if (chartType === "product") {
        let productPurchaseData: any[] = [];
        productChartData.datasets[0].productData.products[
          index
        ].purchase_order.forEach((data: any) => {
          // Calculate product_total_amount for matched products_id
          let product_total_amount = data.items.reduce(
            (sum: number, item: any) => {
              if (
                item.products_id ===
                productChartData.datasets[0].productData.products[index].id
              ) {
                const totalPrice = parseFloat(item.total_price);
                const totalTax = parseFloat(item.total_tax) || 0;
                return sum + (totalPrice + totalTax);
              }
              return sum;
            },
            0,
          );

          data.product_total_amount = product_total_amount;

          productPurchaseData.push(data);
        });
        setModelData({ data: productPurchaseData, type: "product" });
      }
      setModalShow(true);
    }
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const navigateToViewPage: any = (id: any) => {
    let url = PURCHASE_VIEW.replace(":id", id.toString());
    window.open(url, "_blank");
  };

  const convertToCSV = (
    data: any[],
    forProduct?: boolean,
    forCountry?: boolean,
  ) => {
    const headers = [
      "Title",
      "Status",
      "Document Number",
      "Document Date",
      "Buyer",
      "Supplier",
      "Delivery Date",
      "Currency",
      "Total Tax",
      "PurchaseTotal Amount",
      "Kind Attention",
      "Comments",
      "Created Date",
    ];
    if (forProduct) {
      headers.splice(1, 0, "Product Name");
    }
    if (forCountry) {
      headers.splice(1, 0, "Country Name");
    }

    const rows = data?.map((item) => {
      const row = [
        item.title,
        item.status,
        item.document_number,
        item.document_date,
        item.buyer_billing_address?.organization?.company_name || "",
        item.supplier_billing_address?.counter_party_company?.company_name ||
          "",
        item.delivery_date,
        item.currency.symbol,
        item.total_tax,
        item.total_amount,
        item.kind_attention,
        item.comments,
        item.created_at,
      ];

      if (forProduct) {
        row.splice(1, 0, item.product_name || ""); // assuming item.product_name holds the product name
      }

      if (forCountry) {
        row.splice(1, 0, item.country_name || ""); // assuming item.product_name holds the product name
      }

      return row;
    });
    const csvContent = [headers, ...rows]
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");

    // Add BOM to the beginning of the CSV content
    const bom = "\uFEFF";
    const csvContentWithBOM = bom + csvContent;

    return csvContentWithBOM;
  };

  const handleExportCSV = (
    allPurchaseData: any,
    forProduct?: boolean,
    forCountry?: boolean,
  ) => {
    const csvData = allPurchaseData
      ? convertToCSV(allPurchaseData, forProduct, forCountry)
      : convertToCSV(modelData.data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    if (forProduct) {
      link.setAttribute(
        "download",
        `product_purchase_data_${new Date().toISOString()}.csv`,
      );
    } else if (forCountry) {
      link.setAttribute(
        "download",
        `country_purchase_data_${new Date().toISOString()}.csv`,
      );
    } else {
      link.setAttribute(
        "download",
        `purchase_data_${new Date().toISOString()}.csv`,
      );
    }
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const calculateDateRange = () => {
      let newFromDate;
      let newToDate = format(new Date(), "yyyy-MM-dd");
      switch (reportDate.value) {
        case "current_week":
          newFromDate = format(
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            "yyyy-MM-dd",
          );
          newToDate = format(
            endOfWeek(new Date(), { weekStartsOn: 1 }),
            "yyyy-MM-dd",
          );
          break;
        case "last_week":
          newFromDate = format(
            startOfWeek(subWeeks(new Date(), 1)),
            "yyyy-MM-dd",
          );
          newToDate = format(endOfWeek(subWeeks(new Date(), 1)), "yyyy-MM-dd");
          break;
        case "current_month":
          newFromDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
          newToDate = format(endOfMonth(new Date()), "yyyy-MM-dd");
          break;
        case "last_month":
          newFromDate = format(
            startOfMonth(subWeeks(new Date(), 4)),
            "yyyy-MM-dd",
          );
          newToDate = format(endOfMonth(subWeeks(new Date(), 4)), "yyyy-MM-dd");
          break;
        case "last_30_days":
          newFromDate = format(subDays(new Date(), 30), "yyyy-MM-dd");
          break;
        case "current_year":
          newFromDate = format(startOfYear(new Date()), "yyyy-MM-dd");
          newToDate = format(endOfYear(new Date()), "yyyy-MM-dd");
          setReportType({
            label: "Month",
            value: "month",
          });
          break;

        default:
          newFromDate = format(
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            "yyyy-MM-dd",
          );
          newToDate = format(
            endOfWeek(new Date(), { weekStartsOn: 1 }),
            "yyyy-MM-dd",
          );
      }
      setFromDate(newFromDate);
      setToDate(newToDate);
    };

    calculateDateRange();
  }, [reportDate]);

  const reportTypeChange = (report_type: any) => {
    setReportType(report_type);
    if (report_type.value === "month") {
      setReportDate({ label: "Current month", value: "current_month" });
    } else {
      setReportDate({ label: "Current week", value: "current_week" });
    }
  };

  const reportDateOptions =
    reportType.value === "day"
      ? [
          { label: "Current week", value: "current_week" },
          { label: "Last week", value: "last_week" },
          { label: "Current month", value: "current_month" },
          { label: "Last month", value: "last_month" },
          { label: "Last 30 days", value: "last_30_days" },
          { label: "Current year", value: "current_year" },
          { label: "Custom Date Range", value: "custom_date_range" },
        ]
      : [
          { label: "Current month", value: "current_month" },
          { label: "Last month", value: "last_month" },
          { label: "Current year", value: "current_year" },
          { label: "Custom Month Range", value: "custom_date_range" },
        ];

  return (
    <div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <div style={{ marginRight: "10px" }}>
          <label>Report Type: </label>
          <Select
            options={[
              { label: "Day", value: "day" },
              { label: "Month", value: "month" },
            ]}
            placeholder="Select options..."
            onChange={(selectedOption: any) => reportTypeChange(selectedOption)}
            value={reportType}
            className="p-0"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minWidth: "120px",
              }),
            }}
          />
        </div>
        <div style={{ marginRight: "10px" }}>
          <label>Report Date: </label>
          <Select
            options={reportDateOptions}
            value={reportDate}
            placeholder="Select options..."
            onChange={(selectedOption: any) => setReportDate(selectedOption)}
            className="p-0"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minWidth: "120px",
              }),
            }}
          />
        </div>
        {reportDate.value === "custom_date_range" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <label>From: </label>
              <input
                className="form-control"
                type={reportType.value === "day" ? "date" : "month"}
                value={selectedFromDate}
                onChange={(e) => {
                  const selectedValue: any = e.target.value;
                  setSelectedFromDate(selectedValue);
                  if (reportType.value === "month") {
                    const startOfMonthDate = format(
                      startOfMonth(new Date(selectedValue)),
                      "yyyy-MM-dd",
                    );
                    setFromDate(startOfMonthDate);
                  } else {
                    setFromDate(selectedValue);
                  }
                }}
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
            <div>
              <label>To: </label>
              {/* <input
                className="form-control"
                // type="date"
                type={reportType.value === "day" ? "date" : "month"}
                value={toDate}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => setToDate(e.target.value)}
              /> */}
              <input
                className="form-control"
                type={reportType.value === "day" ? "date" : "month"}
                value={selectedToDate}
                onChange={(e) => {
                  const selectedValue: any = e.target.value;
                  setSelectedToDate(selectedValue);
                  if (reportType.value === "month") {
                    const endOfMonthDate = format(
                      endOfMonth(new Date(selectedValue)),
                      "yyyy-MM-dd",
                    );
                    setToDate(endOfMonthDate);
                  } else {
                    setToDate(selectedValue);
                  }
                }}
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
          </div>
        )}

        <div>
          <label style={{ marginRight: "10px" }}>Status: </label>
          <Select
            options={[
              { label: "All", value: "" },
              { label: "Pending", value: "pending" },
              { label: "Delivered", value: "delivered" },
            ]}
            placeholder="Select options..."
            onChange={(selectedOption: any) => setStatus(selectedOption)}
            value={status}
            className="p-0"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minWidth: "120px",
              }),
            }}
          />
        </div>
      </div>
      {purchaseData ? (
        <div className="d-flex" style={{ height: "67vh" }}>
          <div style={{ width: "50%" }}>
            <h4 className="">Purchase Report</h4>
            <Bar
              data={purchaseData}
              options={{
                maintainAspectRatio: false,
                onClick: (event, elements) =>
                  handleChartClick(event, elements, "purchase"),
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: reportType.value === "month" ? "Month" : "Date",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "Total Amount",
                    },
                  },
                },
              }}
            />
          </div>

          <div style={{ width: "50%", marginLeft: "25px" }}>
            <Card style={{ maxHeight: "100%" }}>
              <Card.Header className="d-flex">
                <h5>Purchase Details</h5>
                {allPurchaseData?.length > 0 && (
                  <div className="ms-2">
                    <Button
                      onClick={() => {
                        handleExportCSV(allPurchaseData);
                      }}
                      variant="primary"
                      size="sm"
                    >
                      Export CSV
                    </Button>
                  </div>
                )}
              </Card.Header>
              <Card.Body style={{ overflowY: "scroll" }}>
                <Table striped bordered size={"sm"}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Document Number</th>
                      <th>Document Date</th>
                      <th>Status</th>
                      <th>Buyer</th>
                      <th>Supplier</th>
                      <th>Delivery Date</th>
                      {modelData.type === "product" && <th>Product Amount</th>}
                      <th>PurchaseTotal Amount</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPurchaseData?.length === 0 ? (
                      <tr>
                        <td
                          colSpan={
                            modelData.type === "product" ||
                            modelData.type === "country"
                              ? 10
                              : 9
                          }
                          className="text-center"
                          style={{ height: "200px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            There are no records to display
                          </div>
                        </td>
                      </tr>
                    ) : (
                      allPurchaseData?.map((item: any, index: any) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.document_number}</td>
                          <td>{item.document_date}</td>
                          <td>{CapitalizeAndRemoveUnderscore(item.status)}</td>
                          <td>
                            {item.buyer_billing_address?.organization
                              ?.company_name || ""}
                          </td>
                          <td>
                            {item.supplier_billing_address
                              ?.counter_party_company?.company_name || ""}
                          </td>
                          <td>{item.delivery_date}</td>
                          {modelData.type === "product" && (
                            <td>{item.product_total_amount?.toFixed(2)}</td>
                          )}
                          <td>{item.total_amount}</td>
                          <td
                            onClick={() => {
                              navigateToViewPage(item.id);
                            }}
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            View
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </div>
      ) : (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}

      {productChartData ? (
        <div className="d-flex" style={{ height: "67vh", marginTop: "40px" }}>
          <div style={{ width: "50%" }}>
            <h4 className="">Purchase By Products</h4>
            <Bar
              data={productChartData}
              options={{
                maintainAspectRatio: false,
                onClick: (event, elements) =>
                  handleChartClick(event, elements, "product"),
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Product",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "Total Amount",
                    },
                  },
                },
              }}
            />
          </div>
          <div style={{ height: "67vh", width: "50%", marginLeft: "25px" }}>
            <Card style={{ maxHeight: "100%" }}>
              <Card.Header className="d-flex">
                <h5>Product Purchase Details</h5>
                {allProductPurchaseData?.length > 0 && (
                  <div className="ms-2">
                    <Button
                      onClick={() => {
                        handleExportCSV(allProductPurchaseData, true);
                      }}
                      variant="primary"
                      size="sm"
                    >
                      Export CSV
                    </Button>
                  </div>
                )}
              </Card.Header>
              <Card.Body style={{ overflowY: "scroll" }}>
                <Table striped bordered size={"sm"}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product Name</th>
                      <th>Document Number</th>
                      <th>Document Date</th>
                      <th>Status</th>
                      <th>Buyer</th>
                      <th>Supplier</th>
                      <th>Delivery Date</th>
                      <th>Product Amount</th>
                      <th>PurchaseTotal Amount</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allProductPurchaseData?.length === 0 ? (
                      <tr>
                        <td
                          colSpan={modelData.type === "product" ? 12 : 11}
                          className="text-center"
                          style={{ height: "200px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            There are no records to display
                          </div>
                        </td>
                      </tr>
                    ) : (
                      allProductPurchaseData?.map((item: any, index: any) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.product_name}</td>
                          <td>{item.document_number}</td>
                          <td>{item.document_date}</td>
                          <td>{CapitalizeAndRemoveUnderscore(item.status)}</td>
                          <td>
                            {item.buyer_billing_address?.organization
                              ?.company_name || ""}
                          </td>
                          <td>
                            {item.supplier_billing_address
                              ?.counter_party_company?.company_name || ""}
                          </td>
                          <td>{item.delivery_date}</td>
                          <td>{item.product_total_amount?.toFixed(2)}</td>
                          <td>{item.total_amount}</td>
                          <td
                            onClick={() => {
                              navigateToViewPage(item.id);
                            }}
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            View
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </div>
      ) : (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}

      {countryChartData ? (
        <div className="d-flex" style={{ height: "67vh", marginTop: "40px" }}>
          <div style={{ width: "50%" }}>
            <h4 className="">Purchase By Country</h4>
            <Bar
              data={countryChartData}
              options={{
                maintainAspectRatio: false,
                onClick: (event, elements) =>
                  handleChartClick(event, elements, "country"),
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Country",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "Total Amount",
                    },
                  },
                },
              }}
            />
          </div>
          <div style={{ height: "67vh", width: "50%", marginLeft: "25px" }}>
            <Card style={{ maxHeight: "100%" }}>
              <Card.Header className="d-flex">
                <h5>Country Purchase Details</h5>
                {allCountryPurchaseData?.length > 0 && (
                  <div className="ms-2">
                    <Button
                      onClick={() => {
                        handleExportCSV(allCountryPurchaseData, false, true);
                      }}
                      variant="primary"
                      size="sm"
                    >
                      Export CSV
                    </Button>
                  </div>
                )}
              </Card.Header>
              <Card.Body style={{ overflowY: "scroll" }}>
                <Table striped bordered size={"sm"}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Country Name</th>
                      <th>Document Number</th>
                      <th>Document Date</th>
                      <th>Status</th>
                      <th>Buyer</th>
                      <th>Supplier</th>
                      <th>Delivery Date</th>
                      <th>PurchaseTotal Amount</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCountryPurchaseData?.length === 0 ? (
                      <tr>
                        <td
                          colSpan={modelData.type === "country" ? 11 : 10}
                          className="text-center"
                          style={{ height: "200px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            There are no records to display
                          </div>
                        </td>
                      </tr>
                    ) : (
                      allCountryPurchaseData?.map((item: any, index: any) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.country_name}</td>
                          <td>{item.document_number}</td>
                          <td>{item.document_date}</td>
                          <td>{CapitalizeAndRemoveUnderscore(item.status)}</td>
                          <td>
                            {item.buyer_billing_address?.organization
                              ?.company_name || ""}
                          </td>
                          <td>
                            {item.supplier_billing_address
                              ?.counter_party_company?.company_name || ""}
                          </td>
                          <td>{item.delivery_date}</td>
                          <td>{item.total_amount}</td>
                          <td
                            onClick={() => {
                              navigateToViewPage(item.id);
                            }}
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            View
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </div>
      ) : (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}

      <Modal show={modalShow} onHide={closeModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="d-flex">
            {modelData.type === "product"
              ? "Product Purchase Details"
              : modelData.type === "country"
              ? "Country Purchase Details"
              : "Purchase Details"}
            {modelData?.data?.length > 0 && (
              <div className="ms-2">
                <Button
                  onClick={() => {
                    handleExportCSV(
                      null,
                      modelData.type === "product",
                      modelData.type === "country",
                    );
                  }}
                  variant="primary"
                  size="sm"
                >
                  Export CSV
                </Button>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "scroll" }}>
          {modelData?.data?.length > 0 ? (
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Document Number</th>
                  <th>Document Date</th>
                  <th>Status</th>
                  <th>Buyer</th>
                  <th>Supplier</th>
                  <th>Delivery Date</th>
                  {modelData.type === "product" && <th>Product Amount</th>}
                  <th>PurchaseTotal Amount</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {modelData?.data?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>{item.title}</td>
                    <td>{item.document_number}</td>
                    <td>{item.document_date}</td>
                    <td>{CapitalizeAndRemoveUnderscore(item.status)}</td>
                    <td>
                      {item.buyer_billing_address?.organization?.company_name ||
                        ""}
                    </td>
                    <td>
                      {item.supplier_billing_address?.counter_party_company
                        ?.company_name || ""}
                    </td>
                    <td>{item.delivery_date}</td>
                    {modelData.type === "product" && (
                      <td>{item.product_total_amount.toFixed(2)}</td>
                    )}
                    <td>{item.total_amount}</td>
                    <td
                      onClick={() => {
                        navigateToViewPage(item.id);
                      }}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      View
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div
              style={{ textAlign: "center", color: "gray", marginTop: "20px" }}
            >
              <p>No data available</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PurchaseChart;
