import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate } from "react-router-dom";
import { setToast } from "../Util/Toast";
import Form from "react-bootstrap/Form";
import CustomAsyncSelect from "./AsyncSelect";
import CustomButton from "./Button/CustomButton";
import ErrorHandler from "./ErrorHandler/ErrorHandler";
import { Alert, Button } from "react-bootstrap";
import { DynamicFormFields } from "../Util/DynamicFormFields";
import { useApi } from "../Controller/ApiController";
import { AttributesOption } from "../Model/Setting/AttributesOption";
import Notes from "./Notes";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { DateFormat } from "../Util/DateFormat";
import ActivityLogs from "./ActivityLogs";
import { AuditLogModule } from "../Util/AuditLogModuleList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDateFormat } from "../Util/InputDateFormate";
import ConfirmationModal from "./ConfirmationModel";
import { Download } from "react-bootstrap-icons";
import FormSkeleton from "./FormSkeleton";
import moment from "moment";
import { CapitalizeAndRemoveUnderscore } from "../Util/CapitalizeAndRemoveUnderscore";

interface CustomButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label?: any;
  variant?: string;
  style?: React.CSSProperties;
  width?: string;
  size?: any;
  type?: "button" | "submit" | "reset" | undefined | string;
  customeClass?: string;
  buttonDivClass?: string;
}
interface Field {
  id: string;
  label: string;
  includeWith?: any;
  type: string;
  col: number;
  name: string;
  placeholder?: string;
  isMultiSelect?: boolean;
  validate?: { rule: (value: any) => boolean; message: string }[];
  options?: any[];
  searchField?: any;
  model?: any;
  customFilter?: any;
  disabled?: boolean;
  isNeedFullData?: boolean;
  value?: any;
  required?: any;
  submitButtonLabel?: any;
  button?: CustomButtonProps | CustomButtonProps[];
  addNewBtnStatus?: boolean;
  confirmation?: boolean;
  widget?: any;
  modalStatus?: boolean;
  onChangeModalStatus?: (status: boolean) => void;
}

interface AddUpdateFormProps {
  formFields: Field[];
  refreshKey?: any;
  model: any;
  title: string;
  customTitle?: string;
  customFormSubmit?: (formData: any) => void;
  dynamicFormData?: any;
  setDynamicFormData?: any;
  isBackBtnDisable?: boolean;
  listRoute?: string;
  onOptionChange?: any;
  inputVisibility?: any;
  dynamicFormError?: any;
  // onModalHide?: any;
  entityName?: any;
  entityType?: any;
  isDynamicFormFields?: any;
  autoUpdateField?: any;
  submitButtonLabel?: any;
  isNotUpdate?: any;
  isUpdate?: any;
  dynamicId?: any;
  customSubmitting?: any;
  disableSubmitButton?: any;
  companyTagsCustomStyle?: any;
  customGoBack?: any;
  loadingStatus?: any;
  CustomFormSkeleton?: any;
  onChangeValue?: (formData: any) => void;
  onChangeModalStatus?: (status: boolean) => void;
}

function transformLabel(label: string) {
  const words = label.replace(/_/g, " ").split(" ");

  const transformedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1),
  );

  const transformedLabel = transformedWords.join(" ");

  return transformedLabel;
}

const AddUpdateForm: React.FC<AddUpdateFormProps> = ({
  formFields,
  refreshKey,
  model,
  title,
  customTitle,
  customFormSubmit,
  dynamicFormData,
  setDynamicFormData,
  isBackBtnDisable,
  listRoute,
  onOptionChange,
  dynamicFormError,
  // onModalHide,
  entityName,
  entityType,
  isDynamicFormFields,
  autoUpdateField,
  companyTagsCustomStyle,
  submitButtonLabel,
  isNotUpdate,
  isUpdate,
  onChangeValue,
  dynamicId,
  customGoBack,
  customSubmitting,
  disableSubmitButton,
  loadingStatus,
  CustomFormSkeleton,
  onChangeModalStatus,
}) => {
  const [formData, setFormData] = useState<any>({});
  const [modelName, setModelName] = useState("");
  const [formError, setFormError] = useState<string | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationItem, setConfirmationItem] = useState<any>({});
  const dateFormat: any = useDateFormat();
  const formRef = useRef<HTMLFormElement>(null);

  const [formValidationErrors, setFormValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  let { id }: any = useParams();
  if (isNotUpdate) {
    id = null;
  }
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState({
    code: "",
    message: "",
  });
  const [errorStatusCode, setErrorStatusCode] = useState<number>();
  const { apiGet } = useApi();
  const [options, setOptions] = useState(formData.options || ["", ""]);

  const getFormData = async (itemId: string, customeModel?: any) => {
    if (customeModel) {
      const item = await customeModel.$query().find(itemId);
      return item.$attributes;
    } else {
      const item = await model.$query().find(itemId);
      return item.$attributes;
    }
  };

  let RJSFtitle = "Add " + title;

  if (id || isUpdate) {
    RJSFtitle = "Edit " + title;
  }

  if (customTitle) {
    RJSFtitle = customTitle;
  }

  let noteModules = [
    "audits",
    "bank-details",
    "designations",
    "logistic-details",
    "payment-terms",
    "production-process-types",
    "terms-and-conditions",
    "user-types",
    "bill-of-materials",
    "sales-quotations",
    "purchase-orders",
    // "organizations",
    // "approvals",
    // "attributes",
    // "products",
    // "categories",
    // "document-number-formats",
    // "materials",
    // "stores",
    // "taxes",
    // "users",
  ];
  const getDefaultTab = (modelName: any) => {
    if (noteModules.includes(modelName)) {
      return "notes";
    } else {
      return "activityLogs";
    }
  };
  useEffect(() => {
    const defaultTab = getDefaultTab(modelName);
    setActiveTab(defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelName]);

  const initialTab = getDefaultTab(modelName);
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    if (refreshKey) {
      renderFormFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  useEffect(() => {
    const modelName = new model().$resource();
    setModelName(modelName);
    formFields.forEach((field) => {
      if (field.validate && field.validate.length > 0) {
        const isRequired = field.validate.some((validation) =>
          validation.rule("") ? false : true,
        );
        if (isRequired) {
          field.label = `${field.label}`;
        }
      }
    });

    if (dynamicFormData && !isNotUpdate) {
      let inputData = Object.assign({}, dynamicFormData);
      setFormData(dynamicFormData);
      dynamicFormData?.options && setOptions(dynamicFormData?.options);

      if (
        inputData.type?.value === "select" ||
        inputData.type?.value === "multiselect"
      ) {
        inputData.options = inputData.options?.length > 0 && inputData.options;
        setOptions(inputData.options || ["", ""]);
      }
    } else {
      const fetchData = async () => {
        if (id) {
          try {
            setLoading(true);
            const itemData = await getFormData(id);
            if (isDynamicFormFields === true) {
              const dynamicAttributeValues = await apiGet(
                `${entityName}/${id}/attributes-values`,
              );

              const getDynamicFormFields = await DynamicFormFields(
                formFields,
                entityType,
              );

              await Promise.all(
                getDynamicFormFields?.map(async (dynamicField: any) => {
                  const matchingValue = dynamicAttributeValues.data.find(
                    (value: any) =>
                      value.attribute_id === dynamicField.attribute_id,
                  );

                  let value;
                  if (matchingValue) {
                    if (dynamicField.type === "select") {
                      const selectedOption = dynamicField.options
                        ? dynamicField.options.find(
                            (option: any) =>
                              option.value === matchingValue.integer_value,
                          )
                        : matchingValue.integer_value;

                      value = {
                        label: selectedOption
                          ? selectedOption.label
                          : matchingValue.integer_value,
                        value: matchingValue.integer_value,
                      };
                    } else if (dynamicField.type === "lookup") {
                      const selectedOption = matchingValue.integer_value;

                      const res = await getFormData(
                        selectedOption,
                        dynamicField.model,
                      );
                      value = {
                        label: CapitalizeAndRemoveUnderscore(
                          res[dynamicField.searchField],
                        ),
                        value: matchingValue.integer_value,
                      };
                    } else if (dynamicField.type === "multiselect") {
                      const selectedOptionIds =
                        matchingValue.text_value
                          ?.split(",")
                          .map((id: string) => id.trim()) || [];
                      const selectedOptions = dynamicField.options?.filter(
                        (option: any) =>
                          selectedOptionIds.includes(String(option.value)),
                      );

                      value = selectedOptions;
                    } else {
                      value =
                        matchingValue.text_value !== null
                          ? matchingValue.text_value
                          : matchingValue.integer_value !== null
                          ? matchingValue.integer_value
                          : matchingValue.float_value !== null
                          ? matchingValue.float_value
                          : matchingValue.boolean_value !== null
                          ? matchingValue.boolean_value
                          : matchingValue.json_value !== null
                          ? matchingValue.json_value
                          : matchingValue.date_value
                          ? matchingValue.date_value
                          : matchingValue.datetime_value
                          ? matchingValue.datetime_value
                          : matchingValue.decimal_value
                          ? matchingValue.decimal_value
                          : null;
                    }

                    itemData[dynamicField.name] = value;
                  }
                }),
              );
            }

            setFormData(itemData);
            setLoading(false);
          } catch (error: any) {
            setErrorStatusCode(error.response.status);
            setErrorMsg({
              code: error.response.status,
              message: error.response.data.message,
            });
            if (
              error.response.status !== 404 &&
              error.response.status !== 500
            ) {
              setToast("error", error.response.data.message);
            }
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dynamicFormData]);

  useEffect(() => {
    if (companyTagsCustomStyle) {
      setLoading(false);
    }
    if (id && dynamicFormData) {
      if (!loadingStatus && dateFormat) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingStatus, dateFormat]);

  useEffect(() => {
    if (!id) {
      if (formFields.length === 0) {
        setLoading(true);
      } else if (loadingStatus) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields]);

  const handleInputChange = (
    event: any,
    fieldName: string,
    inputValue?: any,
  ) => {
    let value: any;
    if (inputValue) {
      value = inputValue;
    } else {
      const target = event?.target;
      value = target?.value;
    }
    if (autoUpdateField && fieldName === "name") {
      setFormData((prevData: any) => ({
        ...prevData,
        [fieldName]: value,
        code: value.replace(/ /g, "_"),
        slug: value.replace(/[^\w]|_/g, "_").toUpperCase(),
      }));
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };
  const handleConfirmation = async () => {
    setFormData((prevData: any) => ({
      ...prevData,
      [confirmationItem.fieldName]: confirmationItem.value,
    }));
    setShowConfirmation(false);
  };

  // Function to handle checkbox change
  const handleConfirmationCheckboxChange = (
    item: any,
    fieldName: any,
    value: any,
  ) => {
    setConfirmationItem({ item, fieldName, value });
    setShowConfirmation(true);
  };

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    field?: any,
  ) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (field && field.confirmation) {
      handleConfirmationCheckboxChange(field, fieldName, value);
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };

  const handleDynamicCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    field: any,
  ) => {
    setFormData((prevData: any) => {
      const updatedData = { ...prevData };

      if (event.target.checked) {
        updatedData[fieldName] = updatedData[fieldName]
          ? `${updatedData[fieldName]},${field.optionId}`
          : field.optionId.toString();
      } else {
        if (updatedData[fieldName]) {
          const optionIds = updatedData[fieldName]
            .split(",")
            .filter((id: any) => id !== field.optionId.toString());
          updatedData[fieldName] = optionIds.join(",");
        }
      }
      return updatedData;
    });
  };

  const handleSelectInputChange = (selectedOption: any, field: Field) => {
    setFormData((prevData: any) => {
      const updatedData = {
        ...prevData,
        [field.name]: selectedOption,
      };
      return updatedData;
    });
  };
  const [submitting, setSubmitting] = useState(false); // State variable to track form submission status

  const handleFormSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    const optionsObject: { [key: string]: string } = {};
    Array.isArray(options) &&
      options?.forEach((option: any, index: any) => {
        optionsObject[`option${index + 1}`] = option;
      });

    formData.options = optionsObject;

    formFields.forEach((field) => {
      if (field.type === "checkbox" && !(field.name in formData)) {
        formData[field.name] = false;
      }
    });

    const newFormErrors: { [key: string]: string } = {};
    let firstErrorField: string | null = null; // Track the first field with an error

    formFields.forEach((field) => {
      if (field.validate) {
        field.validate.forEach((validation) => {
          if (!validation.rule(formData[field.name])) {
            newFormErrors[field.name] = validation.message;
            if (firstErrorField === null) {
              firstErrorField = field.id; // Set the ID of the first field with an error
            }
          }
        });
      }
    });

    setFormValidationErrors(newFormErrors);
    if (firstErrorField && formRef.current) {
      // Scroll to the first field with an error
      const errorFieldElement = document.getElementById(firstErrorField);
      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }

    if (Object.keys(newFormErrors).length === 0) {
      if (customFormSubmit) {
        customFormSubmit(formData);
      } else {
        try {
          setSubmitting(true);
          const dynamicAttributesValues = formFields
            .filter((field: any) => field.isDynamic)
            .reduce((result: any[], field: any) => {
              const { name, type } = field;
              if (type === "select") {
                const data: any = formData[name]?.value || formData[name] || "";
                result.push({
                  attribute_id: field.attribute_id,
                  type,
                  data,
                });
              } else if (type === "lookup") {
                let selectedId: any = formData[name];

                result.push({
                  attribute_id: field.attribute_id,
                  type,
                  data: selectedId?.value,
                });
              } else if (type === "multiselect") {
                let selectedIds: any = formData[name] || [];
                selectedIds = selectedIds.map((id: any) => id.value).join(",");

                result.push({
                  attribute_id: field.attribute_id,
                  type,
                  data: selectedIds,
                });
              } else if (type === "checkbox") {
                let isCheckboxChecked;
                if (id) {
                  isCheckboxChecked =
                    formData[name] && formData[name].includes(field.optionId);
                } else {
                  isCheckboxChecked = Number(formData[name]) === field.optionId;
                }

                if (isCheckboxChecked) {
                  const selectedIds = Array.isArray(field.optionId)
                    ? field.optionId
                    : field.optionId
                    ? [field.optionId]
                    : [];

                  const existingEntry = result.find(
                    (entry) =>
                      entry.attribute_id === field.attribute_id &&
                      entry.type === type,
                  );

                  if (existingEntry) {
                    existingEntry.data = existingEntry.data.concat(selectedIds);
                  } else {
                    result.push({
                      attribute_id: field.attribute_id,
                      type,
                      data: selectedIds,
                    });
                  }
                }
              } else {
                const data = formData[name] || "";
                result.push({
                  attribute_id: field.attribute_id,
                  type,
                  data,
                });
              }

              return result;
            }, [])
            .map((entry: any) => ({
              ...entry,
              data: Array.isArray(entry.data)
                ? entry.data.join(",")
                : entry.data,
            }));

          const filteredAttributesValues = dynamicAttributesValues.filter(
            (item) =>
              item.type === "boolean" ? true : item.data && item.data !== "",
          );
          formData.attributes = filteredAttributesValues;

          if (formData.id) {
            let item = await model.$query().find(formData.id);

            item.$attributes = {
              ...item.$attributes,
              ...formData,
            };

            item = await item.$save();
            setToast("success", `${title} Successfully Updated`);
          } else {
            await model.$query().store(formData);
            setToast("success", `${title} Successfully Added`);
          }
          if (onChangeModalStatus) {
            onChangeModalStatus(false);
          } else {
            navigate(
              `${listRoute
                ?.toString()
                .toLowerCase()
                .replace("A", "")
                .replace(" ", "-")}`,
            );
          }
        } catch (error: any) {
          if (error.response?.data?.errors) {
            setFormError(error.response.data.errors);
          } else if (typeof error.response?.data?.message === "object") {
            const dynamicErrors = Object.keys(error.response.data.message)
              .map((key) => error.response.data.message[key])
              .filter((value) => typeof value === "string");

            setFormError(dynamicErrors.join(", "));
          } else if (error.response?.data?.message) {
            setFormError(error.response.data.message);
          } else {
            setToast(
              "error",
              "An error occurred while processing your request",
            );
          }
        } finally {
          setSubmitting(false); // Set submitting state to false after API response or error
        }
      }
    }
  };
  const handleGoBack = () => {
    if (onChangeModalStatus) {
      onChangeModalStatus(false);
    } else if (customGoBack) {
      navigate(customGoBack);
    } else {
      navigate(-1);
    }
  };
  useEffect(() => {
    if (onChangeValue) {
      onChangeValue(formData);
    }
  });
  const handleFileChange = (event: any, fieldName: any) => {
    const selectedFile: any = event.target.files[0];
    setFormData((prevData: any) => ({
      ...prevData,
      [fieldName]: selectedFile,
    }));
  };
  const addOption = () => {
    setOptions((prevOptions: any) => [...prevOptions, ""]);
  };

  const handleOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index?: any,
  ) => {
    const updatedOptions: any = [...options];
    updatedOptions[index] = {
      ...updatedOptions[index],
      name: event.target.value,
    };
    setOptions(updatedOptions);
  };

  const removeOption = async (index: number) => {
    if (id) {
      await AttributesOption.$query().destroy(options[index].id);
    }
    const updatedOptions = [...options];
    updatedOptions?.splice(index, 1);
    setOptions(updatedOptions);
  };

  const renderCheckboxGroup = (checkboxes: any[], groupName: string) => (
    <div key={groupName}>
      {groupName !== "defaultGroup" && (
        <p className="mb-0">{transformLabel(groupName)}</p>
      )}
      <div className="row mb-2">
        {checkboxes.map((checkbox) => (
          <div className="col-md-1" key={checkbox.id}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={checkbox.id}
                name={checkbox.name}
                onChange={(event) =>
                  handleDynamicCheckBoxChange(event, checkbox.name, checkbox)
                }
                checked={
                  id &&
                  formData[checkbox.name] &&
                  formData[checkbox.name].includes(checkbox.optionId)
                }
              />
              <label className="form-check-label" htmlFor={checkbox.id}>
                {transformLabel(checkbox.name)}
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const groupedCheckboxes = formFields.reduce((acc: any, field: any) => {
    if (field.isDynamic && field.type === "checkbox") {
      const groupName = field.checkboxName || "defaultGroup";
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(field);
    }
    return acc;
  }, {});

  const renderFormFields = () => {
    return formFields
      .map((field: any, _index: any) => {
        let fieldValue: any = formData[field.name] || "";
        if (field.isDynamic && field.type === "checkbox") {
          return null;
        }
        return (
          <div className={`col-${field.col} p-2`} key={field.id}>
            <div className="form-group">
              {field.type !== "checkbox" &&
                field.type !== "switch" &&
                field.type !== "hidden" && (
                  <label htmlFor={field.id}>
                    {transformLabel(field.label)}
                  </label>
                )}
              {field.type === "select" || field.type === "multiselect" ? (
                <CustomAsyncSelect
                  componentKey={
                    refreshKey
                      ? refreshKey
                      : field?.componentKey
                      ? field?.componentKey
                      : field?.modalStatus
                      ? 0
                      : 1
                  }
                  id={field.id}
                  model={field.model}
                  isdisabled={field.disabled}
                  customFilter={field.customFilter}
                  inputfield={field.searchField}
                  fieldName={field.name}
                  widget={field.widget}
                  // customValue={field.customValue || fieldValue}
                  isNeedFullData={field.isNeedFullData}
                  isMultiSelect={field.isMultiSelect || false}
                  formData={formData}
                  defaultOptions={field.options}
                  label={field.label}
                  includeWith={field.includeWith}
                  isNotSearchable={field.isNotSearchable}
                  addNewBtnStatus={field.addNewBtnStatus}
                  placeholder={field.placeholder}
                  onChangeModalStatus={(status: boolean) => {
                    field?.onChangeModalStatus &&
                      field?.onChangeModalStatus(status);
                  }}
                  modalStatus={field?.modalStatus && field?.modalStatus}
                  onChange={(selectedOption: any) => {
                    handleSelectInputChange(selectedOption, field);
                    if (onOptionChange) {
                      // setFormData(...formData)
                      onOptionChange(selectedOption, field.name, formData);
                    }
                  }}
                />
              ) : field.type === "lookup" ? (
                <CustomAsyncSelect
                  id={field.id}
                  model={field.model}
                  inputfield={field.searchField}
                  fieldName={field.name}
                  isNeedFullData={field.isNeedFullData}
                  isMultiSelect={field.isMultiSelect || false}
                  formData={formData}
                  isNotSearchable={field.isNotSearchable}
                  addNewBtnStatus={field.addNewBtnStatus}
                  label={field.label}
                  onChange={(selectedOption: any) => {
                    handleSelectInputChange(selectedOption, field);
                    if (onOptionChange) {
                      onOptionChange(selectedOption, field.name);
                    }
                  }}
                />
              ) : field.type === "switch" ? (
                <div className="mb-3">
                  <div className="d-flex align-items-center">
                    <span
                      className="fw-bold"
                      style={{
                        marginRight: "50px",
                        fontSize: "15px",
                        width: "250px",
                      }}
                    >
                      {transformLabel(field.label)}
                    </span>
                    <div className="text-center">
                      <span style={{ fontSize: "15px", marginRight: "10px" }}>
                        Manual
                      </span>
                    </div>
                    <Form.Check
                      type="switch"
                      id={field.id}
                      onChange={(event) =>
                        handleCheckBoxChange(event, field.name)
                      }
                      checked={fieldValue}
                    />
                    <div className="text-center">
                      <span style={{ fontSize: "15px" }}>Auto</span>
                    </div>
                  </div>
                </div>
              ) : field.type === "boolean" ? (
                <div className="mb-3">
                  <div className="d-flex align-items-center">
                    <div className="text-center">
                      <span style={{ fontSize: "15px", marginRight: "10px" }}>
                        No
                      </span>
                    </div>
                    <Form.Check
                      type="switch"
                      id={field.id}
                      onChange={(event) =>
                        handleCheckBoxChange(event, field.name)
                      }
                      checked={fieldValue}
                    />
                    <div className="text-center">
                      <span style={{ fontSize: "15px" }}>Yes</span>
                    </div>
                  </div>
                </div>
              ) : !field.isDynamic && field.type === "checkbox" ? (
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={field.id}
                    name={field.name}
                    onChange={(event) =>
                      handleCheckBoxChange(event, field.name, field)
                    }
                    checked={fieldValue}
                  />
                  <label className="form-check-label" htmlFor={field.id}>
                    {transformLabel(field.name)}
                  </label>
                </div>
              ) : field.type === "datetime" ? (
                <input
                  className="form-control"
                  type="datetime-local"
                  id={field.id}
                  name={field.name}
                  placeholder={field.placeholder}
                  onChange={(event) => handleInputChange(event, field.name)}
                  value={fieldValue}
                  disabled={field.disabled || false}
                />
              ) : field.type === "image" ? (
                <>
                  <input
                    className="form-control"
                    type="file"
                    id={field.id}
                    name={field.name}
                    placeholder={field.placeholder}
                    onChange={(event) => handleFileChange(event, field.name)}
                    disabled={field.disabled || false}
                    accept="image/*"
                  />
                  {id && fieldValue && (
                    <span className="text-success">
                      Selected File: {fieldValue}
                    </span>
                  )}
                </>
              ) : field.type === "file" ? (
                <span className="d-flex align-items-center">
                  <input
                    className="form-control"
                    type="file"
                    id={field.id}
                    name={field.name}
                    placeholder={field.placeholder}
                    onChange={(event) => handleFileChange(event, field.name)}
                    disabled={field.disabled || false}
                  />
                  {id && fieldValue && (
                    <a
                      style={{ marginLeft: "12px" }}
                      href={fieldValue}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Download style={{ fill: "green" }} />
                    </a>
                  )}
                </span>
              ) : field.type === "phone_no" ? (
                <PhoneInput
                  id={field.id}
                  international
                  className="form-control"
                  value={fieldValue}
                  onChange={(value: any) => {
                    handleInputChange(null, field.name, value);
                  }}
                />
              ) : field.type === "decimal" ? (
                <input
                  type="number"
                  className="form-control"
                  id={field.id}
                  name={field.name}
                  placeholder={field.placeholder}
                  onChange={(event) => handleInputChange(event, field.name)}
                  value={fieldValue}
                  disabled={field.disabled || false}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false },
                    )
                  }
                />
              ) : dateFormat && field.type === "datePicker" ? (
                <DatePicker
                  id={field.id}
                  disabled={field.disabled}
                  wrapperClassName="d-flex"
                  selected={
                    new Date(fieldValue) instanceof Date &&
                    !isNaN(new Date(fieldValue).getTime())
                      ? fieldValue
                      : ""
                  }
                  showYearDropdown
                  placeholderText={dateFormat.replace("dd", "DD")}
                  onChange={(date) => {
                    setFormData((prevData: any) => ({
                      ...prevData,
                      [field.name]: date,
                    }));
                  }}
                  dateFormat={dateFormat?.replace("YYYY", "yyyy").split(" ")[0]}
                  className="form-control"
                  onBlur={(e) => {
                    let enteredDate = e.target.value;
                    const dateFormatWithoutTime = dateFormat
                      .replace("dd", "DD")
                      .split(" ")[0];
                    enteredDate = enteredDate.replace(/\b(\d{1})\b/g, "0$1");
                    const isValid = moment(
                      enteredDate,
                      dateFormatWithoutTime,
                    ).isValid();
                    if (isValid) {
                      setFormValidationErrors({
                        ...formValidationErrors,
                        [field.name]: "",
                      });
                    } else {
                      setFormData((prevData: any) => ({
                        ...prevData,
                        [field.name]: "",
                      }));
                      if (enteredDate.length > 0) {
                        setFormValidationErrors({
                          ...formValidationErrors,
                          [field.name]: `Please select a date in the correct format (${
                            dateFormat.replace("dd", "DD").split(" ")[0]
                          }).`,
                        });
                      } else {
                        setFormValidationErrors({
                          ...formValidationErrors,
                          [field.name]: "",
                        });
                      }
                    }
                  }}
                />
              ) : !field.datePicker && field.type === "date" ? (
                <input
                  type={field.type}
                  className="form-control"
                  id={field.id}
                  name={field.name}
                  placeholder={field.placeholder}
                  onChange={(event) => handleInputChange(event, field.name)}
                  value={DateFormat(fieldValue)}
                  disabled={field.disabled || false}
                />
              ) : field.type === "button" ? (
                <div className={field?.button?.buttonDivClass}>
                  <Button
                    id={field.id}
                    variant={field?.button?.variant}
                    type={field?.button?.type}
                    onClick={field?.button?.onClick}
                    className={`btn btn-primary btn-sm ${field?.button?.customeClass}`}
                    size={field?.button?.size && field?.button?.size}
                  >
                    {field?.button?.label}
                  </Button>
                </div>
              ) : field.type === "radio" ? (
                <div className={`col-${field.col} p-2`} key={field.id}>
                  <div className="form-group d-flex">
                    {field.options?.map((option: any) => (
                      <div
                        key={option.value}
                        className={
                          field.name === "stock_action"
                            ? "form-check custom-radio"
                            : undefined
                        }
                      >
                        <input
                          type="radio"
                          id={`${field.id}_${option.value}`}
                          data-action={option.value === "1" ? "add" : "reduce"}
                          name={field.name}
                          value={option.value}
                          checked={fieldValue === option.value}
                          onChange={(event) =>
                            handleInputChange(event, field.name)
                          }
                        />
                        <label
                          className="form-check-label ms-1"
                          htmlFor={`${field.id}_${option.value}`}
                        >
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ) : field.type === "textarea" ? (
                <textarea
                  className="form-control"
                  id={field.id}
                  rows={field.rows}
                  name={field.name}
                  placeholder={field.placeholder}
                  onChange={(event) => handleInputChange(event, field.name)}
                  value={fieldValue}
                  disabled={field.disabled || false}
                />
              ) : (
                <input
                  type={field.type}
                  className="form-control"
                  id={field.id}
                  name={field.name}
                  placeholder={field.placeholder}
                  onChange={(event) => handleInputChange(event, field.name)}
                  value={fieldValue}
                  disabled={field.disabled || false}
                  onFocus={(e) => {
                    if (field.type === "number") {
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false },
                      );
                    }
                  }}
                />
              )}
              {field.name === "type" &&
                (fieldValue.value === "select" ||
                  fieldValue.value === "multiselect" ||
                  fieldValue.value === "checkbox") && (
                  <>
                    <div className="form-group mt-3">
                      {options?.map((option: any, optionIndex: any) => (
                        <div
                          className="form-group mt-3 d-flex"
                          key={optionIndex}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder={`Option ${optionIndex + 1}`}
                            value={option.name}
                            onChange={(event) =>
                              handleOptionChange(event, optionIndex)
                            }
                            required
                          />
                          {optionIndex > 1 && (
                            <button
                              type="button"
                              onClick={() => removeOption(optionIndex)}
                              className="btn btn-danger ms-2"
                            >
                              -
                            </button>
                          )}
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={addOption}
                        className="btn btn-success ms-2 mt-2"
                        style={{ float: "inline-end" }}
                      >
                        +
                      </button>
                    </div>
                  </>
                )}

              {formValidationErrors[field.name] && (
                <small className="text-danger">
                  {formValidationErrors[field.name]}
                </small>
              )}
            </div>
          </div>
        );
      })
      .concat(
        Object.entries(groupedCheckboxes).map(([groupName, checkboxes]: any) =>
          renderCheckboxGroup(checkboxes, groupName),
        ),
      );
  };

  if (errorStatusCode === 500 || errorStatusCode === 404) {
    return <ErrorHandler error={errorMsg} />;
  }

  if (dynamicFormError || formError) {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="container">
      {dynamicFormError && (
        <Alert variant="danger">
          {typeof dynamicFormError === "string"
            ? dynamicFormError
            : Object.keys(dynamicFormError).length > 0 && (
                <>
                  {Object.entries(dynamicFormError).map(
                    ([fieldName, errors]: any) => (
                      <li key={fieldName}>{errors.join(", ")}</li>
                    ),
                  )}
                </>
              )}
        </Alert>
      )}
      {formError && (
        <Alert variant="danger">
          {typeof formError === "string"
            ? formError
            : Object.keys(formError).length > 0 && (
                <>
                  {Object.entries(formError).map(([fieldName, errors]: any) => (
                    <li key={fieldName}>{errors.join(", ")}</li>
                  ))}
                </>
              )}
        </Alert>
      )}
      <h2>{RJSFtitle}</h2>

      {loading ? (
        CustomFormSkeleton ? (
          <CustomFormSkeleton />
        ) : (
          <FormSkeleton />
        )
      ) : (
        <form
          ref={formRef}
          className="row"
          style={
            companyTagsCustomStyle
              ? { alignItems: "center", width: "90%", flexWrap: "unset" }
              : {}
          }
        >
          {renderFormFields()}
          <div
            className={`${
              !companyTagsCustomStyle && "p-4"
            } d-flex justify-content-end `}
            style={companyTagsCustomStyle}
          >
            {!isBackBtnDisable && (
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={handleGoBack}
                style={{ marginRight: "10px" }}
              >
                Back
              </button>
            )}
            <CustomButton
              type="submit"
              label={submitButtonLabel || "Submit"}
              onClick={handleFormSubmit}
              variant="primary"
              disableStatus={
                customSubmitting
                  ? customSubmitting
                  : submitting
                  ? submitting
                  : disableSubmitButton
                  ? disableSubmitButton
                  : false
              } // Disable the button when form is being submitted
              spinnerStatus={
                customSubmitting
                  ? customSubmitting
                  : submitting
                  ? submitting
                  : false
              } // Disable the button when form is being submitted
            />
          </div>
        </form>
      )}
      {(id || dynamicId) &&
        !isNotUpdate &&
        (noteModules.includes(modelName) || AuditLogModule[modelName]) && (
          <span>
            <div className="card mt-4" style={{ marginBottom: "30px" }}>
              <div className="card-header d-flex">
                {id && !dynamicFormData && noteModules.includes(modelName) && (
                  <h5
                    style={{ cursor: "pointer", fontSize: "17px" }}
                    className={`mb-0 me-3 ${
                      activeTab === "notes" ? "text-primary" : ""
                    }`}
                    onClick={() => setActiveTab("notes")}
                  >
                    Notes
                  </h5>
                )}
                {id && AuditLogModule[modelName] && (
                  <h5
                    style={{
                      cursor: noteModules.includes(modelName) ? "pointer" : "",
                      fontSize: "17px",
                    }}
                    className={`mb-0 me-3 ${
                      activeTab === "activityLogs" ? "text-primary" : ""
                    }`}
                    onClick={() => setActiveTab("activityLogs")}
                  >
                    Activity Logs
                  </h5>
                )}
              </div>

              <div
                className="card-body"
                style={{
                  height: "auto",
                  overflow: "auto",
                  backgroundColor: "#e4e4e4",
                }}
              >
                {id &&
                  !dynamicFormData &&
                  noteModules.includes(modelName) &&
                  activeTab === "notes" && (
                    <Notes id={id} model={model}></Notes>
                  )}
                {(id || dynamicId) &&
                  AuditLogModule[modelName] &&
                  activeTab === "activityLogs" && (
                    <ActivityLogs
                      auditable_type={AuditLogModule[modelName]}
                      id={dynamicId ? dynamicId : id}
                    />
                  )}
              </div>
            </div>
          </span>
        )}
      <ConfirmationModal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        title="Confirmation"
        message="Are you sure you want to change the default store?"
        onConfirm={handleConfirmation}
        confirmButtonColor="primary"
      />
    </div>
  );
};

export default AddUpdateForm;
