import { RouteObject } from "react-router-dom";
import List from "../../Pages/SalesAndPurchase/ProformaInvoices/List";
import AddUpdate from "../../Pages/SalesAndPurchase/ProformaInvoices/AddUpdate";
import View from "../../Pages/SalesAndPurchase/ProformaInvoices/View";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_PROFORMA_INVOICE,
  DELETE_PROFORMA_INVOICE,
  UPDATE_PROFORMA_INVOICE,
  VIEW_PROFORMA_INVOICE,
} from "../../Util/PermissionList";

export const PROFORMAINVOICES_TITLE = "Proforma Invoice";
export const PROFORMAINVOICES_LIST = "/proforma-invoices";
export const PROFORMAINVOICES_ADD = "/proforma-invoices/add-order";
export const PROFORMAINVOICES_VIEW = "/proforma-invoices/:id/view";
export const PROFORMAINVOICES_EDIT =
  "/proforma-invoices/:updateid/edit-invoice";

const ProformaInvoices: RouteObject[] = [
  {
    path: PROFORMAINVOICES_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_PROFORMA_INVOICE}
        checkPermisson={{
          addPermission: ADD_PROFORMA_INVOICE,
          updatePermission: UPDATE_PROFORMA_INVOICE,
          deletePermission: DELETE_PROFORMA_INVOICE,
          viewPermission: VIEW_PROFORMA_INVOICE,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: PROFORMAINVOICES_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        checkPermisson={{
          addPermission: ADD_PROFORMA_INVOICE,
          updatePermission: UPDATE_PROFORMA_INVOICE,
          deletePermission: DELETE_PROFORMA_INVOICE,
          viewPermission: VIEW_PROFORMA_INVOICE,
        }}
        permission={ADD_PROFORMA_INVOICE}
      />
    ),
  },
  {
    path: PROFORMAINVOICES_VIEW,
    element: (
      <AuthorizedRoute
        element={<View />}
        checkPermisson={{
          addPermission: ADD_PROFORMA_INVOICE,
          updatePermission: UPDATE_PROFORMA_INVOICE,
          deletePermission: DELETE_PROFORMA_INVOICE,
          viewPermission: VIEW_PROFORMA_INVOICE,
        }}
        permission={VIEW_PROFORMA_INVOICE}
      />
    ),
  },
  {
    path: PROFORMAINVOICES_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        checkPermisson={{
          addPermission: ADD_PROFORMA_INVOICE,
          updatePermission: UPDATE_PROFORMA_INVOICE,
          deletePermission: DELETE_PROFORMA_INVOICE,
          viewPermission: VIEW_PROFORMA_INVOICE,
        }}
        permission={UPDATE_PROFORMA_INVOICE}
      />
    ),
  },
];

export default ProformaInvoices;
