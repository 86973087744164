// Importing necessary modules and components.
import React from "react"; // Importing React library for building UI components.
import App from "./App"; // Importing the main App component.
import { BrowserRouter } from "react-router-dom"; // Importing BrowserRouter for implementing client-side routing.
import ReactDOM from "react-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement,
);
