import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/ProductionProcessType/List";
import AddUpdate from "../../Pages/Setting/ProductionProcessType/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_PRODUCTION_PROCESS_TYPE,
  DELETE_PRODUCTION_PROCESS_TYPE,
  RESTORE_PRODUCTION_PROCESS_TYPE,
  UPDATE_PRODUCTION_PROCESS_TYPE,
  VIEW_PRODUCTION_PROCESS_TYPE,
} from "../../Util/PermissionList";

export const PRODUCTION_PROCESS_TYPE_TITLE = "Production Process Type";
export const PRODUCTION_PROCESS_TYPE_LIST = "/Production-process-type";
export const PRODUCTION_PROCESS_TYPE_ADD = "/Production-process-type/add";
export const PRODUCTION_PROCESS_TYPE_EDIT = "/Production-process-type/:id/edit";
export const PRODUCTION_PROCESS_TYPE_ARCHIVE =
  "/Production-process-type/archive";

const ProductionProcessType: RouteObject[] = [
  {
    path: PRODUCTION_PROCESS_TYPE_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_PRODUCTION_PROCESS_TYPE}
        checkPermisson={{
          addPermission: ADD_PRODUCTION_PROCESS_TYPE,
          updatePermission: UPDATE_PRODUCTION_PROCESS_TYPE,
          deletePermission: DELETE_PRODUCTION_PROCESS_TYPE,
          restorePermission: RESTORE_PRODUCTION_PROCESS_TYPE,
          viewPermission: VIEW_PRODUCTION_PROCESS_TYPE,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: PRODUCTION_PROCESS_TYPE_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_PRODUCTION_PROCESS_TYPE}
        checkPermisson={{
          addPermission: ADD_PRODUCTION_PROCESS_TYPE,
          updatePermission: UPDATE_PRODUCTION_PROCESS_TYPE,
          deletePermission: DELETE_PRODUCTION_PROCESS_TYPE,
          restorePermission: RESTORE_PRODUCTION_PROCESS_TYPE,
          viewPermission: VIEW_PRODUCTION_PROCESS_TYPE,
        }}
      />
    ),
  },
  {
    path: PRODUCTION_PROCESS_TYPE_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_PRODUCTION_PROCESS_TYPE}
        checkPermisson={{
          addPermission: ADD_PRODUCTION_PROCESS_TYPE,
          updatePermission: UPDATE_PRODUCTION_PROCESS_TYPE,
          deletePermission: DELETE_PRODUCTION_PROCESS_TYPE,
          restorePermission: RESTORE_PRODUCTION_PROCESS_TYPE,
          viewPermission: VIEW_PRODUCTION_PROCESS_TYPE,
        }}
      />
    ),
  },
  {
    path: PRODUCTION_PROCESS_TYPE_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        permission={DELETE_PRODUCTION_PROCESS_TYPE}
        checkPermisson={{
          addPermission: ADD_PRODUCTION_PROCESS_TYPE,
          updatePermission: UPDATE_PRODUCTION_PROCESS_TYPE,
          deletePermission: DELETE_PRODUCTION_PROCESS_TYPE,
          restorePermission: RESTORE_PRODUCTION_PROCESS_TYPE,
          viewPermission: VIEW_PRODUCTION_PROCESS_TYPE,
        }}
      />
    ),
  },
];

export default ProductionProcessType;
