import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/LogisticDetail/List";
import AddUpdate from "../../Pages/Setting/LogisticDetail/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_LOGISTIC_DETAIL,
  DELETE_LOGISTIC_DETAIL,
  RESTORE_LOGISTIC_DETAIL,
  UPDATE_LOGISTIC_DETAIL,
  VIEW_LOGISTIC_DETAIL,
} from "../../Util/PermissionList";

export const LOGISTICDETAILS_TITLE = "Logistic Details";
export const LOGISTICDETAILS_LIST = "/logistic-details";
export const LOGISTICDETAILS_ADD = "/logistic-details/add";
export const LOGISTICDETAILS_EDIT = "/logistic-details/:id/edit";
export const LOGISTICDETAILS_ARCHIVE = "/logistic-details/archive";

const LogisticDetail: RouteObject[] = [
  {
    path: LOGISTICDETAILS_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_LOGISTIC_DETAIL}
        checkPermisson={{
          addPermission: ADD_LOGISTIC_DETAIL,
          updatePermission: UPDATE_LOGISTIC_DETAIL,
          deletePermission: DELETE_LOGISTIC_DETAIL,
          restorePermission: RESTORE_LOGISTIC_DETAIL,
          viewPermission: VIEW_LOGISTIC_DETAIL,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: LOGISTICDETAILS_ADD,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate
            title={LOGISTICDETAILS_TITLE}
            listRoute={LOGISTICDETAILS_LIST}
          />
        }
        checkPermisson={{
          addPermission: ADD_LOGISTIC_DETAIL,
          updatePermission: UPDATE_LOGISTIC_DETAIL,
          deletePermission: DELETE_LOGISTIC_DETAIL,
          restorePermission: RESTORE_LOGISTIC_DETAIL,
          viewPermission: VIEW_LOGISTIC_DETAIL,
        }}
        permission={ADD_LOGISTIC_DETAIL}
      />
    ),
  },
  {
    path: LOGISTICDETAILS_EDIT,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate
            title={LOGISTICDETAILS_TITLE}
            listRoute={LOGISTICDETAILS_LIST}
          />
        }
        checkPermisson={{
          addPermission: ADD_LOGISTIC_DETAIL,
          updatePermission: UPDATE_LOGISTIC_DETAIL,
          deletePermission: DELETE_LOGISTIC_DETAIL,
          restorePermission: RESTORE_LOGISTIC_DETAIL,
          viewPermission: VIEW_LOGISTIC_DETAIL,
        }}
        permission={UPDATE_LOGISTIC_DETAIL}
      />
    ),
  },
  {
    path: LOGISTICDETAILS_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_LOGISTIC_DETAIL,
          updatePermission: UPDATE_LOGISTIC_DETAIL,
          deletePermission: DELETE_LOGISTIC_DETAIL,
          restorePermission: RESTORE_LOGISTIC_DETAIL,
          viewPermission: VIEW_LOGISTIC_DETAIL,
        }}
        permission={DELETE_LOGISTIC_DETAIL}
      />
    ),
  },
];

export default LogisticDetail;
