import { FormEvent, useEffect, useState } from "react";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Timeline, TimelineEvent } from "react-event-timeline";
import CustomEditButton from "./Button/CustomEditButton";
import { setToast } from "../Util/Toast";
import { Trash3 } from "react-bootstrap-icons";
import { getCookie } from "typescript-cookie";
import { Note } from "../Model/Setting/Note";
import pluralize from "pluralize";
import CustomButton from "./Button/CustomButton";
import ConfirmationModal from "./ConfirmationModel";
import CustomSkeleton from "./CustomSkeleton";

interface NotesProps {
  id: any;
  model: any;
  customModel?: any;
}

const Notes: React.FC<NotesProps> = ({ id, model, customModel }) => {
  const [noteData, setNoteData] = useState<Array<any>>([]); // Specify the type as Array<any>
  const [noteValue, setNoteValue] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [actionId, setActionId] = useState("");
  const [loginUserId, setLoginUserId] = useState("");
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status

  useEffect(() => {
    const user: any = getCookie("user");
    setLoginUserId(JSON.parse(user).id);
    getNoteData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNoteData = async (itemId: string) => {
    try {
      let query: any = model.$query();
      const apiUrl = `${query.httpClient.baseUrl}/${itemId}/notes?trashed=false`;
      const item: any = await query.httpClient.get(apiUrl);
      setNoteData(item.data.data);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setLoading(false);
    }
  };

  const saveNotes = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      setCustomSubmitting(true);
      const noteData: any = {
        content: noteValue,
        [(customModel
          ? customModel
          : pluralize.singular(new model().$resource()) + "_id"
        ).replace(/-/g, "_")]: id,
      };

      if (actionId) {
        let item = await Note.$query().find(actionId);

        item.$attributes = {
          ...item.$attributes,
          ...noteData,
        };

        item = await item.$save();
        getNoteData(id);

        setActionId("");
        setNoteValue("");
        setToast("success", `Note Successfully Updated`);
      } else {
        await Note.$query().store(noteData);
        getNoteData(id);
        setNoteValue("");
        setToast("success", `Note Successfully Added`);
      }
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  function handleDeleteNote(index: any) {
    setActionId(index);
    setShowConfirmation(true);
  }
  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleConfirmDelete = async () => {
    try {
      setCustomSubmitting(true);
      await Note.$query().destroy(actionId);
      getNoteData(id);
      setNoteValue("");
      setActionId("");
      setShowConfirmation(false);
      setToast("success", `Note Successfully Deleted`);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };
  const handleEditNoteButtonClick = () => {
    const formBottom = document.getElementById("note");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <div
      style={{
        width: "100%",
        marginBottom: "10px",
      }}
    >
      <div>
        <form
          className=" d-flex flex-column align-items-end"
          onSubmit={(event: any) => {
            saveNotes(event);
          }}
        >
          <textarea
            id="note"
            placeholder="Enter Your Note"
            value={noteValue}
            onChange={(e) => {
              setNoteValue(e.target.value);
            }}
            style={{
              width: "100%",
              height: "135px",
              padding: "5px 5px 10px 10px",
              resize: "none",
              borderColor: "#B3B3B3",
              borderRadius: "4px",
            }}
            required
          ></textarea>
          <span style={{ margin: "15px 0" }}>
            <CustomButton
              type="submit"
              label={actionId ? "Update" : "Save"}
              variant="primary"
              disableStatus={customSubmitting ? customSubmitting : false}
              spinnerStatus={customSubmitting ? customSubmitting : false}
            />
          </span>
        </form>
        <div className="overflow-hidden">
          {loading ? (
            <CustomSkeleton
              height={40}
              width={"95%"}
              center={true}
              baseColor={"white"}
              highlightColor={"#f2f2f2"}
            />
          ) : !noteData || noteData.length === 0 ? (
            <div
              style={{ textAlign: "center", color: "gray", marginTop: "20px" }}
            >
              No notes available
            </div>
          ) : (
            noteData?.map((res: any, index: any) => {
              return (
                <Timeline
                  key={index}
                  lineStyle={{ top: "30px" }}
                  style={{
                    height: "auto",
                    padding: "0",
                  }}
                >
                  <TimelineEvent
                    title=""
                    icon={<FontAwesomeIcon icon={faNoteSticky} />}
                    contentStyle={{
                      marginTop: "0.2rem",
                      padding: "0.7em 1em",
                      borderRadius: "3px",
                      height: "auto", // Adjust as needed
                      overflowY: "auto",
                      fontFamily: "var(--bs-font-sans-serif);",
                      color: "rgb(35 35 35)",
                      fontWeight: "400",
                      boxShadow: "0",
                    }}
                    buttons={
                      res.creator?.id === loginUserId && (
                        <span
                          style={{
                            position: "absolute",
                            bottom: "1px",
                            right: "35px",
                          }}
                        >
                          <CustomEditButton
                            id={index}
                            style={{ marginRight: "-5px" }}
                            onClick={() => {
                              setNoteValue(res.content);
                              setActionId(res.id);
                              handleEditNoteButtonClick();
                            }}
                          />
                          <button
                            className="border-0 bg-transparent"
                            onClick={() => {
                              handleDeleteNote(res.id);
                            }}
                          >
                            <Trash3
                              style={{ fill: "red", marginTop: "4px" }}
                            ></Trash3>
                          </button>
                        </span>
                      )
                    }
                  >
                    <CustomContent content={res.content} />
                  </TimelineEvent>
                  <div className="date-info" style={{ marginBottom: "15px" }}>
                    <span
                      style={{
                        position: "absolute",
                        bottom: "3px",
                        left: "50px",
                        fontSize: "14px",
                        color: "#747678",
                        width: "100%", // Set width to 100%
                        boxSizing: "border-box", // Include padding and border in the width
                      }}
                    >
                      {`Created: ${res.created_at}`}

                      {res.updated_at && `  •   Updated: ${res.updated_at}`}
                      {"  •   "}
                      <span
                        className="text-primary"
                        style={{ fontWeight: "500" }}
                      >
                        {res.creator.first_name} {res.creator.last_name}
                      </span>
                    </span>
                  </div>
                </Timeline>
              );
            })
          )}
        </div>
      </div>

      <ConfirmationModal
        show={showConfirmation}
        onHide={handleCancelDelete}
        title="Delete Note"
        message={`Are you sure you want to delete this note?`}
        onConfirm={handleConfirmDelete}
        confirmButtonColor="danger"
        spinnerStatus={customSubmitting ? customSubmitting : false}
      />
    </div>
  );
};

function CustomContent({ content }: any) {
  const [expanded, setExpanded] = useState(false);

  const truncatedContent =
    content.length > 150 ? content.slice(0, 150) + "..." : content;

  return (
    <div>
      <span>{expanded ? content : truncatedContent}</span>
      {content.length > 150 && (
        <span
          style={{ color: "blue", cursor: "pointer", marginLeft: "5px" }}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? " (less)" : " (more)"}
        </span>
      )}
    </div>
  );
}

export default Notes;
