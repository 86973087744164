import { getCookie } from "typescript-cookie";
import { setLocalStorage } from "./LocalStorage";
import { setToast } from "./Toast";

const fetchPermissions = async () => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL as string;
    const BASE_URL = apiUrl + "api/";
    const token = getCookie("authToken");
    const headers = {
      Authorization: `Bearer ${token ? token.replace(/"/g, "") : ""}`,
      "Content-Type": "application/json",
    };

    const response: any = await fetch(`${BASE_URL}sub-user-profile`, {
      method: "GET",
      headers: headers,
    });

    if (response.ok) {
      let data = await response.json();
      const roles = await data.data.roles;

      const allPermissions: any = roles.flatMap((role: any) =>
        role.permissions.map((permission: any) => permission.slug),
      );

      const uniquePermissions: any = allPermissions.filter(
        (value: any, index: any, array: any) => array.indexOf(value) === index,
      );
      setLocalStorage("permissions", uniquePermissions);
    }
  } catch (error) {
    setToast("error", "Something went wrong");
  }
};

export default fetchPermissions;
