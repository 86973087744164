import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/TaxType/List";
import AddUpdate from "../../Pages/Setting/TaxType/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_TAX_TYPE,
  DELETE_TAX_TYPE,
  RESTORE_TAX_TYPE,
  UPDATE_TAX_TYPE,
  VIEW_TAX_TYPE,
} from "../../Util/PermissionList";

export const TAX_TYPE_TITLE = "Tax Type";
export const TAX_TYPE_LIST = "/tax-types";
export const TAX_TYPE_ADD = "/tax-types/add";
export const TAX_TYPE_EDIT = "/tax-types/:id/edit";
export const TAX_TYPE_ARCHIVE = "/tax-types/archive";

const TaxType: RouteObject[] = [
  {
    path: TAX_TYPE_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_TAX_TYPE}
        checkPermisson={{
          addPermission: ADD_TAX_TYPE,
          updatePermission: UPDATE_TAX_TYPE,
          deletePermission: DELETE_TAX_TYPE,
          restorePermission: RESTORE_TAX_TYPE,
          viewPermission: VIEW_TAX_TYPE,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: TAX_TYPE_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate title={TAX_TYPE_TITLE} listRoute={TAX_TYPE_LIST} />}
        permission={ADD_TAX_TYPE}
        checkPermisson={{
          addPermission: ADD_TAX_TYPE,
          updatePermission: UPDATE_TAX_TYPE,
          deletePermission: DELETE_TAX_TYPE,
          restorePermission: RESTORE_TAX_TYPE,
          viewPermission: VIEW_TAX_TYPE,
        }}
      />
    ),
  },
  {
    path: TAX_TYPE_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate title={TAX_TYPE_TITLE} listRoute={TAX_TYPE_LIST} />}
        permission={UPDATE_TAX_TYPE}
        checkPermisson={{
          addPermission: ADD_TAX_TYPE,
          updatePermission: UPDATE_TAX_TYPE,
          deletePermission: DELETE_TAX_TYPE,
          restorePermission: RESTORE_TAX_TYPE,
          viewPermission: VIEW_TAX_TYPE,
        }}
      />
    ),
  },
  {
    path: TAX_TYPE_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_TAX_TYPE,
          updatePermission: UPDATE_TAX_TYPE,
          deletePermission: DELETE_TAX_TYPE,
          restorePermission: RESTORE_TAX_TYPE,
          viewPermission: VIEW_TAX_TYPE,
        }}
        permission={DELETE_TAX_TYPE}
      />
    ),
  },
];

export default TaxType;
