import React, { useEffect, useState } from "react";
import ListGroup from "react-bootstrap/esm/ListGroup";
import { useApi } from "../../../Controller/ApiController";
import { setToast } from "../../../Util/Toast";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import AddUpdateModel from "./Modal";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { CounterPartyPerson } from "../../../Model/BuyersAndSuppliers/CounterPartyPerson/CounterPartyPerson";
import CustomSkeleton from "../../../Components/CustomSkeleton";

interface AdditionalUserProps {
  id: any;
  handleOpen: () => void;
  show: boolean;
  isHideEditBtn: boolean;
  handleClose: () => void;
}

const AdditionalUser: React.FC<AdditionalUserProps> = ({
  id,
  handleOpen,
  show,
  isHideEditBtn,
  handleClose,
}) => {
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [additionalUserFormData, setAdditionalUserFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [updateId, setUpdateId] = useState<string | null>(null);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status

  /// Company Address
  const additionalUserFormFields = [
    {
      id: "inputName",
      label: "Name*",
      type: "text",
      col: 12,
      name: "name",
      placeholder: "Enter Your Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Name is required",
        },
      ],
    },
    {
      id: "inputemail",
      label: "Email*",
      type: "text",
      col: 6,
      name: "email",
      placeholder: "Enter Your Email",
      validate: [
        {
          rule: (value: any) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
          message: "Please enter a valid email address",
        },
        {
          rule: (value: any) => !!value,
          message: "Email is required",
        },
      ],
    },
    {
      id: "inputPhoneNumber",
      label: "Phone Number*",
      type: "phone_no",
      col: 6,
      name: "phone_no",
      placeholder: "Enter Your Phone Number",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Phone Number is required",
        },
      ],
    },
  ];

  const handleAdditionalUserCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    let inputData = Object.assign({}, formData);
    inputData.counter_party_company_id = id;
    try {
      if (inputData.id) {
        let item = await CounterPartyPerson.$query().find(inputData.id);
        item.$attributes = {
          ...item.$attributes,
          ...inputData,
        };

        item = await item.$save();
        setToast("success", ` Successfully Updated`);
        fetchData();
        setAdditionalUserFormData([]);
        handleClose();
      } else {
        await CounterPartyPerson.$query().store(inputData);
        fetchData();
        setAdditionalUserFormData([]);
        handleClose();
        setToast("success", ` Successfully Added`);
      }
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
      setUpdateId(null);
    }
  };

  const getFormData = async () => {
    // Initial filters array
    const filters = [];

    // Add the second filter only if the id is present
    if (id) {
      filters.push({
        field: "counter_party_company_id",
        operator: "=",
        value: id,
      });
    }
    const item = await apiPost(`counter-party-people/search`, { filters });
    return item;
  };

  const fetchData = async () => {
    if (id) {
      try {
        setLoading(true);
        const itemData = await getFormData();
        setData(itemData.data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setToast("error", error.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return (
      <CustomSkeleton
        height={198}
        customOuterStyle={{ padding: "0" }}
        customInnerStyle={{ borderRadius: "0 0 9px 9px" }}
        containerClassName={"d-grid"}
      />
    );
  }

  return (
    <>
      <div
        style={{
          maxHeight: "173px",
          overflowY: "auto",
          fontSize: "14px",
          padding: "10px 20px 15px",
        }}
      >
        <ListGroup as="ol" numbered>
          {data.map((item: any, index: number) => (
            <ListGroup.Item
              key={index}
              as="li"
              className="d-flex justify-content-between align-items-start border-0 p-0 mb-2"
            >
              <div className="ms-2 me-auto" style={{ marginTop: "-3px" }}>
                <div>
                  <span className="fw-bold" style={{ fontSize: "large" }}>
                    {item.name}
                  </span>{" "}
                  <br />
                  <span style={{ fontWeight: 500 }}>Email : </span>{" "}
                  <span>{item.email}</span> <br />
                  <span style={{ fontWeight: 500 }}>Phone Number : </span>{" "}
                  <span>{item.phone_no}</span>
                </div>
                {/* {item.email}{" "} */}
              </div>
              {!isHideEditBtn && (
                <CustomEditButton
                  onClick={async () => {
                    let deliveryId = item.id;
                    setUpdateId(deliveryId);
                    const getDeliveryAddressFormData = async () => {
                      const item =
                        await CounterPartyPerson.$query().find(deliveryId);
                      return item.$attributes;
                    };
                    try {
                      const itemData = await getDeliveryAddressFormData();
                      let data = itemData;
                      setAdditionalUserFormData(data);
                      handleOpen();
                    } catch (error: any) {
                      setToast("error", error.response.data.message);
                    }
                  }}
                  id={0}
                />
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
      <AddUpdateModel
        title="Additional User"
        show={show}
        handleClose={() => {
          setAdditionalUserFormData([]);
          handleClose();
          setFormError(null);
          setUpdateId(null);
        }}
        widget={
          <AddUpdateForm
            formFields={additionalUserFormFields}
            model={CounterPartyPerson}
            title="Additional User"
            customTitle=" "
            customFormSubmit={handleAdditionalUserCustomSubmit}
            dynamicFormData={additionalUserFormData}
            isBackBtnDisable={true}
            dynamicFormError={formError}
            dynamicId={updateId}
            customSubmitting={customSubmitting}
            isNotUpdate={!updateId ? true : false}
          />
        }
      />
    </>
  );
};

export default AdditionalUser;
