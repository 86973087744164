import { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Store } from "../../../Model/Setting/Store";
import {
  STORE_ADD,
  STORE_ARCHIVE,
  STORE_EDIT,
  STORE_INVENTORY_HISTORY,
  STORE_LIST,
  STORE_TITLE,
} from "../../../Routes/Setting/Store";
import { fetchData } from "../../../Util/OrionList";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomDataTable from "../../../Components/CustomDataTable";
import { setToast } from "../../../Util/Toast";
import { Col, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import CustomButton from "../../../Components/Button/CustomButton";
import ImportData from "../../../Components/ImportData";
import pluralize from "pluralize";
import CustomArchiveButton from "../../../Components/Button/CustomArchiveButton";
import CustomArchiveModal from "../../../Components/CustomArchiveModal";
import CustomRestoreIconButton from "../../../Components/Button/CustomRestoreIconButton";
import CustomRestoreModal from "../../../Components/CustomRestoreModal";
import ConfirmationModal from "../../../Components/ConfirmationModel";
import { VIEW_STOCK_INVENTORY } from "../../../Util/PermissionList";
import { ClockHistory } from "react-bootstrap-icons";

interface StoreItem {
  id: number;
  tax_types_id: number;
  country_id: number;
  name: string;
  tax_number: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zipcode: string;
  is_default: boolean;
  created_at: string;
  updated_at: string;
}

function List({ permissions }: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<StoreItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationItem, setConfirmationItem] = useState<any>(null);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status

  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showImportPopup, setShowImportPopup] = useState(false);
  const [showArchiveModal, setshowArchiveModal] = useState(false);
  const [ArchiveItemId, setArchiveItemId] = useState<number | null>(null);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [isArchive, setIsArchive] = useState<boolean>(
    location.pathname === STORE_ARCHIVE,
  );
  const [showRestoreModal, setshowRestoreModal] = useState(false);
  const [restoreItemId, setRestoreItemId] = useState<number | null>(null);

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(STORE_EDIT.replace(":id", id.toString()));
  };
  const handleRowArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setArchiveItemId(id);
    setshowArchiveModal(true);
  };
  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(STORE_ADD);
  };

  const handleRowRestoreButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setRestoreItemId(id);
    setshowRestoreModal(true);
  };

  const handleArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(STORE_ARCHIVE);
  };

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);
  const DefaultCheckbox = ({ value }: any) => {
    const [checked, setChecked] = useState(value.is_default);

    const handleChange = () => {
      handleChangeDefault(value);
      setChecked(!checked);
    };

    return (
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          style={{ border: "1px solid #9a9a9a", marginLeft: "-0.5rem" }}
          checked={checked}
          onChange={handleChange}
        />
      </div>
    );
  };

  const handleConfirmation = async () => {
    try {
      setCustomSubmitting(true);
      const inputData = {
        ...confirmationItem,
        is_default: !confirmationItem.is_default,
      };
      await Store.$query().update(confirmationItem.id, inputData);
      fetchStore();
      setShowConfirmation(false); // Hide the confirmation dialog
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const handleChangeDefault = (item: any) => {
    setConfirmationItem(item);
    setShowConfirmation(true);
  };
  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(STORE_INVENTORY_HISTORY.replace(":id", id.toString()));
  };

  const columns: TableColumn<StoreItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Name",
      cell: (row) => row.name,
    },
    {
      name: "Tax Number",
      cell: (row) => row.tax_number,
    },
    {
      name: "Default",
      omit: isArchive,
      cell: (row) => <DefaultCheckbox value={row} />,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Deleted At",
      cell: (row: any) => row.deleted_at,
      omit: !isArchive,
    },
    {
      name: "Actions",
      button: true,
      omit: !permissions.update && !permissions.delete,
      cell: (row) => (
        <div>
          {isArchive ? (
            permissions.restore && (
              <CustomRestoreIconButton
                id={row.id}
                onClick={handleRowRestoreButtonClick}
              />
            )
          ) : (
            <>
              {permissions[VIEW_STOCK_INVENTORY] && (
                <button className="btn btn-xs border-0 p-0">
                  <ClockHistory
                    color={"green"}
                    size={"18px"}
                    onClick={(e: any) => handleRowViewButtonClick(e, row?.id)}
                  />
                </button>
              )}
              {permissions.update && (
                <CustomEditButton
                  id={row.id}
                  onClick={handleRowEditButtonClick}
                />
              )}
              {permissions.delete && (
                <CustomArchiveButton
                  id={row.id}
                  onClick={handleRowArchiveButtonClick}
                />
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  // @ts-ignore
  const fetchStore = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new Store(),
        sortField,
        sortOrder,
        isArchive,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchStore();
  }, [limit, page]); // eslint-disable-line

  useEffect(() => {
    if (initialFetchDone) {
      setPage(1);
      fetchStore();
    }
  }, [isArchive]); // eslint-disable-line

  useEffect(() => {
    setIsArchive(location.pathname === STORE_ARCHIVE);
    setInitialFetchDone(true);
  }, [location]);

  const handleGoBack = () => {
    navigate(STORE_LIST);
  };

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "name",
      value: "name",
      label: "Name",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "tax_number",
      value: "tax_number",
      label: "Tax Number",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },

    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
  ];

  const handleImportButtonClick = () => {
    setShowImportPopup(true);
  };

  const handleCloseImportPopup = () => {
    fetchStore();
    setShowImportPopup(false);
  };

  return (
    <>
      <div className="mb-4">
        <h2>
          {pluralize(STORE_TITLE)} {isArchive ? "Archive" : "List"}
        </h2>
      </div>
      <Row>
        <CustomFilter
          options={filterOptions}
          inputValues={inputValues}
          setInputValues={setInputValues}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          getData={fetchStore}
          setPage={setPage}
        />
        <Col className="text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {isArchive ? (
            <CustomButton
              label={`Back`}
              variant="secondary"
              onClick={handleGoBack}
            />
          ) : (
            <>
              {permissions.add && (
                <CustomButton
                  label={`Import`}
                  variant="primary"
                  onClick={handleImportButtonClick}
                />
              )}
              {permissions.add && (
                <CustomButton
                  label={`Add`}
                  variant="success"
                  onClick={handleRowCreateButtonClick}
                />
              )}
              {permissions.delete && (
                <CustomButton
                  label={`Archive`}
                  variant="danger"
                  onClick={handleArchiveButtonClick}
                  customeClass="ms-2"
                />
              )}
            </>
          )}
        </Col>
      </Row>
      <div className="mb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"Store"} // Pass optional fields to CustomDataTable
        />
      </div>
      <ImportData
        show={showImportPopup}
        handleClose={handleCloseImportPopup}
        model={Store}
        modelName={"stores"}
      />
      <CustomArchiveModal
        title={STORE_TITLE}
        model={Store}
        getData={fetchStore}
        ArchiveItemId={ArchiveItemId}
        setArchiveItemId={setArchiveItemId}
        showArchiveModal={showArchiveModal}
        setshowArchiveModal={setshowArchiveModal}
      />
      <CustomRestoreModal
        title={STORE_TITLE}
        model={Store}
        getData={fetchStore}
        RestoreItemId={restoreItemId}
        showRestoreModal={showRestoreModal}
        setRestoreItemId={setRestoreItemId}
        setshowRestoreModal={setshowRestoreModal}
      />
      <ConfirmationModal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        title="Confirmation"
        message="Are you sure you want to change the default store?"
        onConfirm={handleConfirmation}
        confirmButtonColor="primary"
        spinnerStatus={customSubmitting ? customSubmitting : false}
      />
    </>
  );
}

export default List;
