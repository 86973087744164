import { Model } from "@tailflow/laravel-orion/lib/model";

export class CounterPartyAddress extends Model<{
  counter_party_company_id: number;
  address_type: any;
  address_name: any;
  address_line1: string;
  address_line2: string;
  zip_code: string;
  city: string;
  state: string;
  country_id: any;
  country?: any;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "counter-party-address";
  }
}
