import React, { useEffect, useState } from "react";
import { getLocalStorage } from "./LocalStorage";
import { setToast } from "./Toast";
import "../Style/Loader.css";

const AuthorizedRoute = ({
  element,
  permission,
  checkPermisson,
  exception,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState({
    add: false,
    update: false,
    delete: false,
    restore: false,
    view: false,
    viewStockInventory: false,
  });

  useEffect(() => {
    try {
      setLoading(true);
      const getPermissions: any = getLocalStorage("permissions");
      getPermissions && checkPermissions(JSON.parse(getPermissions));
    } catch (error) {
      setToast("error", "Something went wrong");
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [exception, permission]);

  const checkPermissions = (permissions: any[]) => {
    const newPermissions: any = {
      add: false,
      update: false,
      delete: false,
      restore: false,
      view: false,
    };
    permissions.forEach((perm: any) => {
      if (perm === checkPermisson?.viewPermission) {
        newPermissions.view = true;
      }
      if (perm === checkPermisson?.addPermission) {
        newPermissions.add = true;
      }
      if (perm === checkPermisson?.updatePermission) {
        newPermissions.update = true;
      }
      if (perm === checkPermisson?.deletePermission) {
        newPermissions.delete = true;
      }
      if (perm === checkPermisson?.restorePermission) {
        newPermissions.restore = true;
      }
      if (exception) {
        Object.keys(checkPermisson).forEach((key) => {
          if (perm === checkPermisson[key]) {
            newPermissions[checkPermisson[key]] = true;
          }
        });
      }
    });
    setPermissions(newPermissions);
  };
  if (loading) {
    return (
      <span>
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      </span>
    );
  } else {
    return React.cloneElement(element, {
      permissions,
    });
  }
};

export default AuthorizedRoute;
