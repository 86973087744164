import "bootstrap/dist/css/bootstrap.min.css";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { ProductionProcessType } from "../../../Model/Setting/ProductionProcessType";
import {
  PRODUCTION_PROCESS_TYPE_LIST,
  PRODUCTION_PROCESS_TYPE_TITLE,
} from "../../../Routes/Setting/ProductionProcessType";
import { useEffect, useState } from "react";
import { DynamicFormFields } from "../../../Util/DynamicFormFields";

const AddUpdate = () => {
  const [dynamicFormFields, setDynamicFormFields] = useState([]);

  const formFields = [
    {
      id: "inputname",
      label: "name*",
      type: "text",
      col: 12,
      name: "name",
      placeholder: "Enter name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "name is required",
        },
      ],
    },
  ];

  useEffect(() => {
    DynamicFormFields(formFields, "production_process_types").then(
      (updatedFormFields) => {
        setDynamicFormFields(updatedFormFields);
      },
    );
  }, []); // eslint-disable-line

  return (
    <AddUpdateForm
      formFields={dynamicFormFields}
      model={ProductionProcessType}
      title={PRODUCTION_PROCESS_TYPE_TITLE}
      listRoute={PRODUCTION_PROCESS_TYPE_LIST}
      entityName={"production-process-types"}
      entityType={"production_process_types"}
      isDynamicFormFields={true}
    />
  );
};

export default AddUpdate;
