import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavBarLayout from "./NavBarLayout";

export default function NotAccess() {
  const navigate = useNavigate();
  const goToHomepage = () => {
    navigate("/");
  };

  return (
    <>
      <NavBarLayout />
      <Container className="border-5" style={{ marginTop: "10rem" }}>
        <div className="row fluid">
          <div className="col-3"></div>
          <div className="col-6 text-center">
            <div className="notfound">
              <div className="notfound-404">
                <h5> You don't have access</h5>
              </div>
              <Button size="sm" variant="primary" onClick={goToHomepage}>
                Go To Homepage
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
