import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/Currency/List";
import AddUpdate from "../../Pages/Setting/Currency/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_CURRENCY,
  DELETE_CURRENCY,
  RESTORE_CURRENCY,
  UPDATE_CURRENCY,
  VIEW_CURRENCY,
} from "../../Util/PermissionList";

export const CURRENCY_TITLE = "Currency";
export const CURRENCY_LIST = "/currencies";
export const CURRENCY_ADD = "/currencies/add";
export const CURRENCY_EDIT = "/currencies/:id/edit";
export const CURRENCY_ARCHIVE = "/currencies/archive";

const Currency: RouteObject[] = [
  {
    path: CURRENCY_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_CURRENCY}
        checkPermisson={{
          addPermission: ADD_CURRENCY,
          updatePermission: UPDATE_CURRENCY,
          deletePermission: DELETE_CURRENCY,
          restorePermission: RESTORE_CURRENCY,
          viewPermission: VIEW_CURRENCY,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: CURRENCY_ADD,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate
            title={CURRENCY_TITLE}
            listRoute={CURRENCY_LIST}
            isNotUpdate={false}
          />
        }
        permission={ADD_CURRENCY}
        checkPermisson={{
          addPermission: ADD_CURRENCY,
          updatePermission: UPDATE_CURRENCY,
          deletePermission: DELETE_CURRENCY,
          restorePermission: RESTORE_CURRENCY,
          viewPermission: VIEW_CURRENCY,
        }}
      />
    ),
  },
  {
    path: CURRENCY_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate title={CURRENCY_TITLE} listRoute={CURRENCY_LIST} />}
        permission={UPDATE_CURRENCY}
        checkPermisson={{
          addPermission: ADD_CURRENCY,
          updatePermission: UPDATE_CURRENCY,
          deletePermission: DELETE_CURRENCY,
          restorePermission: RESTORE_CURRENCY,
          viewPermission: VIEW_CURRENCY,
        }}
      />
    ),
  },
  {
    path: CURRENCY_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_CURRENCY,
          updatePermission: UPDATE_CURRENCY,
          deletePermission: DELETE_CURRENCY,
          restorePermission: RESTORE_CURRENCY,
          viewPermission: VIEW_CURRENCY,
        }}
        permission={DELETE_CURRENCY}
      />
    ),
  },
];

export default Currency;
