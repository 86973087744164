import { RouteObject } from "react-router-dom";
import List from "../../Pages/SalesAndPurchase/Purchase/List";
import AddUpdate from "../../Pages/SalesAndPurchase/Purchase/AddUpdate";
import View from "../../Pages/SalesAndPurchase/Purchase/View";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
  VIEW_INWARD_DOCUMENT,
  VIEW_PURCHASE_ORDER,
} from "../../Util/PermissionList";

export const PURCHASE_TITLE = "Purchase Order";
export const PURCHASE_LIST = "/purchase-order";
export const PURCHASE_DASHBOARD_LIST = "/purchase-order/:date";
export const PURCHASE_ADD = "/purchase-order/add-order";
export const PURCHASE_VIEW = "/purchase-order/:id/view";
export const PURCHASE_EDIT = "/purchase-order/:updateid/edit-invoice";

const Purchase: RouteObject[] = [
  {
    path: PURCHASE_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_PURCHASE_ORDER}
        exception={true}
        checkPermisson={{
          addPermission: ADD_PURCHASE_ORDER,
          updatePermission: UPDATE_PURCHASE_ORDER,
          deletePermission: DELETE_PURCHASE_ORDER,
          viewPermission: VIEW_PURCHASE_ORDER,
          VIEW_INWARD_DOCUMENT,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: PURCHASE_DASHBOARD_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_PURCHASE_ORDER}
        exception={true}
        checkPermisson={{
          addPermission: ADD_PURCHASE_ORDER,
          updatePermission: UPDATE_PURCHASE_ORDER,
          deletePermission: DELETE_PURCHASE_ORDER,
          viewPermission: VIEW_PURCHASE_ORDER,
          VIEW_INWARD_DOCUMENT,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: PURCHASE_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_PURCHASE_ORDER}
        checkPermisson={{
          addPermission: ADD_PURCHASE_ORDER,
          updatePermission: UPDATE_PURCHASE_ORDER,
          deletePermission: DELETE_PURCHASE_ORDER,
          viewPermission: VIEW_PURCHASE_ORDER,
        }}
      />
    ),
  },
  {
    path: PURCHASE_VIEW,
    element: (
      <AuthorizedRoute
        element={<View />}
        permission={VIEW_PURCHASE_ORDER}
        checkPermisson={{
          addPermission: ADD_PURCHASE_ORDER,
          updatePermission: UPDATE_PURCHASE_ORDER,
          deletePermission: DELETE_PURCHASE_ORDER,
          viewPermission: VIEW_PURCHASE_ORDER,
        }}
      />
    ),
  },
  {
    path: PURCHASE_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_PURCHASE_ORDER}
        checkPermisson={{
          addPermission: ADD_PURCHASE_ORDER,
          updatePermission: UPDATE_PURCHASE_ORDER,
          deletePermission: DELETE_PURCHASE_ORDER,
          viewPermission: VIEW_PURCHASE_ORDER,
        }}
      />
    ),
  },
];

export default Purchase;
