import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/Attributes/List";
import AddUpdate from "../../Pages/Setting/Attributes/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  RESTORE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  VIEW_ATTRIBUTE,
} from "../../Util/PermissionList";

export const ATTRIBUTE_TITLE = "Attribute";
export const ATTRIBUTE_LIST = "/attributes";
export const ATTRIBUTE_ADD = "/attributes/add";
export const ATTRIBUTE_EDIT = "/attributes/:id/edit";
export const ATTRIBUTE_ARCHIVE = "/attributes/archive";

const Attribute: RouteObject[] = [
  {
    path: ATTRIBUTE_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_ATTRIBUTE}
        checkPermisson={{
          viewPermission: VIEW_ATTRIBUTE,
          addPermission: ADD_ATTRIBUTE,
          updatePermission: UPDATE_ATTRIBUTE,
          deletePermission: DELETE_ATTRIBUTE,
          restorePermission: RESTORE_ATTRIBUTE,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: ATTRIBUTE_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_ATTRIBUTE}
        checkPermisson={{
          viewPermission: VIEW_ATTRIBUTE,
          addPermission: ADD_ATTRIBUTE,
          updatePermission: UPDATE_ATTRIBUTE,
          deletePermission: DELETE_ATTRIBUTE,
          restorePermission: RESTORE_ATTRIBUTE,
        }}
      />
    ),
  },
  {
    path: ATTRIBUTE_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_ATTRIBUTE}
        checkPermisson={{
          viewPermission: VIEW_ATTRIBUTE,
          addPermission: ADD_ATTRIBUTE,
          updatePermission: UPDATE_ATTRIBUTE,
          deletePermission: DELETE_ATTRIBUTE,
          restorePermission: RESTORE_ATTRIBUTE,
        }}
      />
    ),
  },
  {
    path: ATTRIBUTE_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          viewPermission: VIEW_ATTRIBUTE,
          addPermission: ADD_ATTRIBUTE,
          updatePermission: UPDATE_ATTRIBUTE,
          deletePermission: DELETE_ATTRIBUTE,
          restorePermission: RESTORE_ATTRIBUTE,
        }}
        permission={DELETE_ATTRIBUTE}
      />
    ),
  },
];

export default Attribute;
