import { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomDataTable from "../../../Components/CustomDataTable";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import {
  DOCUMENT_NUMBER_FORMAT_EDIT,
  DOCUMENT_NUMBER_FORMAT_TITLE,
} from "../../../Routes/Setting/DocumentNumberFormat";
import { Row } from "react-bootstrap";
import pluralize from "pluralize";
import { DocumentNumberFormat } from "../../../Model/Setting/DocumentNumberFormat";
import { fetchData } from "../../../Util/OrionList";
import { setToast } from "../../../Util/Toast";

interface DocumentNumberFormatItem {
  id: number;
  document_type: string;
  document_series_type: string;
  series_name: string;
  prefix_number: string;
  next_number: string;
  created_at: string;
  updated_at: string;
}

function List() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [data, setData] = useState<DocumentNumberFormatItem[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 20
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
    documentType: string,
  ) => {
    e.preventDefault();
    navigate(
      DOCUMENT_NUMBER_FORMAT_EDIT.replace(":id", id.toString()).replace(
        ":document_type",
        documentType,
      ),
    );
  };

  const columns: TableColumn<DocumentNumberFormatItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Document Type",
      cell: (row) => row.document_type,
    },
    {
      name: "Series Name",
      cell: (row) => row.series_name,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => (
        <div>
          <CustomEditButton
            id={row.id}
            onClick={(e) =>
              handleRowEditButtonClick(e, row.id, row.document_type)
            }
          />
        </div>
      ),
    },
  ];

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "document_type",
      value: "document_type",
      label: "Document Type",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "series_name",
      value: "series_name",
      label: "Series Name",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
  ];

  // @ts-ignore
  const fetchDocumentNumberFormat = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new DocumentNumberFormat(),
        sortField,
        sortOrder,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchDocumentNumberFormat();
  }, [limit, page]); // eslint-disable-line

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  return (
    <>
      <div className="mb-4">
        <h2>{pluralize(DOCUMENT_NUMBER_FORMAT_TITLE)} List</h2>
      </div>
      <Row>
        <CustomFilter
          options={filterOptions}
          inputValues={inputValues}
          setInputValues={setInputValues}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          getData={fetchDocumentNumberFormat}
          setPage={setPage}
        />
      </Row>
      <CustomDataTable
        columns={columns}
        data={data}
        progressPending={loading}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        handleRowCreateButtonClick={(e) => {}}
        paginationDisable={false}
        currentPages={page}
        currentRowsPerPage={limit}
        module={"DocumentNumberFormat"} // Pass optional fields to CustomDataTable
      />
    </>
  );
}

export default List;
