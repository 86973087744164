import "bootstrap/dist/css/bootstrap.min.css";
import { Permission } from "../../../Model/Setting/Permission";

import {
  PERMISSION_LIST,
  PERMISSION_TITLE,
} from "../../../Routes/Setting/Permission";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { Button } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import { useState } from "react";

const AddUpdate = () => {
  const formFields = [
    {
      id: "inputName",
      label: "Name*",
      type: "text",
      col: 12,
      name: "name",
      placeholder: "Enter Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Name is required",
        },
      ],
      description:
        "Define the permissions for the new sub-user based on their role or responsibilities within the organization.",
    },
    {
      id: "inputSlug",
      label: "Slug*",
      type: "text",
      col: 12,
      name: "slug",
      placeholder: "Enter Slug",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Slug is required",
        },
      ],
      description:
        "Create a unique slug associated with these permissions. Use hyphens or underscores to separate words. Example:content- creator - permission.",
    },
    {
      id: "inputActive",
      label: "add",
      type: "checkbox",
      col: 2,
      name: "add",
      defaultValue: false,
    },
    {
      id: "inputActive",
      label: "view",
      type: "checkbox",
      col: 2,
      name: "view",
      defaultValue: false,
    },
    {
      id: "inputActive",
      label: "upadate",
      type: "checkbox",
      col: 2,
      name: "upadate",
      defaultValue: false,
    },
    {
      id: "inputActive",
      label: "delete",
      type: "checkbox",
      col: 2,
      name: "delete",
      defaultValue: false,
    },
    {
      id: "inputActive",
      label: "restore",
      type: "checkbox",
      col: 2,
      name: "restore",
      defaultValue: false,
    },
  ];
  const [isShows, setIsShows] = useState<boolean>(false);
  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>

        <AddUpdateForm
          formFields={formFields}
          model={Permission}
          title={PERMISSION_TITLE}
          listRoute={PERMISSION_LIST}
        />
      </div>
      <div id="form-bottom">
        <FieldDescription
          title={PERMISSION_TITLE}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
