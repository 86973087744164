import "bootstrap/dist/css/bootstrap.min.css";
import { BankDetail } from "../../../Model/Setting/BankDetails";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import FieldDescription from "../../../Components/FieldDescription";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { DynamicFormFields } from "../../../Util/DynamicFormFields";
interface AddUpdateProps {
  title?: any;
  listRoute?: any;
  isNotUpdate?: any;
  onChangeModalStatus?: (status: boolean) => void;
}
const AddUpdate: React.FC<AddUpdateProps> = ({
  title,
  listRoute,
  isNotUpdate,
  onChangeModalStatus,
}) => {
  const formFields = [
    {
      id: "inputbank_name",
      label: "Bank Name*",
      type: "text",
      col: 6,
      name: "bank_name",
      placeholder: "Enter Your Bank Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Bank Name is required",
        },
      ],
      description: "Enter the full name of the bank where the account is held.",
    },
    {
      id: "inputAccountNumber",
      label: "Account Number/IBAN Number*",
      type: "text",
      col: 6,
      name: "account_number",
      placeholder: "Enter Your Account Number",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Account Number is required",
        },
      ],
      description:
        "Specify the unique account number associated with the bank account.",
    },
    {
      id: "inputaccount_name",
      label: "Account Name*",
      type: "text",
      col: 6,
      name: "account_name",
      placeholder: "Enter Your Account Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Account Name is required",
        },
      ],
      description: "Enter the full name of your account.",
    },
    {
      id: "inputbranch",
      label: "Branch*",
      type: "text",
      col: 6,
      name: "branch",
      placeholder: "Enter Your Branch",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Branch is required",
        },
      ],
      description: "Indicate the branch or location of the bank.",
    },
    {
      id: "inputswift_code",
      label: " Swift Code",
      type: "text",
      col: 6,
      name: "swift_code",
      placeholder: "Enter Your Swift Code",
      description:
        "Include the SWIFT code, which is used for international transactions.",
    },
    {
      id: "inputmicr",
      label: "MICR",
      type: "text",
      col: 6,
      name: "micr",
      placeholder: "Enter Your MICR",
      description: "Include the MICR code for check processing.",
    },
    {
      id: "inputaddress",
      label: "Address",
      type: "text",
      col: 6,
      name: "address",
      placeholder: "Enter Your Address",
      description: "Provide the complete address of the bank branch.",
    },
  ];
  const [isShows, setIsShows] = useState<boolean>(false);
  const [dynamicFormFields, setDynamicFormFields] = useState([]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  useEffect(() => {
    DynamicFormFields(formFields, "bank_details").then((updatedFormFields) => {
      setDynamicFormFields(updatedFormFields);
    });
  }, []); // eslint-disable-line

  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={dynamicFormFields}
          model={BankDetail}
          title={title}
          listRoute={listRoute}
          entityName={"bank-details"}
          entityType={"bank_details"}
          isDynamicFormFields={true}
          isNotUpdate={isNotUpdate}
          onChangeModalStatus={onChangeModalStatus}
        />
      </div>
      <div id="form-bottom">
        <FieldDescription
          title={title}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
