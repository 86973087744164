import { Model } from "@tailflow/laravel-orion/lib/model";

export class LogisticDetail extends Model<{
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "logistic-details";
  }
}
