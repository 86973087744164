import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { Permission } from "../../../Model/Setting/Permission";
import { ROLE_PERMISSION_TITLE } from "../../../Routes/Setting/RolePermission";
import { setToast } from "../../../Util/Toast";
import { useApi } from "../../../Controller/ApiController";
import { useEffect, useState } from "react";
import CustomSkeleton from "../../../Components/CustomSkeleton";

const AddUpdate: React.FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(100);
  const [totalData, setTotalData] = useState(0);
  const { apiGet, apiPatch } = useApi();
  const [permissionslist, setPermissionslist] = useState<any[]>([]);
  const navigate = useNavigate();
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
  const [selectAllPermissions, setSelectAllPermissions] = useState<
    Record<string, boolean>
  >({});

  const handleSelectAllToggle = (heading: string) => {
    setSelectAllPermissions((prevSelectAllPermissions) => ({
      ...prevSelectAllPermissions,
      [heading]: !prevSelectAllPermissions[heading],
    }));

    const allPermissionIds = groupedPermissions[heading].map(
      (item: any) => item.id,
    );
    setSelectedPermissions((prevSelected) =>
      selectAllPermissions[heading]
        ? prevSelected.filter((id) => !allPermissionIds.includes(id))
        : [...prevSelected, ...allPermissionIds],
    );
  };

  const checkall = () => {
    const allPermissionIds: number[] = [];

    // Collect all permission ids from groupedPermissions
    Object.values(groupedPermissions).forEach((permissions: any) => {
      permissions.forEach((item: any) => {
        allPermissionIds.push(item.id);
      });
    });

    // Update selectedPermissions based on the state of selectAllPermissions
    setSelectedPermissions(
      (prevSelected) =>
        selectAllPermissions["all"]
          ? [] // if all is selected, clear the selectedPermissions
          : [...Array.from(new Set([...prevSelected, ...allPermissionIds]))], // add all ids to selectedPermissions
    );

    // Toggle the state of selectAllPermissions for all headings
    setSelectAllPermissions((prevSelectAllPermissions) => {
      const newSelectAllPermissions: any = {
        ...prevSelectAllPermissions,
        all: !prevSelectAllPermissions["all"],
      };

      // Update selectAllPermissions for each heading
      Object.keys(groupedPermissions).forEach((heading) => {
        newSelectAllPermissions[heading] = !prevSelectAllPermissions["all"];
      });

      return newSelectAllPermissions;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const permissionData = await Permission.$query().get(limit, 1);
        setPermissionslist(permissionData[0]?.$response?.data.data);
        setTotalData(permissionData[0]?.$response?.data?.meta?.total);
        setLoading(false);

        if (totalData) {
          const rolePermissionData: any = await apiGet(
            `roles/${id}/permissions?limit=${totalData}`,
          );
          // Extract ids from the response
          const rolePermissionIds = rolePermissionData.data.map(
            (item: any) => item.id,
          );

          // Set the selectedPermissions with the ids
          setSelectedPermissions(rolePermissionIds);
        }
      } catch (error: any) {
        if (error.response?.data?.errors) {
          setToast("error", error.response.data.errors);
        } else if (error.response?.data?.message) {
          setToast("error", error.response.data.message);
        } else {
          setToast("error", "An error occurred while processing your request");
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, limit, totalData]);

  const groupedPermissions: Record<string, Permission[]> =
    permissionslist.reduce((acc, permission) => {
      const [checkboxLabel, ...rest] = permission.name.split("_");
      const heading = rest.join("_").replace(/_/g, " "); // Remove underscores from heading
      if (!acc[heading]) {
        acc[heading] = [];
      }
      acc[heading].push({ ...permission, checkboxLabel });
      return acc;
    }, {});

  useEffect(() => {
    const allSelected = Object.values(groupedPermissions).every(
      (permissions: any) =>
        permissions.every((item: any) => selectedPermissions.includes(item.id)),
    );

    setSelectAllPermissions((prevSelectAllPermissions) => {
      const newSelectAllPermissions: any = {
        ...prevSelectAllPermissions,
        all: allSelected,
      };

      Object.keys(groupedPermissions).forEach((heading) => {
        const allGroupSelected = groupedPermissions[heading].every(
          (item: any) => selectedPermissions.includes(item.id),
        );
        newSelectAllPermissions[heading] = allGroupSelected;
      });

      return newSelectAllPermissions;
    });
  }, [selectedPermissions, groupedPermissions]);

  const handlePermissionToggle = (permissionId: any) => {
    // Toggle the selected state of the permission
    const isSelected = selectedPermissions.includes(permissionId);

    if (isSelected) {
      // If already selected, remove it
      setSelectedPermissions((prevSelected) =>
        prevSelected.filter((id) => id !== permissionId),
      );
    } else {
      // If not selected, add it
      setSelectedPermissions((prevSelected) => [...prevSelected, permissionId]);
    }
  };

  const handleCustomSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    const inputData = {
      resources: selectedPermissions,
    };

    try {
      await apiPatch(`roles/${id}/permissions/sync`, inputData);
      navigate(-1);
      setToast("success", `${ROLE_PERMISSION_TITLE} Successfully Updated`);
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  if (loading) {
    const skeletons = Array.from({ length: 5 }).map((_, index) => (
      <CustomSkeleton
        key={index}
        count={1}
        height={95}
        customOuterStyle={{ marginTop: "10px", width: "100%" }}
      />
    ));
    return <>{skeletons}</>;
  }

  return (
    <>
      <div className="row">
        <form onSubmit={handleCustomSubmit}>
          <div>
            <input
              type="checkbox"
              id="selectAllCheckbox"
              className="form-check-input border-dark text-capitalize mx-1"
              onChange={checkall}
              checked={selectAllPermissions.all}
            />
            <label htmlFor="selectAllCheckbox" className="fw-bolder">
              Select All Permissions
            </label>
          </div>

          {Object.entries(groupedPermissions).map(
            ([heading, permissions]: any) => (
              <div className="col-md-12 bg-light" key={heading}>
                <div className="mt-3 mx-3 d-flex justify-content-between align-items-center">
                  <div>
                    <h3 className="mt-3 mx-2 d-inline-block text-capitalize">
                      {heading.toLowerCase()}
                    </h3>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id={`selectall-${heading}`}
                      style={{ marginTop: "0.8rem", marginRight: "5px" }}
                      className="form-check-input border-dark text-capitalize"
                      checked={selectAllPermissions[heading]}
                      onChange={() => handleSelectAllToggle(heading)}
                    />
                    <label
                      htmlFor={`selectall-${heading}`}
                      className="fw-bolder"
                      style={{ marginTop: "0.5rem" }}
                    >
                      Select All
                    </label>
                  </div>
                </div>
                {permissions.map((item: any) => (
                  <div
                    key={item.id}
                    className="d-inline-block col-2 mb-3"
                    style={{ marginLeft: "2rem" }}
                  >
                    <input
                      type="checkbox"
                      style={{ marginRight: "4px" }}
                      className="form-check-input border-dark"
                      id={`permission-${item.id}`}
                      checked={selectedPermissions.includes(item.id)}
                      onChange={() => handlePermissionToggle(item.id)}
                    />
                    <label
                      className="fw-bolder form-check-label ms-1 text-capitalize"
                      htmlFor={`permission-${item.id}`}
                    >
                      {item.checkboxLabel.toLowerCase()}
                    </label>
                  </div>
                ))}
              </div>
            ),
          )}

          {totalData >= limit && (
            <p
              style={{
                cursor: "pointer",
                textAlign: "end",
                marginBottom: "-1rem",
              }}
              className="text-primary me-3 my-2"
              onClick={() => setLimit((prevLimit) => prevLimit + 100)}
            >
              Show More...
            </p>
          )}
          <div className="my-3 d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default AddUpdate;
