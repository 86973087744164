import "bootstrap/dist/css/bootstrap.min.css";
import { useDateFormat } from "../../../Util/InputDateFormate";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import CustomButton from "../../../Components/Button/CustomButton";
import { Product } from "../../../Model/Inventory/Product";
import CustomAsyncSelect from "../../../Components/AsyncSelect";
import { UnitOfMeasurement } from "../../../Model/Setting/UnitOfMeasurement";
import { Tax } from "../../../Model/Setting/Tax";
import { setToast } from "../../../Util/Toast";
import { useApi } from "../../../Controller/ApiController";
import { PaymentTerms } from "../../../Model/Setting/PaymentTerms";
import { Store } from "../../../Model/Setting/Store";
import { TermsAndCondition } from "../../../Model/Setting/TermsAndCondition";
import { LogisticDetail } from "../../../Model/Setting/LogisticDetail";
import { TAX_INVOICE_TITLE } from "../../../Routes/SalesAndPurchase/TaxInvoice";
import { TaxInvoice } from "../../../Model/SalesAndPurchase/TaxInvoice/TaxInvoice";
import { PurchaseOrder } from "../../../Model/SalesAndPurchase/PurchaseOrder/PurchaseOrder";
import { BankDetail } from "../../../Model/Setting/BankDetails";
import React from "react";
import {
  DateFormatWithStartOFTheDay,
  DateString,
} from "../../../Util/DateFormat";
import { AmountFormat } from "../../../Util/AmountFormat";
import { getUser } from "../../../Util/Auth";
import { Currency } from "../../../Model/Setting/Currency";
import ActivityLogs from "../../../Components/ActivityLogs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BatchManagement } from "../../../Model/SalesAndPurchase/BatchManagement/BatchManagement";
import ConfirmationModal from "../../../Components/ConfirmationModel";
import SignatureForDocuments from "../../../Components/SignatureForDocuments";
import AttachmentsForDocuments from "../../../Components/AttachmentsForDocuments";
import CreateRow from "../../../Components/CreateRow";
import {
  CalculateRowTotalTaxs,
  CalculateTotalBeforeTaxs,
  calculateExtraTotal,
  calculateExtraTotalTax,
  calculateGrandTotal,
  totalWithExtra,
  totaltaxWithExtra,
} from "../../../Util/Calculator";
import FormSkeleton from "../../../Components/FormSkeleton";
import moment from "moment";
import {
  PAYMENTTERMS_LIST,
  PAYMENTTERMS_TITLE,
} from "../../../Routes/Setting/PaymentTerms";
import AddUpdatePaymentTerm from "../../Setting/PaymentTerms/AddUpdate";
import AddUpdateLogisticDetail from "../../Setting/LogisticDetail/AddUpdate";
import AddUpdateCurrency from "../../Setting/Currency/AddUpdate";
import AddUpdateTermsAndCondition from "../../Setting/TermsAndCondition/AddUpdate";
import AddUpdateBankDetail from "../../Setting/BankDetails/AddUpdate";
import AddUpdateStore from "../../Setting/Store/AddUpdate";
import { STORE_LIST, STORE_TITLE } from "../../../Routes/Setting/Store";
import {
  TERMSANDCONDITION_LIST,
  TERMSANDCONDITION_TITLE,
} from "../../../Routes/Setting/TermsAndCondition";
import {
  LOGISTICDETAILS_LIST,
  LOGISTICDETAILS_TITLE,
} from "../../../Routes/Setting/LogisticDetail";
import {
  BANK_DETAILS_LIST,
  BANK_DETAILS_TITLE,
} from "../../../Routes/Setting/BankDetails";
import { PRODUCT_LIST, PRODUCT_TITLE } from "../../../Routes/Inventory/Product";
import AddUpdateProduct from "../../../Pages/Inventory/Product/AddUpdate";
import AddUpdateUMO from "../../Setting/UnitOfMeasurement/AddUpdate";
import AddUpdateTax from "../../Setting/Tax/AddUpdate";
import {
  UNIT_OF_MEASUREMENT_LIST,
  UNIT_OF_MEASUREMENT_TITLE,
} from "../../../Routes/Setting/UnitOfMeasurement";
import { TAX_LIST, TAX_TITLE } from "../../../Routes/Setting/Tax";
import {
  CURRENCY_LIST,
  CURRENCY_TITLE,
} from "../../../Routes/Setting/Currency";
import { CounterPartyAddress } from "../../../Model/BuyersAndSuppliers/CounterPartyAddress/CounterPartyAddress";
import AddressSelecter from "../../../Components/Address/Address";
import { BuyersAndSuppliers } from "../../../Model/BuyersAndSuppliers/BuyersAndSuppliers";
import { OrganizationAddress } from "../../../Model/Setting/OrganizationAddress";
import { showErrorsInToasts } from "../../../Util/ErrorMessageToast";

interface currencies {
  label: string;
  value: string;
}
interface Currencys {
  id: string;
  symbol: string;
}
type ProductDetails = {
  id: string;
  position: number | null;
  // "hsn/sac_code": string;
  quantity: number | null;
  delivery_date?: string | null;
  unit_of_measurement_id: string;
  price: number | null;
  price_type: string;
  discount?: string | null;
  discount_type?: string | null;
  total_price: number | null;
  total_tax: number | null;
  products_id: string;
  batches_id: string;
  comments?: string | null;
  product: {
    id: string;
    products_id: string;
    product_name: string;
  };
  tax: {
    id: string;
    rate: number | null;
  };
  unit: {
    id: string;
    name: string;
  };
};

const AddUpdate = () => {
  const user = getUser();
  const dateFormat = useDateFormat();
  const navigate = useNavigate();
  const { apiGet, apiPost } = useApi();
  const [products, setProducts] = useState<ProductDetails[]>([]);
  const [hideShowField, setHideShowField] = useState<any>([
    { id: 1, label: "discount", fieldStatus: false },
    { id: 2, label: "delivery_date", fieldStatus: false },
    { id: 3, label: "comments", fieldStatus: false },
  ]);
  const [extraExpenses, setExtraExpenses] = useState<any[]>([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [productFormErrors, setProductFormErrors] = useState<any>({});
  const [productDetails] = useState<ProductDetails>({
    id: "",
    position: null,
    // "hsn/sac_code": "",
    quantity: null,
    delivery_date: "",
    unit_of_measurement_id: "",
    price: null,
    price_type: "",
    discount: "",
    discount_type: "",
    total_price: null,
    total_tax: null,
    batches_id: "",
    products_id: "",
    comments: "",
    product: {
      id: "",
      products_id: "",
      product_name: "",
    },
    tax: {
      id: "",
      rate: null,
    },
    unit: {
      id: "",
      name: "",
    },
  });
  const [selectedCompanyOption, setSelectedCompanyOption] = useState({
    label: "",
    value: "",
  });
  const [
    selectedManufactureCompanyOption,
    setSelectedManufactureCompanyOption,
  ] = useState({
    label: "",
    value: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [showExtraCharges, setShowExtraCharges] = useState(false);
  const [showAttachment, setShowAttachment] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [showAttachmentSignature, setShowAttachmentSignature] = useState(false);
  const [attachmentId, setAttachmentId] = useState("");
  const [signatureId, setSignatureId] = useState("");
  const [isAttchmentExist, setIsAttchmentExist] = useState(false);
  const [isSignatureExist, setIsSignatureExist] = useState(false);
  const [customSubmit, setcustomSubmit] = useState(false);
  const [signaturecustomSubmit, setSignaturecustomSubmit] = useState(false);
  const [selectedAttchmentFile, setSelectedAttchmentFile] = useState(false);
  const [selectedSignatureFile, setSelectedSignatureFile] = useState(false);
  const [attchmentTitle, setAttchmentTitle] = useState("");
  const [showLogisticDetails, setShowLogisticDetails] = useState(false);
  const [showTermsandcondition, setShowTermsandcondition] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState<any>(null);
  const [paymentTermsError, setPaymentTermsError] = useState<any>("");
  const [store, setStore] = useState<any>({});
  const [termsAndCondition, setTermsAndCondition] = useState<any>(null);
  const [bankDetails, setBankDetails] = useState<any>(null);
  const [logisticDetail, setLogisticDetail] = useState<any>(null);
  const [storeError, setStoreError] = useState("");
  const { updateid }: any = useParams();
  // const [buyerId, setBuyerId] = useState<string | null>(null);
  const [selectedBuyerBillingAddress, setSelectedBuyerBillingAddress] =
    useState<any>(null);
  const [selectedBuyerDeliveryAddress, setSelectedBuyerDeliveryAddress] =
    useState<any>(null);
  // const [selectedManufacturerId, setSelectedManufacturerId] =
  //   useState<any>(null);
  // const [manufacturerId, setMmanufacturerId] = useState<string | null>(null);
  const [selectedSupplierBillingAddress, setSelectedSupplierBillingAddress] =
    useState<any>(null);
  const [productsModalStatus, setProductsModalStatus] =
    useState<boolean>(false);
  const [UMOModalStatus, setUMOModalStatus] = useState<boolean>(false);
  const [taxModalStatus, setTaxModalStatus] = useState<boolean>(false);
  const [currencyModalStatus, setCurrencyModalStatus] =
    useState<boolean>(false);
  const [paymentTermModalStatus, setPaymentTermModalStatus] =
    useState<boolean>(false);
  const [storeModalStatus, setStoreModalStatus] = useState<boolean>(false);
  const [logistcDetailModalStatus, setLogistcDetailModalStatus] =
    useState<boolean>(false);
  const [termsConditionsModalStatus, setTermsConditionsModalStatus] =
    useState<boolean>(false);
  const [bankDetailsModalStatus, setBankDetailsModalStatus] =
    useState<boolean>(false);
  // NEW END
  const [
    selectedManufacturerBillingAddress,
    setSelectedManufacturerBillingAddress,
  ] = useState<any>(updateid ? {} : null);

  useEffect(() => {
    if (isAttchmentExist) {
      setShowAttachment(true);
    }
    // eslint-disable-next-line
  }, [isAttchmentExist]);
  const [
    loadingForPrimaryDocumentDetails,
    setLoadingForPrimaryDocumentDetails,
  ] = useState(true);
  const [title, setTitle] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  const [titleError, setTitleError] = useState("");
  const [ponumber, setPONumber] = useState<any>(null);
  const [ocnumber, setOCNumber] = useState<any>(null);
  const [documentDate, setDocumentDate] = useState<any>(null);
  const [poDate, setPODate] = useState<any>(null);
  const [ocDate, setOCDate] = useState<any>(null);
  const [paymentDate, setPaymentDate] = useState<any>(null);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const [deliveryLocationError, setDeliveryLocationError] = useState<
    string | null
  >(null);
  const [buyerDetailsError, setBuyerDetailsError] = useState<string | null>(
    null,
  );
  const [supplierBillingAddressError, setSupplierBillingAddressError] =
    useState<string | null>(null);
  const [documentDateError, setDocumentDateError] = useState<string | null>(
    null,
  );
  const [showConfirmationForSendData, setShowConfirmationForSendData] =
    useState(false);
  const [showConfirmationForGoBack, setShowConfirmationForGoBack] =
    useState(false);
  const [paymentDateError, setPaymentDateError] = useState<string | null>(null);
  const [ocDateError, setOcDateError] = useState<string | null>(null);
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [documentnumber, setDocumentNumber] = useState<any | null>(null);
  const [kindAttention, setKindAttention] = useState<any>(null);
  // const [supplierBillingAddressId, setSupplierBillingAddressId] = useState<
  //   string | null
  // >(null);
  // const [buyerDeliveryAddressId, setBuyerDeliveryAddressId] = useState<
  //   string | null
  // >(null);
  // const [buyerBillingAddressId, setBuyerBillingAddressId] = useState<
  //   string | null
  // >(null);
  const [deliveryNote, setDeliveryNote] = useState<any>(null);
  const [invoiceComment, setInvoiceComment] = useState<any>("");
  const [termsAndConditionError, setTermsAndConditionError] = useState<any>("");
  const [logisticDetailsError, setLogisticDetailsError] = useState<any>("");
  const [bankDetailsError, setBankDetailsError] = useState<any>("");
  const [attachmentSignatureError, setAttachmentSignatureError] =
    useState<any>("");
  const [attachmentError, setAttachmentError] = useState<any>("");
  const [selectedCurrency, setSelectedCurrency] = useState<currencies | any>(
    null,
  );
  const [currencyData, setCurrencyData] = useState<Currencys[] | null>(null);
  useEffect(() => {
    if (isAttchmentExist) {
      setShowAttachment(true);
    }
    if (isSignatureExist) {
      setShowAttachmentSignature(true);
    }
  }, [isAttchmentExist, isSignatureExist]);
  const [formData, setFormData] = useState<any>([]);
  const [extraExpensesFormErrors, setExtraExpensesFormErrors] = useState<any>(
    {},
  );
  const [extraExpensesDetails] = useState<any>({
    position: null,
    extra_charge_description: "",
    total: "",
    tax: { id: "", tax_name: "", rate: "" },
    total_tax: "",
    total_amount: "",
  });

  const [primaryDetails, setPrimaryDetails] = useState({
    title: false,
    document_number: false,
    payment_terms: false,
    store: false,
    document_date: false,
    payment_date: false,
  });
  // const handleCloseDeliveryAddress = () => setShowDeliveryAddress(false);
  // const handleShowDeliveryAddress = () => setShowDeliveryAddress(true);
  // const handleCloseBillingAddress = () => setShowBillingAddress(false);
  // const handleShowBillingAddress = () => setShowBillingAddress(true);
  const getCurrencyData = async () => {
    try {
      const item = await apiGet(`currencies`);
      setCurrencyData(item.data);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setToast("error", error.response.data.errors);
      } else if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };

  useEffect(() => {
    getCurrencyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productTableFields = [
    {
      type: "CustomAsyncSelect",
      label: "product",
      headerLabel: "Item*",
      customFilter: [
        {
          field: "type",
          operator: "in",
          value: ["sell", "both"],
        },
      ],
      modal: Product,
      isMenuPortalTarget: true,
      inputField: "product_name",
      fieldName: "product_name",
      submitFieldName: "products_id",
      isMultiSelect: false,
      isNeedFullData: true,
      onChangeItemValue: (item: any) => {
        changePrice(item, "default_price", "price_type");
      },
      onChangeModalStatus: (status: boolean) => {
        setProductsModalStatus(status);
      },
      modalStatus: productsModalStatus,
      widget: (
        <AddUpdateProduct
          title={PRODUCT_TITLE}
          listRoute={PRODUCT_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) =>
            setProductsModalStatus(status)
          }
        />
      ),
    },
    {
      type: "CustomAsyncSelect",
      label: "batch",
      headerLabel: "Batch*",
      test: true,
      modal: BatchManagement,
      isMenuPortalTarget: true,
      inputField: "batch_number",
      fieldName: "batch_number",
      submitFieldName: "batches_id",
      isMultiSelect: false,
      isNeedFullData: true,
      addNewBtnStatus: false,
    },
    // {
    //   type: "text",
    //   label: "hsn/sac_code",
    //   headerLabel: "HSC/SAC Code",
    //   fieldName: "hsn/sac_code",
    // },
    {
      type: "number",
      label: "quantity",
      headerLabel: "Quantity*",
      fieldName: "quantity",
      submitFieldName: "quantity",
    },
    {
      type: "CustomAsyncSelect",
      label: "unit",
      headerLabel: "Unit*",
      modal: UnitOfMeasurement,
      isMenuPortalTarget: true,
      inputField: "name",
      fieldName: "name",
      submitFieldName: "unit_of_measurement_id",
      isMultiSelect: false,
      isNeedFullData: true,
      onChangeModalStatus: (status: boolean) => setUMOModalStatus(status),
      modalStatus: UMOModalStatus,
      widget: (
        <AddUpdateUMO
          title={UNIT_OF_MEASUREMENT_TITLE}
          listRoute={UNIT_OF_MEASUREMENT_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setUMOModalStatus(status)}
        />
      ),
    },
    {
      type: "text",
      label: "price",
      headerLabel: "Price*",
      fieldName: "price",
      submitFieldName: "price",
    },
    {
      type: "simpleSelect",
      label: "price_type",
      headerLabel: "Price Type",
      onChangeItemValue: (item: any, selectedValue: any) =>
        changePriceType(item, item.product[selectedValue.value], "price"),
      options: [
        {
          label: "Default Price",
          value: "default_price",
        },
        {
          label: "Regular Selling Price",
          value: "regular_selling_price",
        },
        {
          label: "Wholesale Selling Price",
          value: "wholesale_selling_price",
        },
        {
          label: "Dealer Price",
          value: "dealer_price",
        },
        {
          label: "Distributor Price",
          value: "distributor_price",
        },
      ],
      fieldName: "price_type",
    },
    {
      type: "text",
      label: "discount",
      fieldShow: hideShowField[0].fieldStatus,
      headerLabel: "Discount",
      fieldName: "discount",
    },
    {
      type: "simpleSelect",
      label: "discount_type",
      headerLabel: "Discount Type",
      fieldShow: hideShowField[0].fieldStatus,
      options: [
        { label: "flat", value: "flat" },
        { label: "%", value: "pct" },
      ],
      fieldName: "discount_type",
    },
    {
      type: "CustomAsyncSelect",
      label: "tax",
      headerLabel: "Tax(%)",
      modal: Tax,
      isMenuPortalTarget: true,
      inputField: "rate",
      fieldName: "rate",
      submitFieldName: "tax_id",
      isMultiSelect: false,
      isNeedFullData: true,
      onChangeModalStatus: (status: boolean) => setTaxModalStatus(status),
      modalStatus: taxModalStatus,
      widget: (
        <AddUpdateTax
          title={TAX_TITLE}
          listRoute={TAX_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setTaxModalStatus(status)}
        />
      ),
    },
    {
      type: "readOnly",
      label: "total_before_tax",
      headerLabel: "Total Before Tax",
      fieldName: "total_before_tax",
      fieldValue: (item: any) => {
        return AmountFormat(CalculateTotalBeforeTaxs(item));
      },
    },
    {
      type: "readOnly",
      label: "total_tax",
      headerLabel: "Total Tax",
      fieldName: "total_tax",
      fieldValue: (item: any) => {
        return AmountFormat(CalculateRowTotalTaxs(item));
      },
    },
    {
      type: "readOnly",
      label: "total_after_tax",
      headerLabel: "Total After Tax",
      fieldName: "total_after_tax",
      fieldValue: (item: any) => {
        return AmountFormat(
          CalculateRowTotalTaxs(item) + CalculateTotalBeforeTaxs(item),
        );
      },
    },
    {
      type: "datePicker",
      label: "delivery_date",
      datePicker: true,
      fieldShow: hideShowField[1].fieldStatus,
      headerLabel: "Delivery Date",
      fieldName: "delivery_date",
    },
    {
      type: "text",
      label: "comments",
      fieldShow: hideShowField[2].fieldStatus,
      headerLabel: "Comments",
      fieldName: "comments",
    },
  ];

  const extraChargeTableFields = [
    {
      type: "text",
      label: "extra_charge_description",
      headerLabel: "Extra Charge Description*",
      fieldName: "extra_charge_description",
      submitFieldName: "extra_charge_description",
    },
    {
      type: "text",
      label: "total",
      headerLabel: "Amount*",
      fieldName: "total",
      submitFieldName: "total",
    },
    {
      type: "CustomAsyncSelect",
      label: "tax",
      headerLabel: "Tax(%)",
      modal: Tax,
      isMenuPortalTarget: true,
      inputField: "rate",
      fieldName: "rate",
      submitFieldName: "tax_id",
      isMultiSelect: false,
      isNeedFullData: true,
      onChangeModalStatus: (status: boolean) => setTaxModalStatus(status),
      modalStatus: taxModalStatus,
      widget: (
        <AddUpdateTax
          title={TAX_TITLE}
          listRoute={TAX_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setTaxModalStatus(status)}
        />
      ),
    },
    {
      type: "readOnly",
      label: "total_tax",
      headerLabel: "Total Tax",
      fieldName: "total_tax",
      fieldValue: (item: any) => {
        return AmountFormat(calculateExtraTotalTax(item));
      },
    },
    {
      type: "readOnly",
      label: "total_amount",
      headerLabel: "Total",
      fieldName: "total_amount",
      fieldValue: (item: any) => {
        return AmountFormat(calculateExtraTotal(item));
      },
    },
  ];

  const changePriceType = (
    item: any,
    selectedValue: any,
    fieldName: string,
  ) => {
    setProducts((prevProducts) => {
      return prevProducts.map((product) => {
        if (product.position === item.position) {
          return {
            ...product,
            [fieldName]: selectedValue,
          };
        }
        return product;
      });
    });
  };

  const changePrice = (item: any, selectedValue: any, fieldName: string) => {
    setProducts((prevProducts) => {
      return prevProducts.map((product: any) => {
        if (product.position === item.position) {
          changePriceType(item, product?.product[selectedValue], "price");
          return {
            ...product,
            [fieldName]: selectedValue,
          };
        }
        return product;
      });
    });
  };

  useEffect(() => {
    if (currencyData && user.organization.currency_id && !updateid) {
      const OrganizationCurrency: any = currencyData.find(
        (currency: Currencys) => currency.id === user.organization.currency_id,
      );
      setSelectedCurrency(OrganizationCurrency);
      setFormData((prevData: any) => {
        const updatedData = {
          ...prevData,
          currency: {
            value: OrganizationCurrency?.id || "",
            label:
              `${OrganizationCurrency?.code} (${OrganizationCurrency?.symbol})` ||
              "",
          },
        };
        return updatedData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyData, user.organization.currency_id]);

  const handleSelectInputChange = (selectedOption: any, field: any) => {
    setFormData((prevData: any) => {
      const updatedData = {
        ...prevData,
        [field]: selectedOption,
      };
      return updatedData;
    });
  };

  const getTaxInvoiceData = async () => {
    try {
      const item: any = await TaxInvoice.$query()
        .with([
          "items",
          "items.tax",
          "charges",
          "charges.tax",
          "store",
          "paymentTerms",
          "termsAndCondition",
          "bankDetail",
          "supplierBillingAddress",
          "buyerBillingAddress",
          "buyerDeliveryAddress",
          "manufactureBillingAddress",
          "manufacture",
          "logisticDetail",
          "items.unit",
          "items.product",
          "items.batch",
          "buyerDeliveryAddress.counterPartyCompany",
          "buyerBillingAddress.counterPartyCompany",
          "manufactureBillingAddress.country",
          "manufactureBillingAddress.counterPartyCompany",
          "supplierBillingAddress.organization",
          "purchaseOrder",
          "purchaseOrder.items",
        ])
        .find(updateid);
      let inputData = { ...item.$attributes };
      setSignatureId(updateid);
      setAttachmentId(updateid);

      if (
        item.$attributes["oc_number"] ||
        item.$attributes["oc_date"] ||
        item.$attributes["purchase_order"] ||
        item.$attributes["po_date"] ||
        item.$attributes["kind_attention"] ||
        item.$attributes["delivery_note"]
      ) {
        setShowOptionalFields(true);
      }

      const fieldSetters = [
        { key: "status", setter: setStatus },
        { key: "title", setter: setTitle },
        { key: "document_number", setter: setDocumentNumber },
      ];

      fieldSetters.forEach(({ key, setter }) => {
        if (item.$attributes[key]) {
          setter(item.$attributes[key]);
        }
      });

      const dateFields = [
        { key: "document_date", setter: setDocumentDate },
        { key: "payment_date", setter: setPaymentDate },
        { key: "oc_date", setter: setOCDate },
        { key: "purchase_order_date", setter: setPODate },
      ];

      const isFieldsValueExist = (fieldName: string) => {
        setPrimaryDetails((prevState) => ({
          ...prevState,
          [fieldName]: true,
        }));
      };

      if (dateFormat) {
        dateFields.forEach(({ key, setter }) => {
          if (item.$attributes[key]) {
            setter(DateString(item.$attributes[key], dateFormat.split(" ")[0]));
          }
          isFieldsValueExist(key); // Set primary details for date fields
        });
      }

      const addressSetters = [
        {
          key: "buyer_billing_address",
          setter: setSelectedBuyerBillingAddress,
          // idSetter: setBuyerBillingAddressId,
        },
        {
          key: "buyer_delivery_address",
          setter: setSelectedBuyerDeliveryAddress,
          // idSetter: setBuyerDeliveryAddressId,
        },
        {
          key: "supplier_billing_address",
          setter: setSelectedSupplierBillingAddress,
          // idSetter: setSupplierBillingAddressId,
        },
        {
          key: "manufacture_billing_address",
          setter: setSelectedManufacturerBillingAddress,
          // idSetter: setManufacturerBillingAddressId,
        },
      ];

      addressSetters.forEach(({ key, setter, idSetter }: any) => {
        if (item.$attributes[key]) {
          setter(item.$attributes[key]);
          idSetter && idSetter(item.$attributes[key].id);
        } else {
          setter(null);
        }
      });

      // if (item.$attributes.buyer_billing_address) {
      //   setBuyerId(item.$attributes.buyer_billing_address.buyer_id);
      // }
      if (item.$attributes["purchase_order"]) {
        inputData["purchase_order"] = {
          label: inputData.purchase_order["document_number"],
          value: inputData.purchase_order["id"],
        };
        setPONumber(item.$attributes["purchase_order"]);
      }
      // if (item.$attributes.manufacture) {
      //   setSelectedManufacturerId(item.$attributes.manufacture);
      //   setMmanufacturerId(item.$attributes.manufacture.id);
      // }

      const optionalFieldSetters = [
        { key: "oc_number", setter: setOCNumber },
        // { key: "purchase_order_id", setter: setPONumber },
        { key: "kind_attention", setter: setKindAttention },
        { key: "delivery_note", setter: setDeliveryNote },
      ];

      optionalFieldSetters.forEach(({ key, setter }) => {
        if (item.$attributes[key]) {
          setter(item.$attributes[key]);
        }
      });

      if (item.$attributes.payment_terms) {
        inputData.payment_terms = {
          label: inputData.payment_terms.name,
          value: inputData.payment_terms.id,
        };
        setPaymentTerms(item.$attributes["payment_terms"]);
      }

      if (item.$attributes.store) {
        inputData.store = {
          label: inputData.store.name,
          value: inputData.store.id,
        };
        setStore(item.$attributes["store"]);
      }

      if (item.$attributes.terms_and_condition) {
        setShowTermsandcondition(true);
        inputData.terms_and_condition = {
          label: inputData.terms_and_condition.name,
          value: inputData.terms_and_condition.id,
        };
        setTermsAndCondition(item.$attributes["terms_and_condition"]);
      }

      if (item.$attributes.logistic_detail) {
        setShowLogisticDetails(true);
        inputData.logistic_detail = {
          label: inputData.logistic_detail.name,
          value: inputData.logistic_detail.id,
        };
        setLogisticDetail(item.$attributes["logistic_detail"]);
      }

      if (item.$attributes.bank_detail) {
        setShowBankDetails(true);
        inputData.bank_detail = {
          label: inputData.bank_detail.bank_name,
          value: inputData.bank_detail.id,
        };
        setBankDetails(item.$attributes["bank_detail"]);
      }

      if (item.$attributes.comments) {
        setShowComments(true);
        setInvoiceComment(inputData.comments);
      }

      if (item.$attributes.currency) {
        inputData["currency"] = {
          label:
            `${inputData.currency?.code} (${inputData.currency?.symbol})` || "",
          value: inputData.currency["id"],
        };
        setSelectedCurrency(item.$attributes["currency"]);
      }

      setProducts((prevState: any) => {
        const uniqueItems = new Set(prevState.map((item: any) => item.id));
        const returnProduct = [
          ...prevState,
          ...inputData.items
            .filter((item: any) => !uniqueItems.has(item.id))
            .map((item: any, index: number) => ({
              ...item,
              position: item.position ?? index,
            })),
        ];
        return returnProduct.sort((a, b) => a.position - b.position);
      });

      setHideShowField((prevState: any) => {
        return prevState.map((field: any) => {
          if (
            field.id === 1 &&
            inputData.items.some(
              (item: any) => item.discount !== "0.00" && item.discount !== null,
            )
          ) {
            return { ...field, fieldStatus: true };
          }
          if (
            field.id === 2 &&
            inputData.items.some((item: any) => item.delivery_date !== null)
          ) {
            return { ...field, fieldStatus: true };
          }
          if (
            field.id === 3 &&
            inputData.items.some((item: any) => item.comments !== null)
          ) {
            return { ...field, fieldStatus: true };
          }
          return field;
        });
      });

      setExtraExpenses((prevState: any) => {
        const uniqueItems = new Set(prevState.map((item: any) => item.id));
        const returnExtraExpenses = [
          ...prevState,
          ...inputData.charges
            .filter((item: any) => !uniqueItems.has(item.id))
            .map((item: any, index: number) => ({
              ...item,
              position: item.position ?? index,
              total_amount:
                (parseFloat(item.total) || 0) +
                ((parseFloat(item.total) || 0) *
                  (parseFloat(item.tax?.rate) || 0)) /
                  100,
            })),
        ];
        if (returnExtraExpenses.length) setShowExtraCharges(true);
        return returnExtraExpenses.sort((a, b) => a.position - b.position);
      });

      const checkField = [
        "title",
        "document_number",
        "payment_terms",
        "store",
        "oc_number",
        "kind_attention",
        "delivery_note",
      ];

      checkField.forEach((fieldName) => {
        isFieldsValueExist(fieldName);
      });
      setFormData(inputData);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };

  useEffect(() => {
    const allFieldsSet = Object.values(primaryDetails).every(
      (field) => field === true,
    );

    if (allFieldsSet) {
      setLoadingForPrimaryDocumentDetails(false);
    }
  }, [primaryDetails]);

  useEffect(() => {
    try {
      if (updateid) {
        setIsUpdated(true);
        getTaxInvoiceData();
      } else {
        setProducts([{ ...productDetails, position: 0 }]);
      }
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateid, dateFormat]);

  useEffect(() => {
    const updatedProducts = products.map((product) => {
      let updatedProduct = { ...product };
      hideShowField.forEach((field: any) => {
        if (!field.fieldStatus) {
          if (field.label === "discount") {
            updatedProduct["discount"] = "0.00";
            delete updatedProduct["discount_type"];
          }
          if (field.label === "comments") {
            updatedProduct["comments"] = "";
          }
          if (field.label === "delivery_date") {
            updatedProduct["delivery_date"] = "";
          }
        }
      });
      return updatedProduct;
    });

    setProducts(updatedProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideShowField]);

  useEffect(() => {
    DocumentNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegenerate = () => {
    DocumentNumber();
  };
  const DocumentNumber = async () => {
    const responce = await apiGet(`next-document-number/invoice`);
    setDocumentNumber(responce.data.value);
  };

  // const handelselectPaymentTerms = (value: string) => {
  //   setPaymentTerms(value);
  // };

  // const handelselectStore = (value: string) => {
  //   setStore(value);
  // };

  // const handelselectPurchaseOrder = (value: string) => {
  //   setPONumber(value);
  // };

  const GoBack = () => {
    setShowConfirmationForGoBack(true);
  };
  const handleConfirmationForGoBack: any = async () => {
    navigate(-1);
    setShowConfirmationForSendData(false);
  };

  const handleConfirmationForSendData = () => {
    handleSubmit(submitEvent, submitStatus, true);
    setShowConfirmationForSendData(false);
  };

  const handleToggleExtraCharges = () => {
    if (!showExtraCharges) {
      setExtraExpenses([{ ...extraExpensesDetails, position: 0 }]);
      setShowExtraCharges(!showExtraCharges);
    } else {
      setExtraExpenses([]);
      setShowExtraCharges(!showExtraCharges);
    }
  };

  const handleToggleAttachment = () => {
    setShowAttachment(!showAttachment);
  };

  useEffect(() => {
    if (!updateid) {
      if (showAttachment) {
        const attachmentsElement = document.getElementById("attachments");
        if (attachmentsElement) {
          attachmentsElement.scrollIntoView({ behavior: "smooth" });
        }
      }
      if (showComments) {
        const commentsElement = document.getElementById("comments");
        if (commentsElement) {
          commentsElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAttachment, showComments]);

  const handleToggleComments = () => {
    setShowComments(!showComments);
  };
  const handleToggleAttachmentSignature = () => {
    setShowAttachmentSignature(!showAttachmentSignature);
  };
  const handleToggleLogisticDetails = () => {
    setShowLogisticDetails(!showLogisticDetails);
  };

  const handleToggleTermsandcondition = () => {
    setShowTermsandcondition(!showTermsandcondition);
  };

  const handleToggleBankDetails = () => {
    setShowBankDetails(!showBankDetails);
  };

  const cleanObject = (obj: any, fileds: any) => {
    for (var propName in obj) {
      if (
        !fileds.includes(propName) &&
        (obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === "")
      ) {
        delete obj[propName];
      } else if (typeof obj[propName] === "object") {
        cleanObject(obj[propName], fileds);
      }
    }
    return obj;
  };

  const submit = async (e: any, status: any) => {
    setSubmitEvent(e);
    setSubmitStatus(status);
    if (status === "draft") {
      await handleSubmit(e, status);
    } else {
      setShowConfirmationForSendData(true);
    }
  };

  const [submitStatus, setSubmitStatus] = useState("");
  const [submitEvent, setSubmitEvent] = useState<any>(null);

  useEffect(() => {
    const fetchDefaultStore = async () => {
      let URL = new Store().$resource();
      let defaultStore = await apiPost(`${URL}/search`, {
        filters: [
          {
            field: "is_default",
            value: true,
          },
        ],
      });
      if (defaultStore.data.length > 0) {
        setFormData((prevData: any) => {
          const updatedData = {
            ...prevData,
            store: {
              label: defaultStore.data[0].name,
              value: defaultStore.data[0].id,
            },
          };
          return updatedData;
        });
        setStore(defaultStore.data[0]);
      }
    };

    if (!updateid) {
      fetchDefaultStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    status: any,
    fullData?: boolean,
  ) => {
    e.preventDefault();
    let isValid = true;
    let isDetailsValid = true;
    let isExpensesValid = true;
    let isSignatureValid = true;
    let isAttchmentValid = true;
    let extraExpensesErrors: any = {};
    let productErrors: any = {};
    let extraExpensesArray = [];

    if (showExtraCharges) {
      extraExpensesArray = extraExpenses.map((item) => {
        const newItem: any = { ...item };
        const fields = ["extra_charge_description", "total"];
        return cleanObject(newItem, fields);
      });
      extraExpensesArray?.every((item: any, index: number) => {
        Object.entries(item).forEach(([key, value]: [string, any]) => {
          if (!(value || key === "position")) {
            let errorKey = key
              .replace("id", "")
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ");
            const errorMessage = `${errorKey} is required`;
            extraExpensesErrors[index] = {
              ...extraExpensesErrors[index],
              [key]: errorMessage,
            };
          }
        });
        return item;
      });
      setExtraExpensesFormErrors(extraExpensesErrors);
    }

    if (showTermsandcondition && !termsAndCondition) {
      setTermsAndConditionError("Terms And Condition is Required");
      isDetailsValid = false;
    } else {
      setTermsAndConditionError("");
    }

    if (showLogisticDetails && !logisticDetail) {
      setLogisticDetailsError("Logistic Details is Required");
      isDetailsValid = false;
    } else {
      setLogisticDetailsError("");
    }

    if (showBankDetails && !bankDetails) {
      setBankDetailsError("Bank Details is Required");
      isDetailsValid = false;
    } else {
      setBankDetailsError("");
    }

    if (
      paymentTerms !== undefined &&
      paymentTerms !== null &&
      (typeof paymentTerms == "object" ||
        Object.keys(paymentTerms).length !== 0)
    ) {
      setPaymentTermsError("");
    } else {
      setPaymentTermsError("Payment Terms is required");
      isValid = false;
    }

    if (!documentDate) {
      setDocumentDateError("Document Date is Required");
      isValid = false;
    } else {
      setDocumentDateError("");
    }

    if (!paymentDate) {
      setPaymentDateError("Payment Due Date is Required");
      isValid = false;
    } else {
      setPaymentDateError("");
    }
    if (!selectedBuyerBillingAddress) {
      isValid = false;
      setBuyerDetailsError("Buyer Details is Required");
    } else {
      setBuyerDetailsError("");
    }
    if (!selectedSupplierBillingAddress) {
      isValid = false;
      setSupplierBillingAddressError("Supplier Address is Required");
    } else {
      setSupplierBillingAddressError("");
    }
    if (!selectedBuyerDeliveryAddress) {
      isValid = false;
      setDeliveryLocationError("Delivery Location is Required");
    } else {
      setDeliveryLocationError("");
    }

    if (showAttachmentSignature) {
      if (!updateid && !selectedSignatureFile) {
        setAttachmentSignatureError("Signature is Required");
        isSignatureValid = false;
      } else {
        setAttachmentSignatureError("");
      }
    }

    if (showAttachment) {
      if (!updateid) {
        if (!attchmentTitle || !(attchmentTitle.length >= 2)) {
          setAttachmentError({
            titleError:
              "Attachment's title field must be at least 2 characters",
          });
          isAttchmentValid = false;
        } else if (!selectedAttchmentFile) {
          setAttachmentError({ attachmentsError: "Attachments is Required" });
          isAttchmentValid = false;
        } else {
          setAttachmentError("");
        }
      } else {
        if (attchmentTitle && !(attchmentTitle.length >= 2)) {
          setAttachmentError({
            titleError:
              "Attachment's title field must be at least 2 characters",
          });
          isAttchmentValid = false;
        } else if (attchmentTitle && !selectedAttchmentFile) {
          setAttachmentError({ attachmentsError: "Attachments is Required" });
          isAttchmentValid = false;
        } else {
          setAttachmentError("");
        }
      }
    }

    if (!title) {
      setTitleError("Title is Required");
      isValid = false;
    } else {
      setTitleError("");
    }

    if (
      store !== undefined &&
      store !== null &&
      (typeof store == "object" || Object.keys(store).length !== 0)
    ) {
      setStoreError("");
    } else {
      setStoreError("Store is Required");
      isValid = false;
    }
    let rowDataArray = products.map((item: any) => {
      const newItem: any = { ...item };
      const fields = [
        "batches_id",
        "quantity",
        "products_id",
        "unit_of_measurement_id",
        "price",
        "comments",
      ];
      if (
        hideShowField.some((item: any) => item.id === 1 && item.fieldStatus)
      ) {
        fields.push("discount");
      }
      if (
        hideShowField.some((item: any) => item.id === 2 && item.fieldStatus)
      ) {
        fields.push("delivery_date");
        if (newItem.delivery_date && !isNaN(Date.parse(item.delivery_date))) {
          newItem.delivery_date = DateFormatWithStartOFTheDay(
            newItem.delivery_date,
            dateFormat?.replace("dd", "DD"),
          );
        }
      }
      return cleanObject(newItem, fields);
    });
    rowDataArray.every((item: any, index: number) => {
      if (parseInt(item.quantity) > item.batch?.current_stock) {
        const errorMessage = `Insufficient Current Stock. (${item.product?.current_stock})`;
        productErrors[index] = {
          ...productErrors[index],
          quantity: errorMessage,
        };
      }

      const keysToCheck = [
        "comments",
        "position",
        "delivery_date",
        "discount",
        "discount_type",
        "customKey",
      ];
      Object.entries(item).forEach(([key, value]: [string, any]) => {
        if (!(value || keysToCheck.includes(key))) {
          let errorKey = key
            .replace("id", "")
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          const errorMessage = `${errorKey} is required`;
          productErrors[index] = {
            ...productErrors[index],
            [key]: errorMessage,
          };
        }
      });
      return item;
    });

    if (fullData) {
      rowDataArray = rowDataArray?.map((item) => ({
        ...item,
        products_data: JSON.stringify(item.product) ?? {},
        unit_of_measurement_data: JSON.stringify(item.unit) ?? {},
        tax_data: JSON.stringify(item.tax) ?? {},
      }));

      extraExpensesArray = extraExpensesArray?.map((item) => ({
        ...item,
        tax_data: JSON.stringify(item.tax) ?? {},
      }));
    }

    setProductFormErrors(productErrors);
    if (
      !isValid ||
      !isDetailsValid ||
      !isExpensesValid ||
      !isSignatureValid ||
      !isAttchmentValid ||
      Object.keys(productErrors).length !== 0 ||
      Object.keys(extraExpensesErrors).length !== 0
    ) {
      setToast("error", "Validation failed. Please check the form fields");
      if (!isValid) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (Object.keys(productErrors).length !== 0) {
        window.scrollTo({ top: 600, behavior: "smooth" });
      } else if (Object.keys(extraExpensesErrors).length !== 0) {
        window.scrollTo({ top: 700, behavior: "smooth" });
      }
      return;
    }

    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();
    const formattedCurrentDate = DateFormatWithStartOFTheDay(
      `${year}-${month}-${day}`,
      dateFormat?.replace("dd", "DD"),
    );

    const formdata: any = {
      buyer_id: selectedBuyerBillingAddress?.counter_party_company_id,
      title: title,
      status: status,
      ...(!updateid ? { document_number: documentnumber } : {}),
      document_date: DateFormatWithStartOFTheDay(
        documentDate,
        dateFormat?.replace("dd", "DD"),
      ),
      currency_id: selectedCurrency?.id,
      store_id: store?.id,
      payment_term_id: paymentTerms?.id,
      payment_date: DateFormatWithStartOFTheDay(
        paymentDate,
        dateFormat?.replace("dd", "DD"),
      ),
      order_date: formattedCurrentDate,
      total_tax: totaltaxWithExtra(products, extraExpenses) || 0,
      total_amount: calculateGrandTotal(products, extraExpenses) || 0,
      comments: showComments ? invoiceComment : "",
      items: rowDataArray,
      buyer_billing_address_id: selectedBuyerBillingAddress?.id,
      buyer_delivery_address_id: selectedBuyerDeliveryAddress?.id,
      supplier_billing_address_id: selectedSupplierBillingAddress?.id,
      manufacture_billing_address_id: selectedManufacturerBillingAddress?.id,
      manufacture_id:
        selectedManufacturerBillingAddress?.counter_party_company?.id,
      extra_charges: extraExpensesArray,
      ...(showTermsandcondition
        ? {
            terms_and_condition_id: termsAndCondition?.id,
            ...(fullData
              ? { terms_and_condition_data: JSON.stringify(termsAndCondition) }
              : {}),
          }
        : {}),
      ...(showLogisticDetails
        ? {
            logistic_detail_id: logisticDetail?.id,
            ...(fullData
              ? { logistic_detail_data: JSON.stringify(logisticDetail) }
              : {}),
          }
        : {}),
      ...(showBankDetails
        ? {
            bank_detail_id: bankDetails?.id,
            ...(fullData
              ? { bank_detail_data: JSON.stringify(bankDetails) }
              : {}),
          }
        : {}),
      ...(fullData
        ? {
            currency_name_code: selectedCurrency?.code,
            buyer_name:
              selectedBuyerBillingAddress?.counter_party_company_id
                ?.company_name,
            manufacture_name:
              selectedManufacturerBillingAddress?.counter_party_company
                ?.company_name,
            buyer_billing_address_data: JSON.stringify(
              selectedBuyerBillingAddress,
            ),
            buyer_delivery_address_data: JSON.stringify(
              selectedBuyerDeliveryAddress,
            ),
            supplier_billing_address_data: JSON.stringify(
              selectedSupplierBillingAddress,
            ),
            manufacture_billing_address_data: JSON.stringify(
              selectedManufacturerBillingAddress,
            ),
            store_data:
              store && store.data
                ? JSON.stringify(store.data)
                : JSON.stringify(store),
            payment_term_data: JSON.stringify(paymentTerms),
          }
        : {}),
    };
    if (showOptionalFields) {
      formdata.oc_number = ocnumber;
      formdata.oc_date =
        ocDate &&
        DateFormatWithStartOFTheDay(ocDate, dateFormat?.replace("dd", "DD"));
      formdata.purchase_order_id = ponumber?.id?.toString();
      formdata.purchase_order_date =
        poDate &&
        DateFormatWithStartOFTheDay(poDate, dateFormat?.replace("dd", "DD"));
      formdata.purchase_order_data = fullData && JSON.stringify(ponumber);
      poDate &&
        DateFormatWithStartOFTheDay(poDate, dateFormat?.replace("dd", "DD"));
      formdata.kind_attention = kindAttention;
      formdata.delivery_note = deliveryNote;
    }
    try {
      setSubmitting(true);
      if (updateid) {
        await (TaxInvoice.$query() as any).update(updateid, formdata);
        if (selectedSignatureFile && showAttachmentSignature) {
          setSignaturecustomSubmit(true);
        }
        setToast("success", "Tax invoice successfully updated");
        if (selectedAttchmentFile && showAttachment) {
          setcustomSubmit(true);
        }
      } else {
        const data = await (TaxInvoice.$query() as any).store(formdata);
        if (selectedSignatureFile && showAttachmentSignature) {
          setSignatureId(data?.$attributes.id);
          setSignaturecustomSubmit(true);
        }
        if (selectedAttchmentFile && showAttachment) {
          setAttachmentId(data?.$attributes.id);
          setcustomSubmit(true);
        }

        setToast("success", "Tax invoice successfully added");
      }
      navigate(-1);
    } catch (error: any) {
      if (error.response && error.response.data.errors) {
        showErrorsInToasts(error.response.data.errors);
      } else if (error.response.data.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setSubmitting(false);
    }
  };

  const customTheme = (theme: any) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    },
  });

  const handleDocDateChange = (date: any) => {
    setDocumentDate(date);
  };
  const handlePaymentDateChange = (date: any) => {
    setPaymentDate(date);
  };
  const handleOCDateChange = (date: any) => {
    setOCDate(date);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h5>{TAX_INVOICE_TITLE}</h5>
        </div>
        <div style={{ display: "flex" }}>
          <div className="mx-2" style={{ width: "190px" }}>
            <CustomAsyncSelect
              componentKey={currencyModalStatus ? 0 : 1}
              customTheme={customTheme}
              model={Currency}
              customFilter={[
                {
                  field: "active",
                  value: 1,
                },
              ]}
              addNewBtnStatus={true}
              inputfield={"code"}
              inputfieldSecond={"symbol"}
              label="Currency"
              fieldName={"currency"}
              isMultiSelect={false}
              formData={formData}
              placeholder={"Select Currency"}
              onChangeModalStatus={(status: boolean) => {
                setCurrencyModalStatus(status);
              }}
              modalStatus={currencyModalStatus}
              isNeedFullData={true}
              onChange={(selectedOption: any) => {
                setSelectedCurrency(selectedOption?.data);
                handleSelectInputChange(selectedOption, "currency");
              }}
              widget={
                <AddUpdateCurrency
                  title={CURRENCY_TITLE}
                  listRoute={CURRENCY_LIST}
                  isNotUpdate={true}
                  onChangeModalStatus={(status: boolean) =>
                    setCurrencyModalStatus(status)
                  }
                />
              }
            />
          </div>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={GoBack}
          >
            Back
          </button>
        </div>
      </div>
      <div>
        <Row>
          <Col>
            <div
              style={{
                display: "flex",
                paddingTop: "20px",
              }}
            >
              <AddressSelecter
                title="Buyer Details*"
                isPratent={true}
                loadingStatus={updateid ? true : false}
                isChild={true}
                parentModal={BuyersAndSuppliers}
                parentFieldName="company_name"
                parentFormData="company_name"
                parentInputfield="company_name"
                parentLabel="Select Company"
                addParentNewBtnStatus={false}
                childModal={CounterPartyAddress}
                setSelectedParentValue={setSelectedCompanyOption}
                selectedParentValue={selectedCompanyOption}
                addChildNewBtnStatus={true}
                childIncludeWith={"country,counterPartyCompany"}
                childFieldName="company_name"
                childFormData="formData"
                childInputfield="address_name"
                childLabel="counter_party_address"
                isChildMultiSelect={false}
                isChildNeedFullData={true}
                isParentMultiSelect={false}
                isParentNeedFullData={false}
                parentCustomFilter={[
                  {
                    field: "company_type",
                    operator: "in",
                    value: ["buyer", "both"],
                  },
                ]}
                childCustomFilter={[
                  ...(selectedCompanyOption?.value ||
                  selectedBuyerBillingAddress?.counter_party_company?.id
                    ? [
                        {
                          field: "counter_party_company_id",
                          operator: "in",
                          value: [
                            selectedCompanyOption?.value ||
                              selectedBuyerBillingAddress?.counter_party_company
                                ?.id,
                          ],
                        },
                      ]
                    : []),
                  {
                    field: "address_type",
                    operator: "in",
                    value: ["billing"],
                  },
                ]}
                isChildDisabled={selectedCompanyOption?.value === undefined}
                selectedValue={selectedBuyerBillingAddress}
                setSelectedValue={setSelectedBuyerBillingAddress}
                formTitle={"Billing Address"}
                childFormAddressType={"billing"}
                formModal={CounterPartyAddress}
                errorValues={buyerDetailsError}
                setErrorValues={setBuyerDetailsError}
              />
              <AddressSelecter
                title="Delivery Location*"
                isPratent={false}
                loadingStatus={updateid ? true : false}
                isChild={true}
                childModal={CounterPartyAddress}
                addChildNewBtnStatus={true}
                childIncludeWith={"country,counterPartyCompany"}
                childFieldName="company_name"
                childFormData="formData"
                childInputfield="address_name"
                childLabel="counter_party_address"
                isChildMultiSelect={false}
                isChildNeedFullData={true}
                selectedParentValue={selectedCompanyOption}
                childCustomFilter={[
                  ...(selectedCompanyOption?.value ||
                  selectedBuyerBillingAddress?.counter_party_company?.id
                    ? [
                        {
                          field: "counter_party_company_id",
                          operator: "in",
                          value: [
                            selectedCompanyOption?.value ||
                              selectedBuyerBillingAddress?.counter_party_company
                                ?.id,
                          ],
                        },
                      ]
                    : []),
                  {
                    field: "address_type",
                    operator: "in",
                    value: ["delivery"],
                  },
                ]}
                isChildDisabled={selectedCompanyOption?.value === undefined}
                childFormAddressType={"delivery"}
                selectedValue={selectedBuyerDeliveryAddress}
                setSelectedValue={setSelectedBuyerDeliveryAddress}
                formTitle={"Delivery Address"}
                formModal={CounterPartyAddress}
                errorValues={deliveryLocationError}
                setErrorValues={setDeliveryLocationError}
              />
            </div>
            <div
              style={{
                display: "flex",
                paddingTop: "37px",
                marginBottom: "20px",
              }}
            >
              <AddressSelecter
                title="Organization Details*"
                isPratent={false}
                loadingStatus={updateid ? true : false}
                isChild={true}
                childModal={OrganizationAddress}
                addChildNewBtnStatus={true}
                childIncludeWith={"country"}
                childFieldName="supplier_billing_address"
                childFormData="formData"
                childInputfield="address_name"
                childLabel="organization_address"
                isChildMultiSelect={false}
                isChildNeedFullData={true}
                childCustomFilter={[
                  {
                    field: "address_type",
                    operator: "in",
                    value: ["billing"],
                  },
                ]}
                isChildDisabled={false}
                childFormAddressType={"billing"}
                selectedValue={selectedSupplierBillingAddress}
                setSelectedValue={setSelectedSupplierBillingAddress}
                formTitle={"Billing Address"}
                formModal={OrganizationAddress}
                errorValues={supplierBillingAddressError}
                setErrorValues={setSupplierBillingAddressError}
              />
              <AddressSelecter
                title="Manufactured by"
                isPratent={true}
                loadingStatus={
                  !selectedManufacturerBillingAddress
                    ? false
                    : updateid
                    ? true
                    : false
                }
                isChild={true}
                parentModal={BuyersAndSuppliers}
                parentFieldName="company_name"
                parentFormData="company_name"
                parentInputfield="company_name"
                parentLabel="counter_party_company"
                addParentNewBtnStatus={false}
                childModal={CounterPartyAddress}
                setSelectedParentValue={setSelectedManufactureCompanyOption}
                selectedParentValue={selectedManufactureCompanyOption}
                addChildNewBtnStatus={true}
                childIncludeWith={"country,counterPartyCompany"}
                childFieldName="company_name"
                childFormData="formData"
                childInputfield="address_name"
                childLabel="counter_party_address"
                isChildMultiSelect={false}
                isChildNeedFullData={true}
                isParentMultiSelect={false}
                isParentNeedFullData={false}
                childCustomFilter={[
                  ...(selectedManufactureCompanyOption?.value ||
                  selectedManufacturerBillingAddress?.counter_party_company?.id
                    ? [
                        {
                          field: "counter_party_company_id",
                          operator: "in",
                          value: [
                            selectedManufactureCompanyOption?.value ||
                              selectedManufacturerBillingAddress
                                ?.counter_party_company?.id,
                          ],
                        },
                      ]
                    : []),
                  {
                    field: "address_type",
                    operator: "in",
                    value: ["billing"],
                  },
                ]}
                isChildDisabled={
                  selectedManufactureCompanyOption?.value === undefined
                }
                childFormAddressType={"billing"}
                selectedValue={selectedManufacturerBillingAddress}
                setSelectedValue={setSelectedManufacturerBillingAddress}
                formTitle={"Manufacturer Address"}
                formModal={CounterPartyAddress}
              />
            </div>
          </Col>
          <Col>
            <Card className="mt-3" style={{ fontSize: "small" }}>
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <span style={{ fontSize: "15px" }}>
                    Primary Document Details
                  </span>
                  <button
                    className="btn btn-sm btn-light border"
                    onClick={() => setShowOptionalFields(!showOptionalFields)}
                  >
                    {showOptionalFields
                      ? "Hide Optional Fields"
                      : "Show Optional Fields"}
                  </button>
                </div>
              </Card.Header>

              {updateid && loadingForPrimaryDocumentDetails ? (
                <div style={{ padding: "1rem" }} className="pb-0">
                  <FormSkeleton />
                </div>
              ) : (
                <Card.Body>
                  <div className="mb-2">
                    <label htmlFor="title">Title*</label>
                    <input
                      className="w-100 form-control form-control-sm form-control form-control-sm-sm"
                      type="text"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                    {titleError && (
                      <div className="error-message text-danger">
                        {titleError}
                      </div>
                    )}
                  </div>

                  <div className="row mb-2">
                    <div className="col">
                      <label
                        htmlFor="documentnumber"
                        className="d-flex justify-content-between "
                      >
                        <span>Invoice Number*</span>
                        <button
                          onClick={handleRegenerate}
                          className="btn btn-light border mb-1"
                          style={{ padding: "3px 5px", fontSize: "12px" }}
                          disabled={updateid ? true : false}
                        >
                          Regenerate
                        </button>
                      </label>
                      <input
                        type="text"
                        className=" form-control form-control-sm"
                        disabled
                        value={documentnumber}
                      />
                    </div>
                    <div className="col d-flex flex-column">
                      <label
                        htmlFor="documentdate"
                        style={{ marginBottom: "10px" }}
                      >
                        Document Date*
                      </label>
                      <DatePicker
                        selected={documentDate}
                        placeholderText={dateFormat.replace("dd", "DD")}
                        onChange={handleDocDateChange}
                        dateFormat={
                          dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                        }
                        showYearDropdown
                        className="form-control form-control-sm w-100"
                        onBlur={(e) => {
                          let enteredDate = e.target.value;
                          const dateFormatWithoutTime = dateFormat
                            .replace("dd", "DD")
                            .split(" ")[0];
                          enteredDate = enteredDate.replace(
                            /\b(\d{1})\b/g,
                            "0$1",
                          );
                          const isValid = moment(
                            enteredDate,
                            dateFormatWithoutTime,
                          ).isValid();
                          if (isValid) {
                            setDocumentDateError("");
                          } else {
                            setDocumentDate("");
                            if (enteredDate.length > 0) {
                              setDocumentDateError(
                                `Please select a date in the correct format (${
                                  dateFormat.replace("dd", "DD").split(" ")[0]
                                }).`,
                              );
                            } else {
                              setDocumentDateError("");
                            }
                          }
                        }}
                      />
                      {documentDateError && (
                        <div className="error-message text-danger">
                          {documentDateError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col">
                      <label htmlFor="paymentterms">Payment Term*</label>
                      <CustomAsyncSelect
                        componentKey={paymentTermModalStatus ? 0 : 1}
                        customTheme={customTheme}
                        model={PaymentTerms}
                        inputfield={"name"}
                        fieldName={"payment_terms"}
                        isMultiSelect={false}
                        formData={formData}
                        label={"payment terms"}
                        addNewBtnStatus={true}
                        // value={paymentTerms}
                        isNeedFullData={true}
                        onChange={(selectedOption: any) => {
                          setPaymentTerms(selectedOption?.data);
                          handleSelectInputChange(
                            selectedOption,
                            "payment_terms",
                          );
                        }}
                        onChangeModalStatus={(status: boolean) => {
                          setPaymentTermModalStatus(status);
                        }}
                        modalStatus={paymentTermModalStatus}
                        widget={
                          <AddUpdatePaymentTerm
                            title={PAYMENTTERMS_TITLE}
                            listRoute={PAYMENTTERMS_LIST}
                            isNotUpdate={true}
                            onChangeModalStatus={(status: boolean) =>
                              setPaymentTermModalStatus(status)
                            }
                          />
                        }
                      />
                      {paymentTermsError && (
                        <div className="error-message text-danger">
                          {paymentTermsError}
                        </div>
                      )}
                    </div>
                    <div className="col d-flex flex-column">
                      <label htmlFor="paymentdate">Payment Due Date*</label>
                      <DatePicker
                        selected={paymentDate}
                        placeholderText={dateFormat.replace("dd", "DD")}
                        onChange={handlePaymentDateChange}
                        dateFormat={
                          dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                        }
                        showYearDropdown
                        className="form-control form-control-sm w-100"
                        onBlur={(e) => {
                          let enteredDate = e.target.value;
                          const dateFormatWithoutTime = dateFormat
                            .replace("dd", "DD")
                            .split(" ")[0];
                          enteredDate = enteredDate.replace(
                            /\b(\d{1})\b/g,
                            "0$1",
                          );
                          const isValid = moment(
                            enteredDate,
                            dateFormatWithoutTime,
                          ).isValid();
                          if (isValid) {
                            setPaymentDateError("");
                          } else {
                            setPaymentDate("");

                            if (enteredDate.length > 0) {
                              setPaymentDateError(
                                `Please select a date in the correct format (${
                                  dateFormat.replace("dd", "DD").split(" ")[0]
                                }).`,
                              );
                            } else {
                              setPaymentDateError("");
                            }
                          }
                        }}
                      />
                      {paymentDateError && (
                        <div className="error-message text-danger">
                          {paymentDateError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col">
                      <label htmlFor="store">Store*</label>
                      <CustomAsyncSelect
                        componentKey={storeModalStatus ? 0 : 1}
                        customTheme={customTheme}
                        model={Store}
                        inputfield={"name"}
                        fieldName={"store"}
                        isMultiSelect={false}
                        formData={formData}
                        label={"store"}
                        addNewBtnStatus={true}
                        isNeedFullData={true}
                        onChange={(selectedOption: any) => {
                          setStore(selectedOption?.data);
                          handleSelectInputChange(selectedOption, "store");
                        }}
                        onChangeModalStatus={(status: boolean) => {
                          setStoreModalStatus(status);
                        }}
                        modalStatus={storeModalStatus}
                        widget={
                          <AddUpdateStore
                            title={STORE_TITLE}
                            listRoute={STORE_LIST}
                            isNotUpdate={true}
                            onChangeModalStatus={(status: boolean) =>
                              setStoreModalStatus(status)
                            }
                          />
                        }
                      />
                      {storeError && (
                        <div className="error-message text-danger">
                          {storeError}
                        </div>
                      )}
                    </div>
                    {showOptionalFields && (
                      <div className="col">
                        <label htmlFor="ocnumber">
                          Order Confirmation Number
                        </label>
                        <input
                          type="text"
                          className="w-48 form-control form-control-sm"
                          value={ocnumber}
                          onChange={(e) => setOCNumber(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                  {showOptionalFields && (
                    <>
                      <div className="row mb-2">
                        <div className="col d-flex flex-column">
                          <label htmlFor="ocdate">
                            Order Confirmation Date
                          </label>
                          <DatePicker
                            selected={ocDate}
                            placeholderText={dateFormat.replace("dd", "DD")}
                            onChange={handleOCDateChange}
                            dateFormat={
                              dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                            }
                            showYearDropdown
                            className="form-control form-control-sm w-100"
                            onBlur={(e) => {
                              let enteredDate = e.target.value;
                              const dateFormatWithoutTime = dateFormat
                                .replace("dd", "DD")
                                .split(" ")[0];
                              enteredDate = enteredDate.replace(
                                /\b(\d{1})\b/g,
                                "0$1",
                              );
                              const isValid = moment(
                                enteredDate,
                                dateFormatWithoutTime,
                              ).isValid();
                              if (isValid) {
                                setOcDateError("");
                              } else {
                                setOCDate("");

                                if (enteredDate.length > 0) {
                                  setOcDateError(
                                    `Please select a date in the correct format (${
                                      dateFormat
                                        .replace("dd", "DD")
                                        .split(" ")[0]
                                    }).`,
                                  );
                                } else {
                                  setOcDateError("");
                                }
                              }
                            }}
                          />
                          {ocDateError && (
                            <div className="error-message text-danger">
                              {ocDateError}
                            </div>
                          )}
                        </div>
                        <div className="col">
                          <label htmlFor="ponumber">
                            Purchase Order Number
                          </label>
                          <CustomAsyncSelect
                            customTheme={customTheme}
                            model={PurchaseOrder}
                            inputfield={"document_number"}
                            fieldName={"purchase_order"}
                            isMultiSelect={false}
                            formData={formData}
                            label={"purchaseOrder"}
                            addNewBtnStatus={false}
                            isNeedFullData={true}
                            onChange={(selectedOption: any) => {
                              setPONumber(selectedOption?.data);
                              handleSelectInputChange(
                                selectedOption,
                                "purchase_order",
                              );
                              setPODate(
                                DateString(
                                  selectedOption.data.document_date,
                                  dateFormat.split(" ")[0],
                                ),
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col d-flex flex-column">
                          <label htmlFor="podate">Purchase Order Date</label>
                          <DatePicker
                            selected={poDate}
                            placeholderText="Select Purchase Order"
                            readOnly
                            dateFormat={dateFormat.split(" ")[0]}
                            className="form-control form-control-sm w-100"
                            onKeyDown={(e) => e.preventDefault()}
                            onChange={() => {}}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="kindattention">Kind Attention</label>
                          <input
                            className="w-100 form-control form-control-sm"
                            type="text"
                            value={kindAttention}
                            onChange={(e) => setKindAttention(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col">
                          <label htmlFor="deliverynote">Delivery Note</label>
                          <textarea
                            rows={1}
                            className="w-48 form-control form-control-sm"
                            value={deliveryNote}
                            onChange={(e) => setDeliveryNote(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>
      </div>
      <CreateRow
        FormTableFields={productTableFields}
        setCardsData={setProducts}
        cardsData={products}
        initializedProduct={productDetails}
        formErrors={productFormErrors}
        updateStatus={isUpdated}
        hideShowField={hideShowField}
        setHideShowField={setHideShowField}
        cardShowStatus={true}
        cardName="Product Details"
        setFormErrors={setProductFormErrors}
        insufficientErrorShow={true}
      />
      <CreateRow
        FormTableFields={extraChargeTableFields}
        setCardsData={setExtraExpenses}
        cardsData={extraExpenses}
        initializedProduct={extraExpensesDetails}
        formErrors={extraExpensesFormErrors}
        updateStatus={isUpdated}
        cardShowStatus={showExtraCharges}
        cardName="Extra Charges"
        setFormErrors={setExtraExpensesFormErrors}
      />
      <Row className="mb-4">
        <Col md={7}>
          <Card className="mb-4 w-100">
            <Card.Header>
              <div className="d-flex align-items-center">
                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="rounded-pill me-2"
                      style={{ fontSize: "12px" }}
                      size={"sm"}
                      variant={
                        showLogisticDetails ||
                        showTermsandcondition ||
                        showBankDetails
                          ? "success"
                          : "secondary"
                      }
                      id="dropdown-basic"
                    >
                      Details
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        className={
                          showTermsandcondition
                            ? "bg-success text-light"
                            : "bg-light text-dark"
                        }
                        onClick={handleToggleTermsandcondition}
                      >
                        Terms & Conditions
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          showLogisticDetails
                            ? "bg-success text-light"
                            : "bg-light text-dark"
                        }
                        onClick={handleToggleLogisticDetails}
                      >
                        Logistic Details
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={
                          showBankDetails
                            ? "bg-success text-light"
                            : "bg-light text-dark"
                        }
                        onClick={handleToggleBankDetails}
                      >
                        Bank Details
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="btngroup">
                  <Button
                    type="button"
                    style={{ fontSize: "12px" }}
                    className={`btn me-2 btn-secondary rounded-pill ${
                      showExtraCharges ? "btn-success" : "secondary"
                    } `}
                    onClick={handleToggleExtraCharges}
                    size={"sm"}
                  >
                    Extra Charge
                  </Button>
                  <Button
                    type="button"
                    style={{ fontSize: "12px" }}
                    className={`btn me-2 btn-secondary rounded-pill ${
                      showAttachment ? "btn-success" : "secondary"
                    } `}
                    onClick={handleToggleAttachment}
                    size={"sm"}
                  >
                    Attachment
                  </Button>
                  <Button
                    type="button"
                    style={{ fontSize: "12px" }}
                    className={`btn me-2 btn-secondary rounded-pill ${
                      showAttachmentSignature ? "btn-success" : "secondary"
                    } `}
                    onClick={handleToggleAttachmentSignature}
                    size={"sm"}
                  >
                    Attach Signature
                  </Button>

                  <Button
                    type="button"
                    style={{ fontSize: "12px" }}
                    className={`btn me-2 btn-secondary rounded-pill ${
                      showComments ? "btn-success" : "secondary"
                    } `}
                    onClick={handleToggleComments}
                    size={"sm"}
                  >
                    Add Comments
                  </Button>
                </div>
              </div>
            </Card.Header>
          </Card>
          <div
            className="row w-100"
            style={{ marginLeft: "0", justifyContent: "space-between" }}
          >
            <Card
              style={{
                display: showAttachment ? "block" : "none",
                fontSize: "small",
              }}
              className="mb-2 p-0"
              id="attachments"
            >
              <Card.Header style={{ fontSize: "15px" }}>
                Attachments
              </Card.Header>
              <Card.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
                <AttachmentsForDocuments
                  fieldType="tax_invoice_id"
                  moduleType="tax_invoice"
                  id={attachmentId}
                  customSubmit={customSubmit}
                  setAttchmentTitle={setAttchmentTitle}
                  setSelectedAttchmentFile={setSelectedAttchmentFile}
                  attachmentError={attachmentError}
                  setIsAttchmentExist={setIsAttchmentExist}
                ></AttachmentsForDocuments>
              </Card.Body>
            </Card>
            <Card
              className="mb-2 p-0"
              style={{
                width: "49%",
                display: showAttachmentSignature ? "block" : "none",
                fontSize: "small",
              }}
            >
              <Card.Header style={{ fontSize: "15px" }}>
                Attach Signature
              </Card.Header>
              <Card.Body>
                <SignatureForDocuments
                  fieldType="tax_invoice_id"
                  moduleType="tax_invoice"
                  id={signatureId}
                  customSubmit={signaturecustomSubmit}
                  setSelectedSignatureFile={setSelectedSignatureFile}
                  attachmentSignatureError={attachmentSignatureError}
                  setIsSignatureExist={setIsSignatureExist}
                ></SignatureForDocuments>
              </Card.Body>
            </Card>
            <Card
              className="mb-2 p-0"
              style={{
                width: "49%",
                display: showLogisticDetails ? "block" : "none",
                fontSize: "small",
              }}
            >
              <Card.Header style={{ fontSize: "15px" }}>
                Logistic Details
              </Card.Header>
              <Card.Body>
                <CustomAsyncSelect
                  componentKey={logistcDetailModalStatus ? 0 : 1}
                  customTheme={customTheme}
                  model={LogisticDetail}
                  inputfield={"name"}
                  label="Logistic Detail"
                  fieldName={"logistic_detail"}
                  isMultiSelect={false}
                  formData={formData}
                  addNewBtnStatus={true}
                  isNeedFullData={true}
                  onChange={(selectedOption: any) => {
                    setLogisticDetail(selectedOption?.data);
                    handleSelectInputChange(selectedOption, "logistic_detail");
                  }}
                  onChangeModalStatus={(status: boolean) =>
                    setLogistcDetailModalStatus(status)
                  }
                  modalStatus={logistcDetailModalStatus}
                  widget={
                    <AddUpdateLogisticDetail
                      title={LOGISTICDETAILS_TITLE}
                      listRoute={LOGISTICDETAILS_LIST}
                      isNotUpdate={true}
                      onChangeModalStatus={(status: boolean) =>
                        setLogistcDetailModalStatus(status)
                      }
                    />
                  }
                />
                {showLogisticDetails && logisticDetailsError && (
                  <div className="error-message text-danger">
                    {logisticDetailsError}
                  </div>
                )}
              </Card.Body>
            </Card>
            <Card
              className="mb-2 p-0"
              style={{
                width: "49%",
                display: showTermsandcondition ? "block" : "none",
                fontSize: "small",
              }}
            >
              <Card.Header style={{ fontSize: "15px" }}>
                Terms & Conditions
              </Card.Header>
              <Card.Body>
                <CustomAsyncSelect
                  componentKey={termsConditionsModalStatus ? 0 : 1}
                  customTheme={customTheme}
                  model={TermsAndCondition}
                  inputfield={"name"}
                  label="Terms And Condition"
                  fieldName={"terms_and_condition"}
                  isMultiSelect={false}
                  formData={formData}
                  addNewBtnStatus={true}
                  isNeedFullData={true}
                  onChange={(selectedOption: any) => {
                    setTermsAndCondition(selectedOption?.data);
                    handleSelectInputChange(
                      selectedOption,
                      "terms_and_condition",
                    );
                  }}
                  onChangeModalStatus={(status: boolean) =>
                    setTermsConditionsModalStatus(status)
                  }
                  modalStatus={termsConditionsModalStatus}
                  widget={
                    <AddUpdateTermsAndCondition
                      title={TERMSANDCONDITION_TITLE}
                      listRoute={TERMSANDCONDITION_LIST}
                      isNotUpdate={true}
                      onChangeModalStatus={(status: boolean) =>
                        setTermsConditionsModalStatus(status)
                      }
                    />
                  }
                />
                {showTermsandcondition && termsAndConditionError && (
                  <div className="error-message text-danger">
                    {termsAndConditionError}
                  </div>
                )}
              </Card.Body>
            </Card>
            <Card
              className="mb-2 p-0"
              style={{
                width: "49%",
                display: showBankDetails ? "block" : "none",
                fontSize: "small",
              }}
            >
              <Card.Header style={{ fontSize: "15px" }}>
                Bank Details
              </Card.Header>
              <Card.Body>
                <CustomAsyncSelect
                  componentKey={bankDetailsModalStatus ? 0 : 1}
                  customTheme={customTheme}
                  model={BankDetail}
                  inputfield={"bank_name"}
                  label="Bank Details"
                  fieldName={"bank_detail"}
                  isMultiSelect={false}
                  formData={formData}
                  addNewBtnStatus={true}
                  isNeedFullData={true}
                  onChange={(selectedOption: any) => {
                    setBankDetails(selectedOption?.data);
                    handleSelectInputChange(selectedOption, "bank_detail");
                  }}
                  onChangeModalStatus={(status: boolean) =>
                    setBankDetailsModalStatus(status)
                  }
                  modalStatus={bankDetailsModalStatus}
                  widget={
                    <AddUpdateBankDetail
                      title={BANK_DETAILS_TITLE}
                      listRoute={BANK_DETAILS_LIST}
                      isNotUpdate={true}
                      onChangeModalStatus={(status: boolean) =>
                        setBankDetailsModalStatus(status)
                      }
                    />
                  }
                />
                {showBankDetails && bankDetailsError && (
                  <div className="error-message text-danger">
                    {bankDetailsError}
                  </div>
                )}
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col md={5}>
          <Card style={{ fontSize: "small", lineHeight: "7px" }}>
            <Card.Header>
              <h5>Amount</h5>
            </Card.Header>
            <Card.Body>
              <div className="mt-2">
                <div className="d-flex justify-content-between mb-0">
                  <label htmlFor="totalBeforeTax">Total (Before Tax):</label>
                  <p>
                    {selectedCurrency &&
                      `${selectedCurrency?.code}(${selectedCurrency?.symbol}) `}
                    {totalWithExtra(products, extraExpenses)}
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-0">
                  <label htmlFor="totalTax">Total Tax:</label>
                  <p>
                    {selectedCurrency &&
                      `${selectedCurrency?.code}(${selectedCurrency?.symbol}) `}
                    {AmountFormat(totaltaxWithExtra(products, extraExpenses))}
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-0">
                  <label htmlFor="totalTax">Total (After Tax):</label>
                  <p className="mb-0">
                    {selectedCurrency &&
                      `${selectedCurrency?.code}(${selectedCurrency?.symbol}) `}
                    {AmountFormat(calculateGrandTotal(products, extraExpenses))}
                  </p>
                </div>
                <hr className="my-3" />
                <div className="d-flex justify-content-between mb-0">
                  <div>
                    <h6>Grand Total:</h6>
                  </div>
                  <div>
                    <h4>
                      {selectedCurrency?.symbol}
                      {AmountFormat(
                        calculateGrandTotal(products, extraExpenses),
                      )}
                    </h4>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card
        style={{ display: showComments ? "block" : "none", fontSize: "small" }}
        className="mb-4"
        id="comments"
      >
        <Card.Header style={{ fontSize: "15px" }}>Comments</Card.Header>
        <Card.Body>
          <Form.Group controlId="comments">
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter your comments here"
              onChange={(e) => {
                setInvoiceComment(e.target.value);
              }}
              value={invoiceComment}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <div className="mb-4 d-flex justify-content-end">
        <span>
          <CustomButton
            type="submit"
            label={"SAVE DRAFT"}
            variant="info"
            onClick={(e) => submit(e, "draft")}
            size="sm"
            customeStyle={{ width: "150px", padding: "5px", fontWeight: "500" }}
            customeClass={"text-light"}
            disableStatus={
              (status && status !== "draft") ||
              (updateid && loadingForPrimaryDocumentDetails) ||
              submitting
            }
            spinnerStatus={submitting && submitStatus === "draft"}
          />
        </span>

        <span>
          <CustomButton
            type="submit"
            label={"SAVE AND SEND"}
            variant="primary"
            onClick={(e) => submit(e, "sent")}
            size="sm"
            customeStyle={{ width: "150px", padding: "5px", fontWeight: "500" }}
            customeClass={"text-light"}
            disableStatus={
              (status && status !== "draft") ||
              (updateid && loadingForPrimaryDocumentDetails) ||
              submitting
            }
            spinnerStatus={submitting && submitStatus !== "draft"}
          />
        </span>
      </div>
      {updateid && (
        <div className="card mt-4" style={{ marginBottom: "30px" }}>
          <div className="card-header d-flex">
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className="mb-0 me-3 text-primary"
            >
              Activity Logs
            </h5>
          </div>

          <div
            className="card-body"
            style={{
              height: "auto",
              overflow: "auto",
              backgroundColor: "#e4e4e4",
            }}
          >
            <ActivityLogs auditable_type={"TAX_INVOICES"} id={updateid} />
          </div>
        </div>
      )}
      <ConfirmationModal
        show={showConfirmationForGoBack}
        onHide={() => setShowConfirmationForGoBack(false)}
        title="Confirmation"
        message="Are you sure you want to go back?"
        onConfirm={handleConfirmationForGoBack}
        confirmButtonColor="primary"
      />
      <ConfirmationModal
        show={showConfirmationForSendData}
        onHide={() => setShowConfirmationForSendData(false)}
        title="Confirmation"
        message="Are you sure you want to send?"
        onConfirm={handleConfirmationForSendData}
        confirmButtonColor="primary"
        spinnerStatus={submitting ? submitting : false}
      />
    </>
  );
};

export default AddUpdate;
