import React from "react";
import { Modal } from "react-bootstrap";
import CustomSpinner from "../Util/Spinner";

const ConfirmationModal = ({
  show,
  onHide,
  title,
  message,
  onConfirm,
  confirmButtonColor,
  spinnerStatus,
}: any) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </button>
        <button
          className={`btn btn-${confirmButtonColor}`}
          onClick={onConfirm}
          disabled={spinnerStatus}
        >
          {spinnerStatus ? (
            <>
              Confirm <CustomSpinner />
            </>
          ) : (
            "Confirm"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
